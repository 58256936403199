import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useHttp from '../../hooks/use-http';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import { apiURL } from '../../utils/Constants';
// import './DeleteOrder.css';

const DeleteOrder = () => {
  const params = useParams();
  const orderID = params.id; // id: from src/App.jsx -> Routes
  const { isLoading, errors, /* setErrors,*/ sendRequest } = useHttp();
  // eslint-disable-next-line
  const [response, setResponse] = useState(null);
  const [success, setSuccess] = useState(false); // only for development (React.strict)
  const token = useSelector((state) => state.auth.token);

  // delete order
  useEffect(() => {
    // if response is a json with JSend specification { status: "success" ... }
    const json = (json) => {
      setResponse(json.data);
      setSuccess(true);
    };

    sendRequest(
      {
        url: apiURL + '/order/DeleteOrder/' + orderID,
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      },
      json
    );
  }, [sendRequest, orderID]);

  const errorContent = (
    <React.Fragment>
      <center className="py-5">
        <h3 className="error-text">
          Die Anfrage konnte nicht gelöscht werden!
        </h3>
        <br />
        {errors && errors.hasOwnProperty('data') ? (
          <p className="error-text pb-5">{JSON.stringify(errors.data)}</p>
        ) : null}
        <Link to={'/Order/Übersicht'}>
          <button
            autoFocus={true}
            className="btn bg-primary border-primary mb-5">
            Zurück zur Anfrage Übersicht
          </button>
        </Link>
      </center>
    </React.Fragment>
  );

  const successContent = (
    <React.Fragment>
      <center className="py-5">
        <h3 className="success-text">Anfrage erfolgreich gelöscht!</h3>
        <br />
        <Link to="/Order/Übersicht">
          <button className="btn bg-primary border-primary" autoFocus={true}>
            OK
          </button>
        </Link>
      </center>
    </React.Fragment>
  );

  const loadingSpinner = (
    <React.Fragment>
      <center className="py-5">
        <b>Lösche Anfrage ...</b>
        <br />
        <LoadingSpinner />
      </center>
    </React.Fragment>
  );

  const somethingWentWrongContent = (
    <React.Fragment>
      <center className="py-5">
        <h3 className="error-text">Etwas ist schief gelaufen :(</h3>
        <br />
        <Link to={'/Order/Übersicht'}>
          <button autoFocus={true} className="btn bg-primary border-primary">
            Zurück zur Anfrage Übersicht
          </button>
        </Link>
      </center>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {!isLoading && !success && errors == null && somethingWentWrongContent}
      {!isLoading && !success && errors != null && errorContent}
      {!isLoading && success && successContent}
      {isLoading && loadingSpinner}
    </React.Fragment>
  );
};

export default DeleteOrder;
