import React from 'react';
import Banner from './Banner';
import About from './About';
import Partner from './Partner';
// import "./Home.css";

const Home = () => {
  return (
    <React.Fragment>
      <Banner />
      <About />
      <hr /> {/* Horizontal line */}
      <Partner />
    </React.Fragment>
  );
};

export default Home;
