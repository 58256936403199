import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFileCirclePlus } from '@fortawesome/free-solid-svg-icons';
import SeminarCard from './SeminarCard';
import LoadingSpinner from '../../../components/UI/LoadingSpinner';
import './SeminarList.css';
import './SeminarCard.css';

const SeminarList = (props) => {
  library.add(faFileCirclePlus);

  // search text
  const [search, setSearch] = useState('');

  const searchHandler = (event) => {
    setSearch(event.target.value);
  };

  const isLoading = props.isLoading;
  let errors = props.errors;
  if (errors && String(errors.message).search('Failed to fetch') >= 0) {
    errors = {
      status: 'Error',
      code: 500,
      message: 'Internal Server Error',
      data: 'Verbindung zur Datenbank fehlgeschlagen',
    };
  }
  const seminars = props.data;
  let content;

  // if loading
  if (isLoading) {
    content = (
      <center className="py-5">
        <LoadingSpinner />
      </center>
    );
  } else {
    // if errors
    if (errors) {
      content = (
        <p>
          <b>
            Status: {errors.status} - Code: {errors.code}: {errors.message} -{' '}
            {errors.data}
          </b>
          <br />
        </p>
      );
    }

    // if success
    else if (seminars !== undefined && seminars.length > 0) {
      content = (
        <React.Fragment>
          <div className="row border">
            <div className="col-3 uuid">
              <b>Titel</b>
            </div>
            <div className="col">
              <b>Von</b>
            </div>
            <div className="col">
              <b>Bis</b>
            </div>
            <div className="col">
              <b>Typ</b>
            </div>
            <div className="col">
              <b>Status</b>
            </div>
            <div className="col-1 buttons">
              <b></b>
            </div>
          </div>

          {seminars.map((seminar) => {
            // if search has text
            let match = false;
            if (search !== '') {
              for (var key in seminar) {
                // only search in list of keys
                if (['title', 'startDateTime', 'endDateTime'].includes(key)) {
                  const value = String(seminar[key]).toLowerCase();
                  if (value.indexOf(search) !== -1) match = true;
                }
              }
            }
            if (search === '' || match) {
              return <SeminarCard key={seminar.uuid} seminar={seminar} />;
            } else {
              return null;
            }
          })}
        </React.Fragment>
      );
    }

    // if no content was found
    else {
      content = 'Es gibt derzeit keine Seminare :(';
    }
  }

  return (
    <React.Fragment>
      <div className="container my-5">
        <div className="row">
          <div className="col-6">
            <h2>Seminar Übersicht</h2>
          </div>
          <div className="col-4">
            {/* Suche */}
            <form role="search">
              <input
                className="form-control"
                type="search"
                placeholder="Suche"
                aria-label="Suche"
                onChange={searchHandler}
              />
            </form>
          </div>
          <div className="col-2 my-auto">
            <Link to="/Seminar/Hinzufügen">
              <h5>
                <nobr>
                  Neues Seminar <i className="fa-solid fa-file-circle-plus"></i>
                </nobr>
              </h5>
            </Link>
          </div>
        </div>
        {content}
      </div>
    </React.Fragment>
  );
};

export default SeminarList;
