import { library } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import './Download.css';
import Button from './Button';

/*
    Beispiel:
        <Download
            key=0                       // erforderlich: für react, muss eindeutig sein!
            className="my-3"            // optional: Klassen, die dem Component mitgegeben werden können
            title={title}               // optional aber sinnvoll: Titel des Downloads
            link={require("/downloads/dokument.pdf")}
                                        // optional aber sinnvoll: Link des Downloads
                                        //  muss im public ordner liegen!!!
            image={require("../assets/img/image1.jpg")}
                                        // optional: Bild des Downloads
            fontAwesome="fa-solid fa-microscope"
                                        // optional: FontAwesome Icon
                                        //  nur sichtbar, wenn kein image verwendet wurde!
        />
 */

const Download = (props) => {
  const classes =
    'row download my-1 ' + (props.className ? props.className : '');
  const imgAlt = props.image
    ? props.image.substring(props.image.lastIndexOf('/') + 1)
    : 'doc';
  library.add(faDownload);
  return (
    <div className="d-flex justify-content-center">
      <div className={classes}>
        {
          // falls ein Bild übergeben wird, nimm das
          props.image !== undefined ? (
            <div className="col-2 text-center align-self-center">
              <img
                className="img-fluid rounded docicon"
                src={props.image}
                alt={imgAlt}
              />
            </div>
          ) : (
            // ansonsten prüfe ob ein fontAwesomeIcon übergeben wurde, dann nimm das
            // sonst nehme das default fontAwesomeIcon fa-download
            <div className="col-2 text-center fontAwesomeLogoDownload align-self-center">
              <i
                className={
                  props.fontAwesome ? props.fontAwesome : 'fa-solid fa-download'
                }></i>
            </div>
          )
        }
        <div className="col-6 text-center align-self-center">
          <h5 className="mb-0">{props.title}</h5>
        </div>
        <div className="col-4 text-center align-self-center">
          <Button title="Download" url={props.link} isDownload={true} />
        </div>
      </div>
    </div>
  );
};

export default Download;
