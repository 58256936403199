import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUsersGear,
  faMicroscope,
  faGraduationCap,
  faScrewdriverWrench,
  faFileZipper,
} from '@fortawesome/free-solid-svg-icons';
import TextBox from '../../../components/TextBox';
import Button from '../../../components/Button';
import Download from '../../../components/Download';
import '../Labs.css';

const Fertigungsverfahren = () => {
  library.add(
    faUsersGear,
    faMicroscope,
    faGraduationCap,
    faScrewdriverWrench,
    faFileZipper
  );
  const textBoxList = [
    /*
            Anleitung zum Hinzufügen neuer Inhalte:
                1. geschweifte Klammern mit Werten kopieren und
                   unter das Array anfügen (Komma vorher nicht vergessen)

                   {
                       title: " Titel",
                       bulletPoints: [
                           "Punkt 1",
                           "Punkt 2",
                           "Punkt 2",
                           "Punkt 2"
                       ],
                       fontAwesome: "fa-solid fa-symbol"
                   },

                2. Titel und Text der bulletPoints abändern
                3. Passendes Symbol auf https://fontawesome.com/icons aussuchen (Bsp: <i class="fa-solid fa-database"></i>)
                    1. Dieses Symbol (fa-database) aus dem Datenpaket '@fortawesome/free-solid-svg-icons' oben bei den Imports hinzufügen
                    dabei ist darauf zu achten dass die Bindestriche durch Großbuchstaben ersetzt werden (Bsp. fa-database -> faDatabase)
                    2. zur Library vor dem Datenarray hinzufügen
                    vorher: library.add(faGlasses, faScrewdriverWrench,...);
                    nachher: library.add(faGlasses, faScrewdriverWrench,..., faDatabase);
                    3. im Array aufrufen (fontAwesome: "fa-solid fa-database)
                4. ggf. einfügen
                <div>
                <Download
                    key= "0"
                    className= "my-3"
                    title="Dateiname.dateiendung"
                    link="/downloads/Dateiname.dateiendung"
                    fontAwesome="fa-solid fa-file-zipper"
                />
                </div>
        */
    {
      title: 'Kompetenzbereiche',
      bulletPoints: [
        'Rapid Prototyping: Schnelle Herstellung von Prototypen und Vorserienmodellen aus CADund STL-Datensätzen in verschiedenen Verfahren (Kunststofflasersintern, Polyjet, FDM)',
        'Rapid Tooling: Schnelle Werkzeugerstellung zur Herstellung von Bauteilen in kleinen Serien.',
        'Rapid-Manufacturing: Werkzeuglose Fertigung von Serienbauteilen, Ersatzteilen, Funktionsprototypen und Modellen direkt aus CAD-Daten',
        'Fertigung mittels Laser-Sinter-, Polyjet- und FDM-Verfahren möglich',
      ],
      fontAwesome: 'fa-solid fa-users-gear',
    },
    {
      title: 'Forschungs- und Kooperationsgebiete',
      bulletPoints: [
        'Unterstützung bei der Prototypenherstellung im Produktentstehungsprozess, wenn firmeneigene Herstellungsverfahren ausgelastet oder nicht verfügbar sind',
        'Beratung und Dienstleistung in den oben genannten Kompetenzbereichen',
        'Gemeinsame Beantragung und Durchführung von nationalen und internationalen industrie- und anwendungsnahen Forschungsprojekten mit Dualen Partnern und Firmenpartnern sowie assoziierten Instituten und anderen Forschungseinrichtungen',
      ],
      fontAwesome: 'fa-solid fa-microscope',
    },
    {
      title: 'Lehrgebiete',
      bulletPoints: [
        'Laborveranstaltungen im Rahmen der Vorlesung Fertigungstechnik',
        'Erstellung von Studienarbeiten',
        'Fertigung von Formen und Bauteilen für studentische Projekte',
      ],
      fontAwesome: 'fa-solid fa-graduation-cap',
    },
    {
      title: 'Ausstattung',
      bulletPoints: [
        'Sinter-Anlage EOS P396, EOS GmbH',
        'Polyjet-Anlage EDEN 260VS, Stratasys Ltd.',
        'FDM-Anlage Fortus 250mc, Stratasys Ltd.',
        'Software zur Bauteil und Jobvorbereitung',
        'MAGICS, Materialise GmbH',
      ],
      fontAwesome: 'fa-solid fa-screwdriver-wrench',
    },
  ];

  return (
    <section id="laborContent">
      <div className="container-lg align-items-center justify-content-center mt-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-5">
            {/* Bild  */}
            <img
              className="img-fluid rounded"
              src={require('../../../assets/img/EOSP396.png')}
              alt="EOS396"
            />
            <p>EOS P 396</p>
          </div>
          <div className="col-md-5">
            <h2>
              Labor für generative Fertigungsverfahren (3D-Druck, Rapid
              Prototyping)
            </h2>
            <div className="row mt-4 mb-4">
              <div className="col">
                <h4>
                  Generative Fertigungsverfahren, auch bekannt als 3D-Druck oder
                  Rapid Prototyping, ermöglichen es Einzelteile werkzeuglos
                  herzustellen.
                </h4>
                {/* Button auf die virtuelle Begehung */}
                {
                  <Button
                    title="360° Blick ins Labor"
                    url="https://my.matterport.com/show/?m=SCEKg6tnmtc&sr=-.43,1.01&ss=34"
                  />
                }
              </div>
            </div>
          </div>
        </div>

        {/* Erste Reihe mit Beschreibungen des Labores */}
        {
          // map => durchläuft alle TextBoxen in der textBoxList
          textBoxList.map(
            (
              { className, classNameCol, title, bulletPoints, fontAwesome },
              index
            ) => {
              return (
                <TextBox
                  // key={index} ist für react zwingend erforderlich!
                  key={index}
                  className="my-4"
                  title={title}
                  bulletPoints={bulletPoints}
                  fontAwesome={fontAwesome}
                />
              );
            }
          )
        }
      </div>
      <div>
        <Download
          key="0"
          className="my-3"
          title="Labor für Fertigungsverfahren.zip"
          link="/downloads/Fertigungsverfahren.zip"
          fontAwesome="fa-solid fa-file-zipper"
        />
      </div>
    </section>
  );
};

export default Fertigungsverfahren;
