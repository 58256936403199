import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DateTimePicker from '../../components/DateTimePicker';
import validator from 'validator';
import useHttp from '../../hooks/use-http';
import useInput from '../../hooks/use-input';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import { apiURL } from '../../utils/Constants';
import GoogleCalendar from '../../components/GoogleCalendar';
// import './AddDownload.css';

const AddSeminar = ({ seminarToEdit }) => {
  const edit = seminarToEdit != null ? true : false;
  const token = useSelector((state) => state.auth.token);

  const { isLoading, errors, setErrors, sendRequest } = useHttp();
  const [formIsValid, setFormIsValid] = useState(false);
  // eslint-disable-next-line
  const [response, setResponse] = useState(null);
  const [success, setSuccess] = useState(false); // only for development (React.strict)
  const navigate = useNavigate();

  // title
  const {
    value: title,
    isValid: titleIsValid,
    hasError: titleHasError,
    valueChangeHandler: titleChangeHandler,
    inputBlurHandler: titleBlurHandler,
    /* reset: titleReset, */
  } = useInput({
    defaultValue: edit ? seminarToEdit.title : '',
    validate: (value) =>
      validator.isAlphanumeric(value, 'de-DE', { ignore: ' -.' }),
  });

  // startDateTime
  const defaultDate = new Date();
  const defaultStartDate = new Date(defaultDate.setHours(8, 0, 0, 0));

  const {
    value: startDateTime,
    setValue: startDateTimeChangeHandler,
    isValid: startDateTimeIsValid,
    hasError: startDateTimeHasError,
    inputBlurHandler: startDateTimeBlurHandler,
    /* reset: startDateTimeReset, */
  } = useInput({
    defaultValue: edit
      ? new Date(seminarToEdit.startDateTime)
      : defaultStartDate, // 8 o'clock
    validate: (value) => (value ? typeof value.getMonth === 'function' : false),
  });

  // endDateTime
  // startDateTime
  const defaultEndDate = new Date(defaultDate.setHours(9, 0, 0, 0));

  const {
    value: endDateTime,
    setValue: endDateTimeChangeHandler,
    isValid: endDateTimeIsValid,
    hasError: endDateTimeHasError,
    inputBlurHandler: endDateTimeBlurHandler,
    /* reset: endDateTimeReset, */
  } = useInput({
    defaultValue: edit ? new Date(seminarToEdit.endDateTime) : defaultEndDate, // 9 o'clock,
    validate: (value) => (value ? typeof value.getMonth === 'function' : false),
  });

  // price
  const {
    value: price,
    isValid: priceIsValid,
    hasError: priceHasError,
    valueChangeHandler: priceChangeHandler,
    inputBlurHandler: priceBlurHandler,
    /* reset: priceReset, */
  } = useInput({
    defaultValue: edit ? seminarToEdit.price : '',
    validate: (value) =>
      validator.isDecimal(value, { decimal_digits: '2', locale: 'en-US' }) ||
      value < 0,
  });

  // content
  const {
    value: content,
    isValid: contentIsValid,
    hasError: contentHasError,
    valueChangeHandler: contentChangeHandler,
    inputBlurHandler: contentBlurHandler,
    /* reset: contentReset, */
  } = useInput({
    defaultValue: edit ? seminarToEdit.content : '',
    validate: (value) => true,
  });

  const json = (json) => {
    // transform data here (extract data from JSend specification)
    setResponse(json.data);
    setSuccess(true);
  };

  const sendForm = async (event) => {
    await sendRequest(
      {
        url:
          apiURL +
          (edit ? '/offer/update/' + seminarToEdit.uuid : '/offer/add'),
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: {
          service: {
            title,
            type: 'seminar',
          },
          offer: {
            startDateTime,
            endDateTime,
            content,
            price,
            state: 0,
          },
        },
      },
      json
    );
  };

  // if form was submitted
  const formSubmitHandler = async (event) => {
    event.preventDefault();

    // set all fields as touched
    titleBlurHandler();
    startDateTimeBlurHandler();
    endDateTimeBlurHandler();
    priceBlurHandler();
    contentBlurHandler();

    // set form to valid if all fields are valid
    if (
      titleIsValid &&
      startDateTimeIsValid &&
      endDateTimeIsValid &&
      priceIsValid &&
      contentIsValid
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  };

  const retryHandler = () => {
    setErrors(null);
    setFormIsValid(false);
  };

  const resetHandler = () => {
    navigate('/Seminar/Übersicht');
  };

  // if form is valid -> send form
  if (formIsValid) {
    setFormIsValid(false);
    sendForm();
  }

  const errorContent = (
    <React.Fragment>
      <center className="py-5">
        <b className="error-text mb-2">
          {errors && errors.hasOwnProperty('data')
            ? JSON.stringify(errors.data)
            : null}
        </b>
        <br />
        <br />
        <button
          onClick={retryHandler}
          className="btn btn-primary bg-primary border-primary">
          Nochmal versuchen
        </button>
      </center>
    </React.Fragment>
  );

  const successContent = (
    <React.Fragment>
      <center className="py-5">
        <b className="success-text">
          {'Seminar erfolgreich ' + (edit ? 'bearbeitet' : 'hinzugefügt')}
        </b>
        <br />
        <br />
        <button
          autoFocus={true}
          onClick={resetHandler}
          className="btn btn-primary bg-primary border-primary">
          OK
        </button>
      </center>
    </React.Fragment>
  );

  const loadingSpinner = (
    <React.Fragment>
      <center className="py-5">
        <b>{'Seminar wird ' + (edit ? 'bearbeitet' : 'erstellt') + ' ...'}</b>
        <br />
        <LoadingSpinner />
      </center>
    </React.Fragment>
  );

  const formContent = (
    <React.Fragment>
      <section id="seminar">
        <div className="container pb-5">
          <h2 className="mt-5 mb-3">
            {edit ? 'Seminar bearbeiten' : 'Neues Seminar'}
          </h2>

          {/* GoogleCalendar */}
          <GoogleCalendar />

          {/* Form */}
          <form onSubmit={formSubmitHandler}>
            {/* title */}
            <div className="form-group row my-1 mt-5">
              <label
                htmlFor="title"
                className="col-form-label col-sm-3 col-lg-2">
                Titel
              </label>

              {/* FormData */}
              <div className="col-md-9 col-lg-10">
                <input
                  type="text"
                  className={
                    titleHasError ? 'form-control invalid' : 'form-control'
                  }
                  id="title"
                  placeholder="Titel"
                  onChange={titleChangeHandler}
                  onBlur={titleBlurHandler}
                  value={title}
                />
                {titleHasError && (
                  <p className="error-text mb-2">
                    {'Titel ' +
                      (title.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )}
              </div>
            </div>

            {/* price */}
            <div className="form-group row my-1">
              <label
                htmlFor="price"
                className="col-form-label col-sm-3 col-lg-2">
                Preis
              </label>
              <div className="col-md-9 col-lg-10">
                <input
                  type="text"
                  className={
                    priceHasError ? 'form-control invalid' : 'form-control'
                  }
                  id="price"
                  placeholder="100.00"
                  onChange={priceChangeHandler}
                  onBlur={priceBlurHandler}
                  value={price}
                />
                {priceHasError && (
                  <p className="error-text mb-2">
                    {'Preis ' +
                      (price.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )}
              </div>
            </div>

            {/* startDateTime */}
            <div className="form-group row my-1">
              <label
                htmlFor="startDateTime"
                className="col-form-label col-sm-3 col-lg-2">
                Von
              </label>
              <div className="col-md-9 col-lg-10">
                <DateTimePicker
                  onChange={startDateTimeChangeHandler}
                  value={startDateTime}
                />
                {startDateTimeHasError && (
                  <p className="error-text mb-2">
                    {'Startzeit ' +
                      (title.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'ist ungültig')}
                  </p>
                )}
              </div>
            </div>

            {/* endDateTime */}
            <div className="form-group row my-1">
              <label
                htmlFor="endDateTime"
                className="col-form-label col-sm-3 col-lg-2">
                Bis
              </label>
              <div className="col-md-9 col-lg-10">
                <DateTimePicker
                  onChange={endDateTimeChangeHandler}
                  value={endDateTime}
                />
                {endDateTimeHasError && (
                  <p className="error-text mb-2">
                    {'Endzeit ' +
                      (title.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'ist ungültig')}
                  </p>
                )}
              </div>
            </div>

            {/* content */}
            <div className="form-group row my-1">
              <label
                htmlFor="content"
                className="col-form-label col-sm-3 col-lg-2">
                Beschreibung
              </label>
              <div className="col-md-9 col-lg-10">
                <textarea
                  className={
                    contentHasError ? 'form-control invalid' : 'form-control'
                  }
                  name="content"
                  id="content"
                  rows="10"
                  placeholder="Beschreibung"
                  onChange={contentChangeHandler}
                  onBlur={contentBlurHandler}
                  value={content}
                />
                {/* {contentHasError && (
                  <p className="error-text mb-2">
                    {'Beschreibung ' +
                      (content.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )} */}
              </div>
            </div>

            {/* submit */}
            <div className="form-group row my-3">
              <div className="col-md-9 col-lg-10">
                <button
                  /* autoFocus={true} */
                  type="submit"
                  className="btn btn-primary bg-primary border-primary">
                  {edit ? 'Seminar speichern' : 'Seminar hinzufügen'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {!isLoading && !success && errors == null && formContent}
      {!isLoading && !success && errors != null && errorContent}
      {!isLoading && success && successContent}
      {isLoading && loadingSpinner}
    </React.Fragment>
  );
};

export default AddSeminar;
