import React, { useEffect } from 'react';
import 'bootstrap/dist/js/bootstrap.bundle';
import { dom } from '@fortawesome/fontawesome-svg-core';
import { useDispatch, useSelector } from 'react-redux';
import { /* Navigate, */ Routes, Route } from 'react-router-dom';
import { authActions } from './store/auth';

// Navigation
import Navigation from './components/Navigation/Navigation';
import Suche from './components/Navigation/Suche';

// Login
import Login from './pages/auth/Login';
import Logout from './pages/auth/Logout';
import Register from './pages/auth/Register';

// MainNav
// Home
import Home from './pages/Home/Home';

// Labs
import Fertigungsverfahren from './pages/Labs/Fertigungsverfahren/Fertigungsverfahren';
import Koordinatenmesstechnik from './pages/Labs/Koordinatenmesstechnik/Koordinatenmesstechnik';
import Optischemesstechnik from './pages/Labs/Optischemesstechnik/Optischemesstechnik';
import Prueftechnik from './pages/Labs/Prueftechnik/Prueftechnik';
import Werkstoffpruefung from './pages/Labs/Werkstoffpruefung/Werkstoffpruefung';

// Services
import Services from './pages/Services/Services';
//import AddServiceRequest from './pages/ServiceRequest/AddServiceRequest';

// Aktuelles
import News from './pages/News/News';

// Order
import AddOrder from './pages/Order/AddOrder';
import GetOrders from './pages/Order/OrdersOverview';
import EditOrder from './pages/Order/EditOrder';
import DeleteOrder from './pages/Order/DeleteOrder';
import OrderInvoice from './pages/Order/OrderInvoice';

// Footer
import Footer from './components/Footer/Footer';
import UeberMich from './components/Footer/UeberMich';
import Datenschutz from './components/Footer/Datenschutz';
import Impressum from './components/Footer/Impressum';
import LebenslaufUndProjekte from './components/Footer/LebenslaufUndProjekte';
import NotFound from './components/NotFound/NotFound';

// Downloads
import DownloadsOverview from './pages/CustomerDownloads/DownloadsOverview';
import AddCustomerDownload from './pages/CustomerDownloads/AddCustomerDownload';
import EditCustomerDownload from './pages/CustomerDownloads/EditCustomerDownload';
import DeleteCustomerDownload from './pages/CustomerDownloads/DeleteCustomerDownload';
import DownloadCustomerDownload from './pages/CustomerDownloads/DownloadCustomerDownload';

// Subscribe
import Subscribe from './pages/Subscribe/Subscribe';

// Newsletter
import Newsletter from './pages/Newsletter/Newsletter';

// Seminar
import SeminarOverview from './pages/Seminar/SeminarOverview';
import AddSeminar from './pages/Seminar/AddSeminar';
import DeleteSeminar from './pages/Seminar/DeleteSeminar';
import EditSeminar from './pages/Seminar/EditSeminar';

// CSS
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

const App = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const JWT_EXPIRES_AT = useSelector((state) => state.auth.JWT_EXPIRES_AT);

  // define logout conditions
  let logout = false;
  try {
    // check if jwtToken is expired
    const today = Date.now();
    const expire = JWT_EXPIRES_AT ? Date.parse(JWT_EXPIRES_AT) : 0;
    const jwtTokenExpired = expire - today <= 0;
    logout = jwtTokenExpired ? true : false;
  } catch {
    logout = true;
  }

  // auto-logout if logout is true
  useEffect(() => {
    if (logout || isAuth === false) {
      dispatch(authActions.logout());
    }
  }, [dispatch, isAuth, logout]);

  dom.watch(); // für FontAwesome wichtig
  return (
    <React.Fragment>
      <Navigation />
      <Routes>
        {/* HeaderNav */}
        <Route path="/Suche" element={<Suche />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Logout" element={<Logout />} />
        <Route path="/Register" element={<Register />} />

        {/* MainNav */}
        <Route path="/Dienstleistungen" element={<Services />} />
        <Route path="/Aktuelles" element={<News />} />

        {/* Home */}
        <Route path="/" element={<Home />} />

        {/* Labore */}
        <Route
          path="/Labore/Fertigungsverfahren"
          element={<Fertigungsverfahren />}
        />
        <Route
          path="/Labore/Koordinatenmesstechnik"
          element={<Koordinatenmesstechnik />}
        />
        <Route
          path="/Labore/Optischemesstechnik"
          element={<Optischemesstechnik />}
        />
        <Route path="/Labore/Prueftechnik" element={<Prueftechnik />} />
        <Route
          path="/Labore/Werkstoffpruefung"
          element={<Werkstoffpruefung />}
        />

        {/* Footer */}
        <Route path="/UeberMich" element={<UeberMich />} />
        <Route path="/Datenschutz" element={<Datenschutz />} />
        <Route path="/Impressum" element={<Impressum />} />
        <Route
          path="/LebenslaufUndProjekte"
          element={<LebenslaufUndProjekte />}
        />

        {/* Dinestleistung buchen */}
        <Route path="/Dienstleistung/buchen" element={<AddOrder />} />
        <Route
          path="/Order/Übersicht"
          element={isAuth ? <GetOrders /> : <Home />}
        />
        <Route
          path="/Order/bearbeiten/:id"
          element={isAuth ? <EditOrder /> : <Home />}
        />
        <Route
          path="/Order/löschen/:id"
          element={isAuth ? <DeleteOrder /> : <Home />}
        />
        <Route
          path="/Order/Rechnung/:id"
          element={isAuth ? <OrderInvoice /> : <Home />}
        />

        {/* AddCustomerDownload */}
        <Route
          path="/Download/Hinzufügen"
          element={isAuth ? <AddCustomerDownload /> : <Home />}
        />
        <Route
          path="/Downloads/Hinzufügen"
          element={isAuth ? <AddCustomerDownload /> : <Home />}
        />
        <Route
          path="/Download/bearbeiten/:id"
          element={isAuth ? <EditCustomerDownload /> : <Home />}
        />
        <Route
          path="/Downloads/bearbeiten/:id"
          element={isAuth ? <EditCustomerDownload /> : <Home />}
        />
        <Route
          path="/Download/löschen/:id"
          element={isAuth ? <DeleteCustomerDownload /> : <Home />}
        />
        <Route
          path="/Downloads/löschen/:id"
          element={isAuth ? <DeleteCustomerDownload /> : <Home />}
        />

        {/* DownloadsOverview */}
        <Route
          path="/Download/Übersicht"
          element={isAuth ? <DownloadsOverview /> : <Home />}
        />
        <Route
          path="/Downloads/Übersicht"
          element={isAuth ? <DownloadsOverview /> : <Home />}
        />

        {/* Downloads Customers */}
        <Route
          path="/Kunden-Download/:uuid"
          element={<DownloadCustomerDownload />}
        />

        {/* Subscribe */}
        <Route path="/Subscribe" element={<Subscribe />} />
        <Route path="/Unsubscribe" element={<Subscribe unsubscribe={true} />} />

        {/* Newsletter */}
        <Route
          path="/Newsletter"
          element={isAuth ? <Newsletter /> : <Home />}
        />

        {/* Seminar */}
        <Route
          path="/Seminar/hinzufügen"
          element={isAuth ? <AddSeminar /> : <Home />}
        />
        <Route
          path="/Seminar/Hinzufügen"
          element={isAuth ? <AddSeminar /> : <Home />}
        />
        <Route
          path="/Seminar/bearbeiten/:uuid"
          element={isAuth ? <EditSeminar /> : <Home />}
        />
        <Route
          path="/Seminar/Bearbeiten/:uuid"
          element={isAuth ? <EditSeminar /> : <Home />}
        />
        <Route
          path="/Seminar/löschen/:uuid"
          element={isAuth ? <DeleteSeminar /> : <Home />}
        />
        <Route
          path="/Seminar/Löschen/:uuid"
          element={isAuth ? <DeleteSeminar /> : <Home />}
        />
        <Route
          path="/Seminar/Übersicht"
          element={isAuth ? <SeminarOverview /> : <Home />}
        />
        <Route
          path="/Seminare/Übersicht"
          element={isAuth ? <SeminarOverview /> : <Home />}
        />

        {/* 404 - NotFound */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </React.Fragment>
  );
};

export default App;
