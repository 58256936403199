import { createSlice } from '@reduxjs/toolkit';

const initialAuthState = {
  // look into localStorage to check if there is an authState present
  isAuthenticated: localStorage.getItem('token') != null ? true : false,
  token:
    localStorage.getItem('token') != null
      ? localStorage.getItem('token')
      : null,
  id: localStorage.getItem('id') != null ? localStorage.getItem('id') : null,
  uuid:
    localStorage.getItem('uuid') != null ? localStorage.getItem('uuid') : null,
  firstname:
    localStorage.getItem('firstname') != null
      ? localStorage.getItem('firstname')
      : null,
  lastname:
    localStorage.getItem('lastname') != null
      ? localStorage.getItem('lastname')
      : null,
  JWT_EXPIRES_AT:
    localStorage.getItem('JWT_EXPIRES_AT') != null
      ? localStorage.getItem('JWT_EXPIRES_AT')
      : null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    login(state, action) {
      // save to state
      state.isAuthenticated = true;
      state.token = action.payload.token;
      state.id = action.payload.id;
      state.uuid = action.payload.uuid;
      state.firstname = action.payload.firstname;
      state.lastname = action.payload.lastname;
      state.JWT_EXPIRES_AT = action.payload.JWT_EXPIRES_AT;
      // save to localStorage
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('id', action.payload.id);
      localStorage.setItem('uuid', action.payload.uuid);
      localStorage.setItem('firstname', action.payload.firstname);
      localStorage.setItem('lastname', action.payload.lastname);
      localStorage.setItem('JWT_EXPIRES_AT', action.payload.JWT_EXPIRES_AT);
    },
    logout(state) {
      // remove from state
      state.isAuthenticated = false;
      state.token = null;
      state.id = null;
      state.uuid = null;
      state.firstname = null;
      state.lastname = null;
      state.JWT_EXPIRES_AT = null;
      // remove from localStorage
      localStorage.removeItem('token');
      localStorage.removeItem('id');
      localStorage.removeItem('uuid');
      localStorage.removeItem('firstname');
      localStorage.removeItem('lastname');
      localStorage.removeItem('JWT_EXPIRES_AT');
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
