import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useHttp from '../../hooks/use-http';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import { apiURL } from '../../utils/Constants';
import AddCustomerDownload from './AddCustomerDownload';
// import './EditCustomerDownload.css';

const EditCustomerDownload = () => {
  const params = useParams();
  const customerDownloadID = params.id; // id: from src/App.jsx -> Routes
  const { isLoading, errors, /* setErrors,*/ sendRequest } = useHttp();
  const [customerDownload, setCustomerDownload] = useState([]);
  const [success, setSuccess] = useState(false); // only for development (React.strict)
  const token = useSelector((state) => state.auth.token);

  // get CustomerDownload
  useEffect(() => {
    const json = (json) => {
      setCustomerDownload(json.data);
      setSuccess(true);
    };

    sendRequest(
      {
        url: apiURL + '/customer_downloads/get/' + customerDownloadID,
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      },
      json
    );
  }, [sendRequest, customerDownloadID]);

  const errorContent = (
    <React.Fragment>
      <center className="py-5">
        <h3 className="error-text">
          Der Download konnte nicht geladen werden!
        </h3>
        <br />
        {errors && errors.hasOwnProperty('data') ? (
          <p className="error-text pb-5">{JSON.stringify(errors.data)}</p>
        ) : null}
        <Link to={'/Download/Übersicht'}>
          <button autoFocus={true} className="btn bg-primary border-primary">
            Zurück zur Download Übersicht
          </button>
        </Link>
      </center>
    </React.Fragment>
  );

  const successContent = (
    <AddCustomerDownload
      customerDownloadToEdit={customerDownload}></AddCustomerDownload>
  );

  const loadingSpinner = (
    <React.Fragment>
      <center className="py-5">
        <b>Lade Download ...</b>
        <br />
        <LoadingSpinner />
      </center>
    </React.Fragment>
  );

  const somethingWentWrongContent = (
    <React.Fragment>
      <center className="py-5">
        <h3 className="error-text">Etwas ist schief gelaufen :(</h3>
        <br />
        <Link to={'/Download/Übersicht'}>
          <button autoFocus={true} className="btn bg-primary border-primary">
            Zurück zur Download Übersicht
          </button>
        </Link>
      </center>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {!isLoading && !success && errors == null && somethingWentWrongContent}
      {!isLoading && !success && errors != null && errorContent}
      {!isLoading && success && successContent}
      {isLoading && loadingSpinner}
    </React.Fragment>
  );
};

export default EditCustomerDownload;
