import React from 'react';
import { Link } from 'react-router-dom';
import Accordion from '../../components/Accordion';
// import "./News.css";

const News = () => {
  const eventList = [
    /*
            Anleitung zum Hinzufügen Aktuelles & Archiv:
            1. geschweifte Klammern mit Werten kopieren und
            unter das Array anfügen (Komma vorher nicht vergessen)

                {
                    eventID:    "Position des Events",
                    isActual:   true/false,
                    title:      "Überschrift",
                    subtitle:   "Text zur Überschrift",
                    content:    "Text/Inhalt im ausgeklappten Zustand" ,
                    buttonTitle:"Text auf Button"
                    buttonUrl:  "Button link/Pfad"
                    images: [
                        {
                            imgUrl: require("../../assets/img/partner/partner_logo.webp"),
                            imgAlt: "Beschreibung/Nummerierung bei mehreren"
                        },
                        {
                            imgUrl: require("../../assets/img/partner/partner_logo.webp"),
                            imgAlt: "2"
                        }
                    ],
                    downloads: [
                        {
                            title:  "Titel vom Download",
                            link:   "Link/Pfad des Downloads",
                            image:  "Bildlink des Downloads"
                        },
                        {
                            title:  "Titel vom 2ten Download",
                            link:   "Link/Pfad des 2ten Downloads",
                            image:  "Bildlink des 2ten Downloads"
                        }
                    ],
                    partner: [
                        {
                            title:  "Name Partner",
                            text:   "Name Partner",
                            img:    "Bildlink des Partners",
                            url:    "Link Webseite Partner"
                        },
                        {
                            title:  "Name 2ten Partner",
                            text:   "Name 2ten Partner",
                            img:    "Bildlink des 2ten Partners",
                            url:    "Link Webseite des 2ten Partners"
                        }
                    ]
                },
            2. eventID bestimmt Position des Events, Niedrigere Zahlen werden zuerst gezeigt
            3. isActual falls true wird es unter Aktuelles gezeigt, bei false wird es unter Archiv angezeigt
            4. button, downloads und partner können weggelassen werden, für mehrere {} innerhalb der [] kopieren und ausfüllen,
            Komma zwischen geschweiften Klammern {},{} nicht vergessen
        */
    {
      eventID: 'One',
      isActual: true,
      title: 'Runder Geburtstag ',
      subtitle:
        'Das Steinbeis Transferzentrum von Prof. Dr.-Ing. Nico Blessing feiert seinen 10. Geburtstag',
      content: '',
      images: [
        {
          imgUrl: require('../../assets/img/archivbilder/11.10Jahre.jpg'),
          imgAlt: 'GOMScanCobotATOSQ',
        },
      ],
      downloads: [
        {
          title: 'Runder Geburtstag.pdf',
          link: '/downloads/RunderGeburtstag.pdf',
          image: require('../../assets/docicons/pdf.webp'),
        },
      ],
    },
    {
      eventID: 'Two',
      isActual: false,
      title: 'Besuch der Präsidentin ',
      subtitle:
        'Ende März 2022 war die neue Präsidentin der DHBW, Frau Prof. Martina Klärle, am Standort Heidenheim zu Besuch und hat unter anderem die Labore besichtigt',
      content: '',
      images: [
        {
          imgUrl: require('../../assets/img/archivbilder/BesuchPraesidentin/1.jpeg'),
          imgAlt: '1',
        },
        {
          imgUrl: require('../../assets/img/archivbilder/BesuchPraesidentin/2.jpeg'),
          imgAlt: '2',
        },
        {
          imgUrl: require('../../assets/img/archivbilder/BesuchPraesidentin/3.jpeg'),
          imgAlt: '3',
        },
        {
          imgUrl: require('../../assets/img/archivbilder/BesuchPraesidentin/4.jpeg'),
          imgAlt: '4',
        },
      ],
    },
    {
      eventID: 'Three',
      isActual: false,
      title: 'Neue Materialprüfmaschine',
      subtitle:
        'Die neue Materialprüfmaschine wurde im März geliefert und wird in kürze in Betrieb genommen',
      content:
        'Die Tisch-Prüfmaschine ist für quasistatische Prüfungen in Zug- und Druckrichtung ausgelegt. Die Belastung kann dabei zügig, ruhend schwellend oder wechselnd sein. Genaueres können Sie der Laborseite entnehmen.',
      buttonTitle: 'Zum Labor',
      buttonUrl: '/Labore/Werkstoffpruefung',
      images: [
        {
          imgUrl: require('../../assets/img/archivbilder/Materialpruefmaschine/1.jpg'),
          imgAlt: '1',
        },
        {
          imgUrl: require('../../assets/img/archivbilder/Materialpruefmaschine/2.jpg'),
          imgAlt: '2',
        },
        {
          imgUrl: require('../../assets/img/archivbilder/Materialpruefmaschine/3.jpg'),
          imgAlt: '3',
        },
        {
          imgUrl: require('../../assets/img/archivbilder/Materialpruefmaschine/4.jpg'),
          imgAlt: '4',
        },
        {
          imgUrl: require('../../assets/img/archivbilder/Materialpruefmaschine/5.jpg'),
          imgAlt: '5',
        },
      ],
    },
    {
      eventID: 'Four',
      isActual: false,
      title: 'Neues Messgerät ',
      subtitle: 'Der Scan Cobot GOM ATOS Q im Labor für optische Messtechnik',
      content:
        'Im Labor für optische Messtechnik der DHBW Heidenheim werden mit dem Streifenprojektionsscanner ATOS III der Firma GOM mbH Bauteile digitalisiert, d.h. Oberflächen dreidimensional erfasst, welche anschließend mittels geeigneter Software-Werkzeuge analysiert und ausgewertet werden, um Fehler zu diagnostizieren und die Qualität der Objekte zu beurteilen. Genaueres können Sie der Laborseite entnehmen.',
      buttonTitle: 'Zum Labor',
      buttonUrl: '/Labore/Optischemesstechnik',
      images: [
        {
          imgUrl: require('../../assets/img/GOMScanCobot.png'),
          imgAlt: 'GOMScanCobotATOSQ',
        },
      ],
    },
    {
      eventID: 'Five',
      isActual: false,
      title: 'Gastprofessur ',
      subtitle:
        'Seit Januar 2022 ist Prof. Dr.-Ing. Nico Blessing zusätzlich Gastprofessor an der University of Toronto',
      content: '',
      buttonTitle: 'Webiste',
      buttonUrl: 'https://www.utoronto.ca/',
      images: [
        {
          imgUrl: require('../../assets/img/archivbilder/toronto.png'),
          imgAlt: 'DHBW',
        },
      ],
    },
    {
      eventID: 'Six',
      isActual: false,
      title: 'AdaptSave',
      subtitle:
        'Neue BMBF gefördertes Forschungsprojekt zum Thema Feuerlöscher',
      content:
        'Feuerlöscher und Löschanlagen sind unumgängliche und notwendige Einrichtungen für einen sicheren Personentransport. Aber nicht jedes Transportmittel verfügt über den notwendigen Stauraum für die eigentlich erforderlichen Feuerlöscher bzw. Löschmittelbehälter. Insbesondere Busse sind, wie immer  wieder den Medien zu entnehmen, besonders gefährdet. Auch im Bereich des emissionsfreien Automobils, beispielsweise bei leicht entzündlichem Wasserstoff und die Brandgefährdung durch Akkus, ist eine Verbesserung des Brandschutzes notwendig. Ziel des Projektes ist es deshalb, einen in seiner Form adaptierten Druckbehälter aus Composite-Werkstoffen herzustellen, d.h. es werden Seitenwände in beliebiger Form für den Löscher verwendet.',
      images: [
        {
          imgUrl: require('../../assets/img/archivbilder/3feuerlöscher.jpg'),
          imgAlt: '3feuerlöscher',
        },
        {
          imgUrl: require('../../assets/img/archivbilder/4feuerlöscher.jpg'),
          imgAlt: '4feuerlöscher',
        },
        {
          imgUrl: require('../../assets/img/archivbilder/5feuerlöscher.jpg'),
          imgAlt: '5feuerlöscher',
        },
      ],
      partner: [
        {
          title: 'Frank und Waldenberger GmbH',
          text: 'Frank und Waldenberger GmbH',
          img: require('../../assets/img/partner/frank_und_waldenberger_50.webp'),
          url: 'http://www.frankundwaldenberger.de/',
        },
        {
          title: 'Fischer Gase GmbH',
          text: 'Fischer Gase GmbH',
          img: require('../../assets/img/partner/fischer_gase_50.webp'),
          url: 'https://www.fischergase.de/',
        },
        {
          title: 'Fraunhofer-Institut',
          text: 'Fraunhofer-Institut fuer Produktionstechnik und Automatisierung IPA',
          img: require('../../assets/img/partner/fraunhofer-institut_50.webp'),
          url: 'https://www.ipa.fraunhofer.de/',
        },
        {
          title: 'Ing.-Buero C-C-Urban.',
          text: 'Ing.-Buero C-C-Urban',
          img: require('../../assets/img/partner/c_c_urban_50.webp'),
          url: '#',
        },
      ],
    },
    {
      eventID: 'Seven',
      isActual: false,
      title: 'FME Webinar 2020',
      subtitle: 'FME Webinar 2020',
      content:
        'Aufgrund der Corona-bedingten Einschränkungen konnten durch Einsatz verschiedener Webconferencing Werkzeuge online Schulungen in Form von Webinaren angeboten und durchgeführt werden. In enger Zusammenarbeit mit dem schweizerischen Ingenieurverband Swiss-Engineering wurde den Verbandsmitgliedern eine Webinar-Reihe zu verschiedenen Methoden und Werkzeugen des Produktentstehungsprozesses angeboten.',
      images: [
        {
          imgUrl: require('../../assets/img/archivbilder/1fmewebinar.jpg'),
          imgAlt: '1fmewebinar',
        },
        {
          imgUrl: require('../../assets/img/archivbilder/2fmewebinar.jpg'),
          imgAlt: '2fmewebinar',
        },
      ],
      downloads: [
        {
          title: 'vorlageFME.xlsx',
          link: '/downloads/4VorlageFMEA.xlsx',
          image: require('../../assets/docicons/xlsx.webp'),
        },
        {
          title: 'DFMEA Training.pdf',
          link: '/downloads/dfmea_training.pdf',
          image: require('../../assets/docicons/pdf.webp'),
        },
      ],
    },
    {
      eventID: 'Eight',
      isActual: false,
      title: 'Letze FMEA-Schulung 2019',
      subtitle:
        'Die letzte FMEA Schulung  fand am 4. Und 5. Dezember in Zang statt.',
      content:
        'An zwei Tagen wurden die Teilnehmer aus mehreren Unternehmen aus dem deutschsprachigen Raum fit gemacht für den Umgang mit der Risikobeurteilungsmethode der Design-FMEA nach neustem Standard des VDA/AIAG. In angenehmer Atmosphäre in den Seminarräumen des Hotels Widmanns Albleben in Zang wurden die Theorie der Design-FMEA vermittelt und mit direktem Praxisbezug anhand von individuellen und auf die teilnehmenden Firmen angepassten Beispielen trainiert.',
      images: [
        {
          imgUrl: require('../../assets/img/archivbilder/9seminar.jpg'),
          imgAlt: '9seminar',
        },
        {
          imgUrl: require('../../assets/img/archivbilder/8seminar.jpg'),
          imgAlt: '8seminar',
        },
        {
          imgUrl: require('../../assets/img/archivbilder/7Küche.jpg'),
          imgAlt: '7küche',
        },
        {
          imgUrl: require('../../assets/img/archivbilder/6tisch.jpg'),
          imgAlt: '6tisch',
        },
      ],
      downloads: [
        {
          title: 'vorlageFME.xlsx',
          link: '/downloads/4VorlageFMEA.xlsx',
          image: require('../../assets/docicons/xlsx.webp'),
        },
        {
          title: 'DFMEA Training.pdf',
          link: '/downloads/dfmea_training.pdf',
          image: require('../../assets/docicons/pdf.webp'),
        },
      ],
    },
    {
      eventID: 'Nine',
      isActual: false,
      title: 'BMBF-gefördertes Forschungsprojekt',
      subtitle:
        'Im Rahmen eines BMBF-Projekts wurde ein leichter und zulassungsfähiger Kabinenfeuerlöscher aus kohlenstofffaserverstärktem Kunststoff (CFK) für die Luftfahrt entwickelt. Die Gewichtsreduzierung dient dabei der Energieeffizienz und somit auch der CO2-Reduzierung im Luftfahrtbereich. Für die Entwicklung wurde die Thermo- und Computertomographie als Prüftechnik eingesetzt.',
      content:
        'Im Rahmen eines BMBF-Projekts wurde ein leichter und zulassungsfähiger Kabinenfeuerlöscher aus kohlenstofffaserverstärktem Kunststoff (CFK) für die Luftfahrt entwickelt. Die Gewichtsreduzierung dient dabei der Energieeffizienz und somit auch der CO2-Reduzierung im Luftfahrtbereich. Für die Entwicklung wurde die Thermo- und Computertomographie als Prüftechnik eingesetzt. Ergebnisse Klassische Kabinenfeuerlöscher bestehen aus Blech und enthalten ca. ein Kilogramm des bewährten, weil sehr wirksamen und für Menschen ungiftigen, jedoch die Ozonschicht schädigenden Löschmittels Halon. Dies führt dazu, dass Halon als Löschmittel in Flugzeugen in absehbarer Zeit ersetzt werden muss. Allerdings weisen alternative Löschmittel eine geringere Löschleistung pro Volumen auf, was zu einer Erhöhung der mitgeführten Löschmittelmenge führt. Durch den Einsatz des im Projekt entwickelten leichten CFK-Löschers kann das Gesamtgewicht um bis zu 33 Prozentreduziert werden. Das bedeutet, dass eine größere Löschmittelmenge ohne Gewichtserhöhung mitgeführt bzw. bei gleicher Löschleistung das Gesamtgewicht reduziert werden kann. Die hergestellten Behälter wurden im Rahmen der Entwicklungsunterstützung mittels zerstörungsfreier Prüfmethoden untersucht. Dabei wurden sowohl qualitative als auch quantitative Prüfungen mittels Industrieller Computertomographie (CT) durchgeführt. Durch die ebenfalls zerstörungsfreie Untersuchungsmethode Thermographie konnten nicht nur Risse in den Behältern erkannt werden, diese Prüfmethodik ist im Gegensatz zur CT auch innerhalb einer 100 Prozent-Kontrolle in der Serienfertigung einsetzbar Förderkennzeichen: 01LY1414A-Fb',
      images: [
        {
          imgUrl: require('../../assets/img/archivbilder/BMBF.png'),
          imgAlt: 'BMBF',
        },
        {
          imgUrl: require('../../assets/img/archivbilder/3feuerlöscher.jpg'),
          imgAlt: '3feuerlöscher',
        },
        {
          imgUrl: require('../../assets/img/archivbilder/4feuerlöscher.jpg'),
          imgAlt: '4feuerlöscher',
        },
        {
          imgUrl: require('../../assets/img/archivbilder/5feuerlöscher.jpg'),
          imgAlt: '5feuerlöscher',
        },
      ],
      partner: [
        {
          title: 'FBS Finish GmbH',
          text: 'FBS Finish GmbH',
          img: require('../../assets/img/partner/fbs_50.webp'),
          url: 'https://www.fbs-finish.de/',
        },
        {
          title: 'Fischer Gase GmbH',
          text: 'Fischer Gase GmbH',
          img: require('../../assets/img/partner/fischer_gase_50.webp'),
          url: 'https://www.fischergase.de/',
        },
        {
          title: 'Frank und Waldenberger GmbH',
          text: 'Frank und Waldenberger GmbH',
          img: require('../../assets/img/partner/frank_und_waldenberger_50.webp'),
          url: 'http://www.frankundwaldenberger.de/',
        },
        {
          title: 'Ing.-Buero C-C-Urban.',
          text: 'Ing.-Buero C-C-Urban',
          img: require('../../assets/img/partner/c_c_urban_50.webp'),
          url: '#',
        },
        {
          title: 'Ing.-Buero Dipl.-Inf. Andre Jansen',
          text: 'Ing.-Buero Dipl.-Inf. Andre Jansen',
          img: require('../../assets/img/partner/ingeneurbuero_jansen_50.webp'),
          url: 'https://www.ibjansen.de/',
        },
      ],
    },
    {
      eventID: 'Ten',
      isActual: false,
      title: 'QualiFibre',
      subtitle:
        'Es wurde eine Software-Plattform für die gemeinsame Auswertung, Darstellung und Fusion der Daten der zerstörungsfreien Prüfmethoden Computertomographie (CT), Ultraschall und Thermographie entwickelt. Der Fokus lag hierbei auf der Prüfung von Kohle- (CFK) und Glasfaserverbundkunststoffen (GFK).',
      content:
        'Im Rahmen des EU-Projektes QualiFibre wurden die zerstörungsfreien Prüfmethoden CT, Ultraschall und Thermographie betrachtet. Der Fokus lag hierbei auf der Untersuchung von Faserverbundkunststoffen (FVK), insbesondere CFK und GFK. Jede der genannten Prüfmethoden hat ihre spezifischen Vor- und Nachteile. Um den größtmöglichen Mehrwert zu generieren, wurde eine Software-Plattform für die gemeinsame Auswertung, Darstellung und Fusion der mit den Methoden erzeugten Daten entwickelt. Für die Auswertung der dreidimensionalen CT-Daten sind in der Plattform Algorithmen zur Defekterkennung, Analyse der Faserorientierung und der Porosität enthalten. Auch die zweidimensionalen Ultraschall- und Thermographiedaten werden automatisiert hinsichtlich Fehlstellen untersucht. Da manche Defekte nicht in beiden Prüfbildern enthalten sind, wurde eine Datenfusion entwickelt. Hierdurch wird es ermöglicht, die maximale Aussagekraft aus beiden Prüftechniken zu  erhalten und die Erkennungsrate der Defekte in Summe zu erhöhen. Auch wenn die entwickelten Verfahren für FVK ausgelegt wurden, sind sie dennoch auf eine Vielzahl anderer Materialarten übertragbar.',
      buttonTitle: 'Zum Projekt',
      buttonUrl: 'https://cordis.europa.eu/project/id/315639/reporting',
      images: [
        {
          imgUrl: require('../../assets/img/archivbilder/10qualifibre.jpg'),
          imgAlt: 'BMBF',
        },
      ],
      downloads: [
        {
          title: 'Meeting QualiFibre.pdf',
          link: '/downloads/MeetingQualiFibre.pdf',
          image: require('../../assets/docicons/pdf.webp'),
        },
        {
          title: 'Meeting QualiFibre WP2.pdf',
          link: '/downloads/MeetingQualiFibreWP2.pdf',
          image: require('../../assets/docicons/pdf.webp'),
        },
        {
          title: 'Repair Procedures QualiFibre.pdf',
          link: '/downloads/WP5_RepairProceduresQualiFibre.pdf',
          image: require('../../assets/docicons/pdf.webp'),
        },
      ],
      partner: [
        {
          title: 'EMPA',
          text: 'Eidgenössische Materialpüfungs- und Forschungsanstalt',
          img: require('../../assets/img/partner/empa_50.webp'),
          url: 'https://www.empa.ch/',
        },
        {
          title: 'RayScan Technologies GmbH',
          text: 'RayScan Technologies GmbH',
          img: require('../../assets/img/partner/rayscan_50.webp'),
          url: 'https://www.rayscan.eu/',
        },
        {
          title: 'Sonotec',
          text: 'Sonotec Ultraschallsensorik Halle GmbH',
          img: require('../../assets/img/partner/sonotec_50.webp'),
          url: 'https://www.sonotec.de/',
        },
        {
          title: 'Tomo Adour S.A.',
          text: 'Tomo Adour S.A.',
          img: require('../../assets/img/partner/tomo_adour_s_a_50.webp'),
          url: 'https://www.airframer.com/direct_detail.html?company=118700',
        },
        {
          title: 'Fraunhofer-Institut',
          text: 'Fraunhofer-Institut fuer Produktionstechnik und Automatisierung IPA',
          img: require('../../assets/img/partner/fraunhofer-institut_50.webp'),
          url: 'https://www.ipa.fraunhofer.de/',
        },
      ],
    },
  ];

  return (
    <section id="Aktuelles">
      <div className="container text-center my-5">
        <h2 className="mt-5">Aktuelle Veranstaltungen und Projekte</h2>
        <Link to="/Subscribe">
          <h5 className="mb-5">
            Nichts verpassen? hier zum Newsletter anmelden!
          </h5>
        </Link>

        <div className="row mb-5">
          {eventList.map((event) => {
            event.collapsed = false;
            // Zeige nur Veranstaltungen mit isActual = true
            if (event.isActual) {
              return <Accordion key={event.eventID} event={event} />;
            } else {
              return null;
            }
          })}
        </div>

        <h1 className="mt-5">Archiv</h1>
        <div className="row mb-5">
          {eventList.map((event) => {
            event.collapsed = true;
            // Zeige nur Veranstaltungen mit isActual = false
            if (!event.isActual) {
              return <Accordion key={event.eventID} event={event} />;
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </section>
  );
};

export default News;
