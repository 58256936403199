import React from 'react';
import './Carousel.css';

/*
    Beispiel:
        <Carousel
            key=0                           // erforderlich: für react, muss eindeutig sein!
            carouselID="One"                // erforderlich: muss eindeutig sein!
            images=images=[
                {
                    imgUrl: require("../assets/img/image1.jpg"),
                    imgAlt: "image1"
                },
                {
                    imgUrl: require("../assets/img/image2.jpg"),
                    imgAlt: "image2"
                }
            ]                               // erforderlich: Bilder für das Carousel
                                            //  ab 2 Bilder im Array erscheinen Buttons zur Navigation
        />
 */

const Carousel = (props) => {
  const id = 'carouselID' + (props.carouselID ? props.carouselID : 'Example');
  const classes = 'carousel slide ' + (props.className ? props.className : '');
  return (
    <div
      id={id}
      className={classes}
      data-bs-ride="carousel"
      data-bs-interval="3000">
      <div className="carousel-inner">
        {props.images
          ? props.images.map((image, index) => {
              return (
                <div
                  key={index}
                  className={'carousel-item' + (index === 0 ? ' active' : '')}>
                  <img
                    src={image.imgUrl}
                    className="d-block w-100"
                    alt={image.imgAlt}
                  />
                </div>
              );
            })
          : null}
      </div>
      {
        // zeige Buttons erst ab 2 Bilder
        props.images[1] ? (
          <React.Fragment>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target={'#' + id}
              data-bs-slide="prev">
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target={'#' + id}
              data-bs-slide="next">
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </React.Fragment>
        ) : null
      }
    </div>
  );
};

export default Carousel;
