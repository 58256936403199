import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useHttp from '../../hooks/use-http';
import { apiURL } from '../../utils/Constants';
import CustomerDownloads from './components/CustomerDownloads';
// import './CustomerDownloads.css';

const DownloadsOverview = () => {
  const { isLoading, errors, sendRequest } = useHttp();
  const [customerDownloads, setCustomerDownloads] = useState([]);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const json = (json) => {
      setCustomerDownloads(json.data);
    };

    sendRequest(
      {
        url: apiURL + '/customer_downloads/fetchAll',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      },
      json
    );
  }, [sendRequest]);

  return (
    <React.Fragment>
      <section id="downloadsOverview">
        <CustomerDownloads
          isLoading={isLoading}
          errors={errors}
          data={customerDownloads}
        />
      </section>
    </React.Fragment>
  );
};

export default DownloadsOverview;
