import React from 'react';
import { Link } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { convertStateToName } from '../../../utils/attributeConverter';
import { convertToDate } from '../../../utils/attributeConverter';
import { faTrash, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import './OrderCard.css';

const OrderCard = (props) => {
  const order = props.order;
  const orderState = convertStateToName(order.state);
  const orderDate = convertToDate(order.orderDate);

  library.add(faTrash, faPenToSquare);

  return (
    <div className="row border">
      {/* orderDate */}
      <div className="col-3 uuid">{orderDate}</div>
      {/* firstname */}
      <div className="col">{order.firstname}</div>
      {/* lastname */}
      <div className="col">{order.lastname}</div>
      {/* company */}
      <div className="col">{order.company}</div>
      {/* state */}
      <div className="col">{orderState}</div>
      {/* buttons */}
      <div className="col-1 buttons">
        <div className="row justify-content-between">
          <div className="col-6">
            <Link to={'/Order/löschen/' + order.orderID} className="">
              <i className="fa-solid fa-trash ms-2"></i>
            </Link>
          </div>
          <div className="col-6">
            <Link to={'/Order/bearbeiten/' + order.orderID} className="">
              <i className="fa-solid fa-pen-to-square"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
