import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faDatabase,
  faGlasses,
  faScrewdriverWrench,
  faMicroscope,
  faGraduationCap,
  faFileZipper,
} from '@fortawesome/free-solid-svg-icons';
import TextBox from '../../../components/TextBox';
import Button from '../../../components/Button';
import Download from '../../../components/Download';
import '../Labs.css';

const Werkstoffpruefung = () => {
  library.add(
    faDatabase,
    faGlasses,
    faScrewdriverWrench,
    faMicroscope,
    faGraduationCap,
    faFileZipper
  );
  const textBoxListREM = [
    /*
            Anleitung zum Hinzufügen neuer Inhalte:
                1. geschweifte Klammern mit Werten kopieren und
                   unter das Array anfügen (Komma vorher nicht vergessen)

                   {
                       title: " Titel",
                       bulletPoints: [
                           "Punkt 1",
                           "Punkt 2",
                           "Punkt 2",
                           "Punkt 2"
                       ],
                       fontAwesome: "fa-solid fa-symbol"
                   },

                2. Titel und Text der bulletPoints abändern
                3. Passendes Symbol auf https://fontawesome.com/icons aussuchen (Bsp: <i class="fa-solid fa-database"></i>)
                    1. Dieses Symbol (fa-database) aus dem Datenpaket '@fortawesome/free-solid-svg-icons' oben bei den Imports hinzufügen
                    dabei ist darauf zu achten dass die Bindestriche durch Großbuchstaben ersetzt werden (Bsp. fa-database -> faDatabase)
                    2. zur Library vor dem Datenarray hinzufügen
                    vorher: library.add(faGlasses, faScrewdriverWrench,...);
                    nachher: library.add(faGlasses, faScrewdriverWrench,..., faDatabase);
                    3. im Array aufrufen (fontAwesome: "fa-solid fa-database)
                4. ggf. einfügen
                <div>
                <Download
                    key= "0"
                    className= "my-3"
                    title="Dateiname.dateiendung"
                    link="/downloads/Dateiname.dateiendung"
                    fontAwesome="fa-solid fa-file-zipper"
                />
                </div>
        */
    {
      title: 'Maschinendaten',
      bulletPoints: [
        'Rasterelektronenmikroskop (REM) Sigma VP, Carl Zeiss Microscopy GmbH',
        'Detektoren: SE, SE-VP, IN-Lens, BSD, EDX, WDX',
        'ATLAS System zur hochaufgelösten Rasterelektronenmikroskopie, Carl Zeiss Microscopy GmbH',
        'Zug-, Druck-, Biege-Einrichtung zur Untersuchung von Proben in belasteten Zuständen',
        'Temperiereinrichtung zur Analyse von Proben von minus 20 bis plus 100 °C',
        'Auswertesoftware REM, Carl Zeiss Microscopy GmbH',
        'Auswertesoftware EDX, WDX, BrukerNano Analytics GmbH',
      ],
      fontAwesome: 'fa-solid fa-database',
    },
    {
      title: 'Visualisierung',
      bulletPoints: [
        'Hochauflösende Bilder (32.000 x 32.000)',
        'Detailansichten von Oberflächen bis zu nanoskaliger Strukturen (1.000.000-fache Vergrößerung)',
        'Auswertung der Topographie von Materialproben',
        'Detektion von Rauigkeiten und Verunreinigungen',
        'Umfassende Schadensanalyse und Fehleridentifikation',
      ],
      fontAwesome: 'fa-solid fa-glasses',
    },
  ];

  const textBoxListPT = [
    {
      title: 'Maschinendaten',
      bulletPoints: [
        'Zug und Druckbelastung',
        'schwellend, ruhend, zügig oder abwechselnde Belastung',
        '100 kN Prüfkraft',
        '1755mm x 640mm Prüfraum',
      ],
      fontAwesome: 'fa-solid fa-database',
    },
    {
      title: 'Komponenten',
      bulletPoints: [
        <a
          href="https://www.zwickroell.com/de-de/universalpruefmaschinen/allroundline-material-pruefmaschine"
          target="_blank"
          rel="noreferrer">
          AllroundLine
        </a>,
        <a
          href="https://www.zwickroell.com/de-de/kraftaufnehmer/xforce"
          target="_blank"
          rel="noreferrer">
          Xforce
        </a>,
        <a
          href="http://www.zwickroell.com/de-de/probenhalter"
          target="_blank"
          rel="noreferrer">
          Probenhalter
        </a>,
        <a
          href="http://www.zwickroell.com/de-de/pruefwerkzeuge"
          target="_blank"
          rel="noreferrer">
          Prüfwerkzeuge
        </a>,
        <a
          href="http://www.zwickroell.com/de-de/extensometer"
          target="_blank"
          rel="noreferrer">
          Extensometer
        </a>,
        <a
          href="http://www.zwickroell.com/de-de/pruefsoftware/testxpert-iii"
          target="_blank"
          rel="noreferrer">
          testXpert III
        </a>,
        <a
          href="http://www.zwickroell.com/de-de/mess-steuer-regelungstechnik/testcontrol-ii"
          target="_blank"
          rel="noreferrer">
          testControl II
        </a>,
      ],
      fontAwesome: 'fa-solid fa-screwdriver-wrench',
    },
  ];
  const textBoxListLab = [
    {
      title: 'Forschungs- und Kooperationsgebiete',
      bulletPoints: [
        'Unterstützung bei der Qualitätssicherung, wenn firmeneigene Mess- und Prüfverfahren ausgelastet oder nicht verfügbar sind',
        'Beratung und Dienstleistung in den oben genannten Kompetenzbereichen',
        'Gemeinsame Beantragung und Durchführung von nationalen und internationalen industrie- und anwendungsnahen Forschungsprojekten mit Dualen Partnern und Firmenpartnern sowie assoziierten Instituten und anderen Forschungseinrichtungen',
      ],
      fontAwesome: 'fa-solid fa-microscope',
    },
    {
      title: 'Lehrgebiete',
      bulletPoints: [
        'Durchführung von Laborveranstaltungen im Rahmen verschiedener Vorlesungen',
        'Vergabe von Studien- und Projektarbeiten',
      ],
      fontAwesome: 'fa-solid fa-graduation-cap',
    },
  ];
  return (
    <section id="laborContent">
      <div className="container-lg mt-5 justify-content-center align-items-center">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-5">
            {/* Bild  */}
            <img
              className="img-fluid rounded"
              src={require('../../../assets/img/Materialpruefmaschine.png')}
              alt="Zwick/Roell Z100"
            />
            <p>Zwick/Roell Z100</p>
          </div>
          <div className="col-md-5">
            <h2>Labor für Werkstoffprüfung und - Charakterisierung</h2>
            <div className="row mt-4 mb-4">
              <div className="col">
                <h4>
                  Die Tisch-Prüfmaschine ist für quasistatische Prüfungen in
                  Zug- und Druckrichtung ausgelegt. Die Belastung kann dabei
                  zügig, ruhend schwellend oder wechselnd sein.
                </h4>
                {/* Button auf die virtuelle Begehung */}
                {
                  <Button
                    title="360° Blick ins Labor"
                    url="https://my.matterport.com/show/?m=SCEKg6tnmtc&sr=-1.44,1.21&ss=40"
                  />
                }
              </div>
            </div>
          </div>
        </div>
        {
          // map => durchläuft alle TextBoxen in der textBoxList
          textBoxListPT.map(
            (
              { className, classNameCol, title, bulletPoints, fontAwesome },
              index
            ) => {
              return (
                <TextBox
                  // key={index} ist für react zwingend erforderlich!
                  key={index}
                  className="my-4"
                  title={title}
                  bulletPoints={bulletPoints}
                  fontAwesome={fontAwesome}
                />
              );
            }
          )
        }
      </div>
      <div className="container-lg mt-5 justify-content-center align-items-center">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-5">
            {/* Bild  */}
            <img
              className="img-fluid rounded"
              src={require('../../../assets/img/ZigmaVP.png')}
              alt="Zeiss Sigma VP"
            />
            <p>Zeiss Sigma VP</p>
          </div>
          <div className="col-md-5">
            <h2>Rasterelektronenmikroskopie (REM)</h2>
            <div className="row mt-4 mb-4">
              <div className="col">
                <h4>Forschung und Analyse im Nanometerbereich</h4>
                {/* Button auf die virtuelle Begehung */}
                {
                  <Button
                    title="360° Blick ins Labor"
                    url="https://my.matterport.com/show/?m=SCEKg6tnmtc&sr=-1.44,1.21&ss=40"
                  />
                }
              </div>
            </div>
          </div>
        </div>
        {
          // map => durchläuft alle TextBoxen in der textBoxList
          textBoxListREM.map(
            (
              { className, classNameCol, title, bulletPoints, fontAwesome },
              index
            ) => {
              return (
                <TextBox
                  // key={index} ist für react zwingend erforderlich!
                  key={index}
                  className="my-4"
                  title={title}
                  bulletPoints={bulletPoints}
                  fontAwesome={fontAwesome}
                />
              );
            }
          )
        }
      </div>
      <div className="container-lg mt-5 justify-content-center align-items-center">
        <div className="row align-items-center justify-content-center">
          <h2>Laboreigenschaften</h2>
        </div>
        {
          // map => durchläuft alle TextBoxen in der textBoxList
          textBoxListLab.map(
            (
              { className, classNameCol, title, bulletPoints, fontAwesome },
              index
            ) => {
              return (
                <TextBox
                  // key={index} ist für react zwingend erforderlich!
                  key={index}
                  className="my-4"
                  title={title}
                  bulletPoints={bulletPoints}
                  fontAwesome={fontAwesome}
                />
              );
            }
          )
        }
      </div>
      <div>
        <Download
          key="0"
          className="my-3"
          title="Labor für Werkstoffprüfung.zip"
          link="/downloads/Werkstoffpruefung.zip"
          fontAwesome="fa-solid fa-file-zipper"
        />
      </div>
    </section>
  );
};

export default Werkstoffpruefung;
