export const convertStateToName = (state) => {
  switch (state) {
    case 0:
      return 'Offen';
    case 1:
      return 'Angefragt';
    case 2:
      return 'Angenommen';
    case 3:
      return 'Erledigt';
    case 9:
      return 'Abgesagt';
    default:
      return 'Unbekannt';
  }
};

export const convertToDateTime = (date) => {
  // convert MySQL DateTime String to normal DateTime
  // 2023-04-03T06:00:00.000Z -> 2023-04-03 06:00:00
  return String(date).slice(0, 10) + ' ' + String(date).slice(11, 19);
};

export const convertToDate = (date) => {
  // convert MySQL DateTime String to normal DateTime
  // 2023-04-03T06:00:00.000Z -> 2023-04-03 06:00:00
  return String(date).slice(0, 10);
};
