import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import validator from 'validator';
import useHttp from '../../hooks/use-http';
import useInput from '../../hooks/use-input';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import { apiURL } from '../../utils/Constants';
import './Auth.css';

const Register = () => {
  const { isLoading, errors, setErrors, sendRequest } = useHttp();
  const [formIsValid, setFormIsValid] = useState(false);
  // eslint-disable-next-line
  const [response, setResponse] = useState(null);
  const [success, setSuccess] = useState(false); // only for development (React.strict)

  // firstname
  const {
    value: firstname,
    isValid: firstnameIsValid,
    hasError: firstnameHasError,
    valueChangeHandler: firstnameChangeHandler,
    inputBlurHandler: firstnameBlurHandler,
    reset: firstnameReset,
  } = useInput({
    validate: (value) => validator.isAlpha(value.trim(), 'de-DE'),
  });

  // lastname
  const {
    value: lastname,
    isValid: lastnameIsValid,
    hasError: lastnameHasError,
    valueChangeHandler: lastnameChangeHandler,
    inputBlurHandler: lastnameBlurHandler,
    reset: lastnameReset,
  } = useInput({
    validate: (value) => validator.isAlpha(value.trim(), 'de-DE'),
  });

  // email
  const {
    value: email,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: emailReset,
  } = useInput({
    validate: (value) => validator.isEmail(value.trim()),
  });

  // password
  const {
    value: password,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: passwordReset,
  } = useInput({
    validate: (value) =>
      validator.isStrongPassword(value.trim(), {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
        returnScore: false,
        /* // if returnScore: true:
      pointsPerUnique: 1,
      pointsPerRepeat: 0.5,
      pointsForContainingLower: 10,
      pointsForContainingUpper: 10,
      pointsForContainingNumber: 10,
      pointsForContainingSymbol: 10, */
      }), // do not validate user entered password
  });

  // passwordRepeat
  const {
    value: passwordRepeat,
    isValid: passwordRepeatIsValid,
    hasError: passwordRepeatHasError,
    valueChangeHandler: passwordRepeatChangeHandler,
    inputBlurHandler: passwordRepeatBlurHandler,
    reset: passwordRepeatReset,
  } = useInput({
    validate: (value) => (value === password ? true : false),
  });

  const json = (json) => {
    setResponse(json.data);
    setSuccess(true);
  };

  const sendForm = async (event) => {
    await sendRequest(
      {
        url: apiURL + '/auth/register',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: {
          firstname,
          lastname,
          email,
          password,
        },
      },
      json
    );
  };

  // if form was submitted
  const formSubmitHandler = (event) => {
    event.preventDefault();

    // set all fields as touched
    firstnameBlurHandler();
    lastnameBlurHandler();
    emailBlurHandler();
    passwordBlurHandler();
    passwordRepeatBlurHandler();

    // set form to valid if all fields are valid
    if (
      firstnameIsValid &&
      lastnameIsValid &&
      emailIsValid &&
      passwordIsValid &&
      passwordRepeatIsValid
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  };

  const retryHandler = () => {
    setErrors(null);
    setFormIsValid(false);
  };

  const resetHandler = () => {
    setFormIsValid(false);
    setResponse(null);
    setErrors(null);
    setSuccess(false);

    firstnameReset();
    lastnameReset();
    emailReset();
    passwordReset();
    passwordRepeatReset();
  };

  // if form is valid -> send form
  if (formIsValid) {
    setFormIsValid(false);
    sendForm();
  }

  const errorContent = (
    <React.Fragment>
      <center className="py-5">
        <h3 className="error-text">Registrierung fehlgeschlagen!</h3>
        <br />
        {errors && errors.hasOwnProperty('data') ? (
          <p className="error-text pb-5">{JSON.stringify(errors.data)}</p>
        ) : null}
        <button
          autoFocus={true}
          onClick={retryHandler}
          className="btn bg-primary border-primary">
          Nochmal versuchen
        </button>
      </center>
    </React.Fragment>
  );

  const successContent = (
    <React.Fragment>
      <center className="py-5">
        <b className="success-text">Registrierung erfolgreich!</b>
        <br />
        <br />
        <Link to="/login">
          <button
            onClick={resetHandler}
            className="btn bg-primary border-primary"
            autoFocus={true}>
            Weiter zur Anmeldung ...
          </button>
        </Link>
      </center>
    </React.Fragment>
  );

  const loadingSpinner = (
    <React.Fragment>
      <center className="py-5">
        <b>Login ...</b>
        <br />
        <LoadingSpinner />
      </center>
    </React.Fragment>
  );

  const formContent = (
    <React.Fragment>
      <section id="login">
        <div className="container">
          <div className="login-page">
            {/* Form */}
            <form className="form" onSubmit={formSubmitHandler}>
              <h1 className="text-secondary pt-0">Registrieren</h1>

              {/* firstname */}
              <div className="form-group row">
                <label htmlFor="firstname" className="col-form-label" hidden>
                  Vorname
                </label>
                <input
                  className={
                    firstnameHasError ? 'form-control invalid' : 'form-control'
                  }
                  type="text"
                  name="firstname"
                  id="firstname"
                  placeholder="Max"
                  onChange={firstnameChangeHandler}
                  onBlur={firstnameBlurHandler}
                  value={firstname}
                />
                {firstnameHasError && (
                  <p className="error-text">
                    {'Vorname ' +
                      (firstname.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )}
              </div>

              {/* lastname */}
              <div className="form-group row">
                <label htmlFor="lastname" className="col-form-label" hidden>
                  Nachname
                </label>
                <input
                  className={
                    lastnameHasError ? 'form-control invalid' : 'form-control'
                  }
                  type="text"
                  name="lastname"
                  id="lastname"
                  placeholder="Mustermann"
                  onChange={lastnameChangeHandler}
                  onBlur={lastnameBlurHandler}
                  value={lastname}
                />
                {lastnameHasError && (
                  <p className="error-text">
                    {'Nachname ' +
                      (lastname.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )}
              </div>

              {/* email */}
              <div className="form-group row">
                <label htmlFor="email" className="col-form-label" hidden>
                  Email Address
                </label>
                <input
                  className={
                    emailHasError ? 'form-control invalid' : 'form-control'
                  }
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  onChange={emailChangeHandler}
                  onBlur={emailBlurHandler}
                  value={email}
                />
                {emailHasError && (
                  <p className="error-text">
                    {'Email ' +
                      (email.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )}
              </div>

              {/* password */}
              <div className="form-group row">
                <label htmlFor="password" className="col-form-label" hidden>
                  Password
                </label>
                <input
                  className={
                    passwordHasError ? 'form-control invalid' : 'form-control'
                  }
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Passwort"
                  onChange={(event) => {
                    passwordChangeHandler(event);
                    passwordRepeatBlurHandler(event);
                  }}
                  onBlur={passwordBlurHandler}
                  value={password}
                />
                {passwordHasError && (
                  <p className="error-text">
                    {'Passwort ' +
                      (password.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'ist nicht stark genug (mindestens 8 Zeichen, 1 Kleinbuchstabe, 1 Großbuchstabe, ' +
                          '1 Zahl, 1 Symbol)')}
                  </p>
                )}
              </div>

              {/* passwordRepeat */}
              <div className="form-group row">
                <label
                  htmlFor="passwordRepeat"
                  className="col-form-label"
                  hidden>
                  Password wiederholen
                </label>
                <input
                  className={
                    passwordRepeatHasError
                      ? 'form-control invalid'
                      : 'form-control'
                  }
                  type="password"
                  name="passwordRepeat"
                  id="passwordRepeat"
                  placeholder="Passwort wiederholen"
                  onChange={passwordRepeatChangeHandler}
                  onBlur={passwordRepeatBlurHandler}
                  value={passwordRepeat}
                />
                {passwordRepeatHasError && (
                  <p className="error-text">
                    {'Passwörter stimmen nicht überein'}
                  </p>
                )}
              </div>

              {/* submit */}
              <div className="form-group row">
                <input
                  type="submit"
                  name="submit"
                  className="input-submit"
                  value="Anmelden"
                />
              </div>

              {/* no account, register? */}
              <p className="text text-white">
                stattdessen anmelden?{' '}
                <Link to="/login" className="text-links">
                  Anmelden
                </Link>
              </p>
            </form>
          </div>
        </div>
      </section>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {!isLoading && !success && errors == null && formContent}
      {!isLoading && !success && errors != null && errorContent}
      {!isLoading && success && successContent}
      {isLoading && loadingSpinner}
    </React.Fragment>
  );
};

export default Register;
