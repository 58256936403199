import React from 'react';
import './NotFound.css';

const NotFound = () => {
  return (
    <React.Fragment>
      <center className="pb-5">
        <h1>404 - NotFound</h1>
        <img src="/img/errors/404.gif" alt=""></img>
      </center>
    </React.Fragment>
  );
};

export default NotFound;
