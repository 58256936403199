import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import validator from 'validator';
import useHttp from '../../hooks/use-http';
import useInput from '../../hooks/use-input';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import { apiURL } from '../../utils/Constants';
import './DownloadCustomerDownload.css';

// downloadZip
const downloadZip = ({ fileName, file }) => {
  const link = document.createElement('a');
  link.href = String(file);
  link.download = String(fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const DownloadCustomerDownload = () => {
  const params = useParams();
  const downloadUUID = params.uuid; // uuid: from src/App.jsx -> Routes
  const { isLoading, errors, setErrors, sendRequest } = useHttp();
  const [formIsValid, setFormIsValid] = useState(false);
  // eslint-disable-next-line
  const [response, setResponse] = useState(null);
  const [success, setSuccess] = useState(false); // only for development (React.strict)

  // user
  const {
    value: user,
    isValid: userIsValid,
    hasError: userHasError,
    valueChangeHandler: userChangeHandler,
    inputBlurHandler: userBlurHandler,
    reset: userReset,
  } = useInput({
    validate: (value) =>
      validator.isAlphanumeric(String(value).trim(), 'de-DE', { ignore: '-_' }),
  });

  // password
  const {
    value: password,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: passwordReset,
  } = useInput({
    validate: (value) => (value ? true : false), // do not validate user entered password
  });

  // if response is a json with JSend specification { status: "success" ... }
  const json = (json) => {
    setSuccess(true);
    setResponse(json.data);
    downloadZip({ fileName: json.data.fileName, file: json.data.file });
  };

  const sendForm = async (event) => {
    await sendRequest(
      {
        url: apiURL + '/customers_downloads/' + downloadUUID,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: {
          user,
          password,
          // password: encodeURIComponent(password),
        },
      },
      json
    );
  };

  // if form was submitted
  const formSubmitHandler = (event) => {
    event.preventDefault();
    userBlurHandler();
    passwordBlurHandler();

    // set form to valid if all fields are valid
    if (userIsValid && passwordIsValid) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  };

  const retryHandler = () => {
    setErrors(null);
    setFormIsValid(false);
  };

  const resetHandler = () => {
    setFormIsValid(false);
    setResponse(null);
    setErrors(null);
    setSuccess(false);

    userReset();
    passwordReset();
  };

  // if form is valid -> send form
  if (formIsValid) {
    setFormIsValid(false);
    sendForm();
  }

  const errorContent = (
    <React.Fragment>
      <center className="py-5">
        <h3 className="error-text">Falsches Passwort!</h3>
        <br />
        {errors && errors.hasOwnProperty('data') ? (
          <p className="error-text pb-5">{JSON.stringify(errors.data)}</p>
        ) : null}
        <button
          autoFocus={true}
          onClick={retryHandler}
          className="btn bg-primary border-primary">
          Nochmal versuchen
        </button>
      </center>
    </React.Fragment>
  );

  const successContent = (
    <React.Fragment>
      <center className="py-5">
        <h3 className="success-text">
          {'Downloade ' + (response ? response.fileName : null) + ' ...'}
        </h3>
        <br />
        <Link to="/">
          <button
            onClick={resetHandler}
            className="btn bg-primary border-primary"
            autoFocus={true}>
            OK
          </button>
        </Link>
      </center>
    </React.Fragment>
  );

  const loadingSpinner = (
    <React.Fragment>
      <center className="py-5">
        <b>Downloade ...</b>
        <br />
        <LoadingSpinner />
      </center>
    </React.Fragment>
  );

  const formContent = (
    <React.Fragment>
      <section id="customerDownload">
        <div className="container">
          <div className="customerDownload-page">
            {/* Form */}
            <form className="form" onSubmit={formSubmitHandler}>
              <h1 className="text-secondary pt-0">Kunden-Download</h1>

              {/* user */}
              <div className="form-group row">
                <label htmlFor="user" className="col-form-label" hidden>
                  Benutzer
                </label>
                <input
                  className={
                    userHasError ? 'form-control invalid' : 'form-control'
                  }
                  type="user"
                  name="user"
                  id="user"
                  placeholder="Benutzer"
                  onChange={userChangeHandler}
                  onBlur={userBlurHandler}
                  value={user}
                  autoFocus={true}
                />
                {userHasError && (
                  <p className="error-text">
                    {'Benutzer ' +
                      (user.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )}
              </div>

              {/* password */}
              <div className="form-group row">
                <label htmlFor="password" className="col-form-label" hidden>
                  Password
                </label>
                <input
                  className={
                    passwordHasError ? 'form-control invalid' : 'form-control'
                  }
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Passwort"
                  onChange={passwordChangeHandler}
                  onBlur={passwordBlurHandler}
                  value={password}
                />
                {passwordHasError && (
                  <p className="error-text">
                    {'Passwort ' +
                      (password.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )}
              </div>

              {/* submit */}
              <div className="form-group row">
                <input
                  type="submit"
                  name="submit"
                  className="input-submit"
                  value="Download (.zip)"
                />
              </div>
            </form>
          </div>
        </div>
      </section>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {!isLoading && !success && errors == null && formContent}
      {!isLoading && !success && errors != null && errorContent}
      {!isLoading && success && successContent}
      {isLoading && loadingSpinner}
    </React.Fragment>
  );
};

export default DownloadCustomerDownload;
