import React from 'react';
import './Banner.css';

const Banner = () => {
  return (
    <section id="banner">
      <div className="container-fluid m-0 p-0">
        <div className="bg-image">
          {/* hidden on sm, md; visible on lg, xl, xxl */}
          <div className="container d-none d-lg-block">
            <h1>Steinbeis-Transferzentrum</h1>
            {/* <p className="text-primary">Zerstoerungsfreie Pruefung und Messtechnik</p> */}
          </div>
          {/* visible on sm, md; hidden on lg, xl, xxl */}
          <div className="container d-block d-lg-none">
            <h2>
              Steinbeis-
              <br />
              Transferzentrum
            </h2>
            {/* <p className="text-primary">Zerstoerungsfreie Pruefung<br>und Messtechnik</p> */}
          </div>
          <div className="container ms-header__title">
            <div className="ms-slider">
              <ul className="ms-slider__words">
                <li className="ms-slider__word">
                  <p>Zerstörungsfreie Prüfung</p>
                </li>
                <li className="ms-slider__word">
                  <p>Präzise Messtechnik</p>
                </li>
                <li className="ms-slider__word">
                  <p>Generative Fertigungsverfahren</p>
                </li>
                <li className="ms-slider__word">
                  <p>Zerstörungsfreie Prüfung</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
