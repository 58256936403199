import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import validator from 'validator';
import useHttp from '../../hooks/use-http';
import useInput from '../../hooks/use-input';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import { apiURL } from '../../utils/Constants';

const Subscribe = ({ unsubscribe }) => {
  const { isLoading, errors, setErrors, sendRequest } = useHttp();
  const [formIsValid, setFormIsValid] = useState(false);
  // eslint-disable-next-line
  const [response, setResponse] = useState(null);
  const [success, setSuccess] = useState(false); // only for development (React.strict)

  // name
  const {
    value: name,
    isValid: nameIsValid,
    hasError: nameHasError,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    reset: nameReset,
  } = useInput({
    validate: (value) => validator.isAlpha(value, 'de-DE', { ignore: '-_ ' }),
  });

  // email
  const {
    value: email,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: emailReset,
  } = useInput({
    validate: (value) => validator.isEmail(value.trim()),
  });

  // if response is a json with JSend specification { status: "success" ... }
  const json = (json) => {
    setResponse(json.data);
    setSuccess(true);
  };

  const sendForm = async (event) => {
    await sendRequest(
      {
        url:
          apiURL + (unsubscribe ? '/subscription/delete' : '/subscription/add'),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: {
          name,
          email,
        },
      },
      json
    );
  };

  // if form was submitted
  const formSubmitHandler = (event) => {
    event.preventDefault();

    // set all fields as touched
    if (unsubscribe) nameBlurHandler();
    emailBlurHandler();

    // set form to valid if all fields are valid
    if (unsubscribe) {
      emailIsValid ? setFormIsValid(true) : setFormIsValid(false);
    } else {
      nameIsValid && emailIsValid
        ? setFormIsValid(true)
        : setFormIsValid(false);
    }
  };

  const retryHandler = () => {
    setErrors(null);
    setFormIsValid(false);
  };

  const resetHandler = () => {
    setFormIsValid(false);
    setResponse(null);
    setErrors(null);
    setSuccess(false);

    if (unsubscribe) nameReset();
    emailReset();
  };

  // if form is valid -> send form
  if (formIsValid) {
    setFormIsValid(false);
    sendForm();
  }

  const errorContent = (
    <React.Fragment>
      <center className="py-5">
        <h3 className="error-text">
          {(unsubscribe ? 'Abmeldung' : 'Anmeldung') +
            ' zum Newsletter fehlgeschlagen!'}
        </h3>
        <br />
        {errors && errors.hasOwnProperty('data') ? (
          <p className="error-text pb-5">{JSON.stringify(errors.data)}</p>
        ) : null}
        <button
          autoFocus={true}
          onClick={retryHandler}
          className="btn bg-primary border-primary">
          Nochmal versuchen
        </button>
      </center>
    </React.Fragment>
  );

  const successContent = (
    <React.Fragment>
      <center className="py-5">
        <h3 className="success-text">
          {unsubscribe
            ? 'Abmeldung vom Newsletter erfolgreich!'
            : 'Anmeldung zum Newsletter erfolgreich!'}
        </h3>
        <br />
        <Link to="/Aktuelles">
          <button
            onClick={resetHandler}
            className="btn bg-primary border-primary"
            autoFocus={true}>
            OK
          </button>
        </Link>
      </center>
    </React.Fragment>
  );

  const loadingSpinner = (
    <React.Fragment>
      <center className="py-5">
        <b>
          {unsubscribe
            ? 'Vom Newsletter abmelden ...'
            : 'Zum Newsletter anmelden ...'}
        </b>
        <br />
        <LoadingSpinner />
      </center>
    </React.Fragment>
  );

  const formContent = (
    <React.Fragment>
      <section id="login">
        <div className="container">
          <div className="login-page">
            {/* Form */}
            <form className="form" onSubmit={formSubmitHandler}>
              <h1 className="text-secondary pt-0">
                {unsubscribe ? 'Abmeldung Newsletter' : 'Anmeldung Newsletter'}
              </h1>

              {/* name */}
              {unsubscribe ? null : (
                <div className="form-group row">
                  <label htmlFor="name" className="col-form-label" hidden>
                    Name
                  </label>
                  <input
                    className={
                      nameHasError ? 'form-control invalid' : 'form-control'
                    }
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    onChange={nameChangeHandler}
                    onBlur={nameBlurHandler}
                    value={name}
                  />
                  {nameHasError && (
                    <p className="error-text">
                      {'Name ' +
                        (name.trim() === ''
                          ? 'darf nicht leer sein'
                          : 'enthält ungültige Zeichen')}
                    </p>
                  )}
                </div>
              )}

              {/* email */}
              <div className="form-group row">
                <label htmlFor="email" className="col-form-label" hidden>
                  Email Address
                </label>
                <input
                  className={
                    emailHasError ? 'form-control invalid' : 'form-control'
                  }
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  onChange={emailChangeHandler}
                  onBlur={emailBlurHandler}
                  value={email}
                />
                {emailHasError && (
                  <p className="error-text">
                    {'Email ' +
                      (email.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )}
              </div>

              {/* submit */}
              <div className="form-group row">
                <input
                  type="submit"
                  name="submit"
                  className="input-submit"
                  value={unsubscribe ? 'Abmelden' : 'Anmelden'}
                />
              </div>

              {/* unsubscribe */}
              <p className="text text-white">
                stattdessen{' '}
                <Link
                  to={unsubscribe ? '/Subscribe' : '/Unsubscribe'}
                  className="text-links">
                  {unsubscribe ? 'Anmelden' : 'Abmelden?'}
                </Link>
              </p>
            </form>
          </div>
        </div>
      </section>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {!isLoading && !success && errors == null && formContent}
      {!isLoading && !success && errors != null && errorContent}
      {!isLoading && success && successContent}
      {isLoading && loadingSpinner}
    </React.Fragment>
  );
};

export default Subscribe;
