import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authActions } from '../../store/auth';
import './Auth.css';

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.logout());
  }, [dispatch]);

  return (
    <React.Fragment>
      <center className="py-5">
        <h3 className="success-text">Du wurdest erfolgreich abgemeldet!</h3>
        <br />
        <Link to="/">
          <button className="btn bg-primary border-primary" autoFocus={true}>
            OK
          </button>
        </Link>
      </center>
    </React.Fragment>
  );
};

export default Logout;
