import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faGlasses,
  faRulerCombined,
  faFlaskVial,
  faLaptopFile,
  faMicroscope,
  faScrewdriverWrench,
  faFileZipper,
} from '@fortawesome/free-solid-svg-icons';
import TextBox from '../../../components/TextBox';
import Button from '../../../components/Button';
import Download from '../../../components/Download';
import '../Labs.css';

const Prueftechnik = () => {
  library.add(
    faGlasses,
    faRulerCombined,
    faFlaskVial,
    faLaptopFile,
    faMicroscope,
    faScrewdriverWrench,
    faFileZipper
  );
  const textBoxList = [
    /*
            Anleitung zum Hinzufügen neuer Inhalte:
                1. geschweifte Klammern mit Werten kopieren und
                   unter das Array anfügen (Komma vorher nicht vergessen)

                   {
                       title: " Titel",
                       bulletPoints: [
                           "Punkt 1",
                           "Punkt 2",
                           "Punkt 2",
                           "Punkt 2"
                       ],
                       fontAwesome: "fa-solid fa-symbol"
                   },

                2. Titel und Text der bulletPoints abändern
                3. Passendes Symbol auf https://fontawesome.com/icons aussuchen (Bsp: <i class="fa-solid fa-database"></i>)
                    1. Dieses Symbol (fa-database) aus dem Datenpaket '@fortawesome/free-solid-svg-icons' oben bei den Imports hinzufügen
                    dabei ist darauf zu achten dass die Bindestriche durch Großbuchstaben ersetzt werden (Bsp. fa-database -> faDatabase)
                    2. zur Library vor dem Datenarray hinzufügen
                    vorher: library.add(faGlasses, faScrewdriverWrench,...);
                    nachher: library.add(faGlasses, faScrewdriverWrench,..., faDatabase);
                    3. im Array aufrufen (fontAwesome: "fa-solid fa-database)
                4. ggf. einfügen
                <div>
                <Download
                    key= "0"
                    className= "my-3"
                    title="Dateiname.dateiendung"
                    link="/downloads/Dateiname.dateiendung"
                    fontAwesome="fa-solid fa-file-zipper"
                />
                </div>
        */
    {
      title: 'Visualisierung',
      bulletPoints: [
        'Darstellung innenliegender Strukturen',
        'Segmentierung verschiedener Materialien im Bauteil',
        'Räumliche Abbildung einzelner Volumenbereiche und Ausschnitte (ROI Scan)',
      ],
      fontAwesome: 'fa-solid fa-glasses',
    },
    {
      title: 'Messen',
      bulletPoints: [
        'Präzise Messungen von Geometriemerkmalen wie zum Beispiel Wandstärken und Spaltmaßen bei höchster Auflösung und maximaler Vergrößerung',
        'Vergleich von CAD-Konstruktionsdaten und Bauteil (Soll-Ist-Vergleich)',
        'Erstmusterprüfung',
      ],
      fontAwesome: 'fa-solid fa-ruler-combined',
    },
    {
      title: 'Analyse',
      bulletPoints: [
        'Analyse der inneren und äußeren Beschaffenheit von komplexen Bauteilen (z.B. Elektronikomponenten, Spritzgusteile, Faserverbundbauteile, ...)',
        'Volumetrische Fehleranalyse',
        'Lunker- und Porenanalyse',
        'Faseranalyse an Glas- und Kohlefaserbauteilen',
        'Qualitätsanalyse',
        'Untersuchung von Proben unter Temperatureinfluss (-20°C bis +160°C)',
        'Datenbasis für Generative Fertigungsverfahren',
        'Digitalisierung von Bauteilen und Erstellen von Datensätzen zur Weiterverarbeitung auf 3D-Druckern (Rapid Prototyping,Rapid Tooling, Rapid Manufacturing)',
      ],
      fontAwesome: 'fa-solid fa-flask-vial',
    },
    {
      title: 'Entwicklung/Fertigung',
      bulletPoints: [
        'Erzeugung eines kompletten CAD-Models des Bauteils (Reverse Engineering)',
      ],
      fontAwesome: 'fa-solid fa-laptop-file',
    },
    {
      title: 'Forschungs- und Kooperationsgebiete',
      bulletPoints: [
        'Unterstützung bei der Qualitätssicherung, wenn firmeneigene Mess- und Prüfverfahren ausgelastet oder nicht verfügbar sind',
        'Beratung und Dienstleistung in den oben genannten Kompetenzbereichen',
        'Gemeinsame Beantragung und Durchführung von nationalen und internationalen industrie- und anwendungsnahen Forschungsprojekten mit Dualen Partnern und Firmenpartnern sowie assoziierten Instituten und anderen Forschungseinrichtungen',
      ],
      fontAwesome: 'fa-solid fa-microscope',
    },
    {
      title: 'Ausstattung',
      bulletPoints: [
        'Industrielles Computertomographie-System Metrotom 800, Carl Zeiss Industrielle Messtechnik GmbH',
        'Deben Coolstage zur Analyse von kleinen Proben unter Temperatureinfluss (-20°C bis +160°C)',
        'Auswertesoftware',
        'VGStudioMAX, VolumeGraphics GmbH',
        'CALIGO, Carl Zeiss Industrielle Messtechnik GmbH',
        'CALYPSO, Carl Zeiss Industrielle Messtechnik GmbH',
        'Imageware, Siemens AG',
      ],
      fontAwesome: 'fa-solid fa-screwdriver-wrench',
    },
  ];

  return (
    <section id="laborContent">
      <div className="container-lg mt-5 justify-content-center align-items-center">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-5">
            {/* Bild  */}
            <img
              className="img-fluid rounded"
              src={require('../../../assets/img/Metrotom800.png')}
              alt="Metrotom800"
            />
            <p>Zeiss Metronom 800</p>
          </div>
          <div className="col-md-5">
            <h2>
              Labor für zerstörungsfreie Prüftechnik / industrielle
              Computertomographie
            </h2>
            <div className="row mt-4 mb-4">
              <div className="col">
                <h4>
                  Die zerstörungsfreie Prüfung von Objekten mittels
                  industrieller Computertomographie (iCT) ermöglicht es,
                  komplexe, verdeckte und auch innenliegende Strukturen von
                  Bauteilen zu visualisieren.{' '}
                </h4>
                {/* Button auf die virtuelle Begehung */}
                {
                  <Button
                    title="360° Blick ins Labor"
                    url="https://my.matterport.com/show/?m=SCEKg6tnmtc&sr=-2.84,.96&ss=42"
                  />
                }
              </div>
            </div>
          </div>
        </div>

        {
          // map => durchläuft alle TextBoxen in der textBoxList
          textBoxList.map(
            (
              { className, classNameCol, title, bulletPoints, fontAwesome },
              index
            ) => {
              return (
                <TextBox
                  // key={index} ist für react zwingend erforderlich!
                  key={index}
                  className="my-4"
                  title={title}
                  bulletPoints={bulletPoints}
                  fontAwesome={fontAwesome}
                />
              );
            }
          )
        }
      </div>
      <div>
        <Download
          key="0"
          className="my-3"
          title="Labor für Prüftechnik.zip"
          link="/downloads/Prueftechnik.zip"
          fontAwesome="fa-solid fa-file-zipper"
        />
      </div>
    </section>
  );
};

export default Prueftechnik;
