import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useHttp from '../../hooks/use-http';
import { apiURL } from '../../utils/Constants';
import SeminarList from './components/SeminarList';
// import './SeminarList.css';

const SeminarOverview = () => {
  const { isLoading, errors, sendRequest } = useHttp();
  const [seminarList, setSeminarListList] = useState([]);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const json = (json) => {
      setSeminarListList(json.data);
    };

    sendRequest(
      {
        url: apiURL + '/offers/fetchAll?type=seminar',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      },
      json
    );
  }, [sendRequest]);

  return (
    <React.Fragment>
      <section id="seminarOverview">
        <SeminarList isLoading={isLoading} errors={errors} data={seminarList} />
      </section>
    </React.Fragment>
  );
};

export default SeminarOverview;
