import { useState } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import './MainNav.css';

const MainNav = () => {
  library.add(faBars);

  const [dropDownLabsClicked, setDropDownLabsClicked] = useState(false);
  const dropDownLabsClickedHandler = (event) => {
    setDropDownLabsClicked(true);
    setDropDownServicesClicked(false);
  };

  const [dropDownServicesClicked, setDropDownServicesClicked] = useState(false);
  const dropDownServicesClickedHandler = (event) => {
    setDropDownServicesClicked(true);
    setDropDownLabsClicked(false);
  };

  const navLinkItemOnClickedHandler = (event) => {
    setDropDownLabsClicked(false);
    setDropDownServicesClicked(false);
  };

  return (
    <nav className="navbar navbar-expand-md bg-primary p-0" id="mainNav">
      <div className="container-lg">
        <button
          className="navbar-toggler text-uppercase font-weight-bold bg-primary text-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation">
          Menu <i className="fa-solid fa-bars"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink
                to="/"
                className="nav-link me-3"
                onClick={navLinkItemOnClickedHandler}>
                Home
              </NavLink>
            </li>

            {/* Labore Dropdown */}
            <li className="nav-item dropdown">
              {/* eslint-disable-next-line */}
              <a
                className={
                  dropDownLabsClicked
                    ? 'nav-link dropdown-toggle mx-3 show active'
                    : 'nav-link dropdown-toggle mx-3'
                }
                href="#"
                id="Labore"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                Labore
              </a>
              <ul className="dropdown-menu" aria-labelledby="Labore">
                <li>
                  <NavLink
                    to="/Labore/Fertigungsverfahren"
                    className="dropdown-item"
                    onClick={dropDownLabsClickedHandler}>
                    Labor für genereative Fertigungsverfahren
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/Labore/Koordinatenmesstechnik"
                    className="dropdown-item"
                    onClick={dropDownLabsClickedHandler}>
                    Labor für Koordinatenmesstechnik
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/Labore/Optischemesstechnik"
                    className="dropdown-item"
                    onClick={dropDownLabsClickedHandler}>
                    Labor für Optischemesstechnik
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/Labore/Werkstoffpruefung"
                    className="dropdown-item"
                    onClick={dropDownLabsClickedHandler}>
                    Labor für Werkstoffprüfung und - Charakterisierung
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/Labore/Prueftechnik"
                    className="dropdown-item"
                    onClick={dropDownLabsClickedHandler}>
                    Labor für zerstörungsfreie Prüftechnik
                  </NavLink>
                </li>
              </ul>
            </li>

            {/* Dienstleistungen Dropdown */}
            <li className="nav-item dropdown">
              {/* eslint-disable-next-line */}
              <a
                className={
                  dropDownServicesClicked
                    ? 'nav-link dropdown-toggle mx-3 show active'
                    : 'nav-link dropdown-toggle mx-3'
                }
                href="#"
                id="Dienstleistungen"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                Dienstleistungen
              </a>
              <ul className="dropdown-menu" aria-labelledby="Dienstleistungen">
                <li>
                  <NavLink
                    to="/Dienstleistungen"
                    className="dropdown-item"
                    onClick={dropDownServicesClickedHandler}>
                    Übersicht
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/Dienstleistung/buchen"
                    className="dropdown-item"
                    onClick={dropDownServicesClickedHandler}>
                    Dienstleistung buchen
                  </NavLink>
                </li>
              </ul>
            </li>

            {/* Aktuelles */}
            <li className="nav-item">
              <NavLink
                to="/Aktuelles"
                className="nav-link mx-3"
                onClick={navLinkItemOnClickedHandler}>
                Aktuelles & Archiv
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MainNav;
