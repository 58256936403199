import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHome,
  faEnvelope,
  faPhone,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faTwitter,
  faYoutube,
  faLinkedin,
  faXing,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

import './Footer.css';

const Footer = () => {
  // const siteUrl = 'https://zpm.steinbeis.de';
  library.add(
    faFacebookF,
    faTwitter,
    faYoutube,
    faLinkedin,
    faXing,
    faInstagram,
    faHome,
    faEnvelope,
    faPhone,
    faPrint
  );
  return (
    <footer className="bg-primary text-center text-lg-start text-muted">
      {/* Social media */}
      <section
        className="d-flex justify-content-center p-4 border-bottom text-secondary"
        id="footerSozialMedia">
        <div className="me-5 d-none d-sm-block">Seite teilen</div>{' '}
        {/* hidden on sm */}
        <div>
          <a href="https://www.facebook.com/" className="me-4 text-reset">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://www.twitter.com/" className="me-4 text-reset">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://www.youtube.com/" className="me-4 text-reset">
            <i className="fab fa-youtube"></i>
          </a>
          <a href="https://www.linkedin.com/" className="me-4 text-reset">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href="https://www.xing.com/" className="me-4 text-reset">
            <i className="fab fa-xing"></i>
          </a>
          <a href="https://www.instagram.com/" className="me-4 text-reset">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </section>

      {/* Main */}
      <section id="footerMain">
        <div className="container text-center text-md-start my-5">
          <div className="row mt-3">
            {/* col to fit */}
            <div className="col-1 mx-auto mb-4"></div>

            {/* Content */}
            <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-4">
              <h6 className="text-uppercase text-secondary fw-bold mb-4">
                <img
                  src={require('../../assets/favicon/lightblue.webp')}
                  alt=""
                  width="16"
                  height="16"
                />
                &nbsp; Steinbeis
              </h6>
              <p>
                {' '}
                Steinbeis-Transferzentrum
                <br />
                Zerstörungsfreie Prüfung
                <br />
                und Messtechnik
              </p>
            </div>

            {/* Links */}
            <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
              <h6 className="text-uppercase text-secondary fw-bold mb-4">
                Links
              </h6>
              <p>
                <a href="https://www.steinbeis.de/de/steinbeis/ueber-steinbeis.html">
                  Über Steinbeis
                </a>
              </p>
              <p>
                <a href="/UeberMich">Über mich</a>
              </p>
              <p>
                <a href="/Datenschutz">Datenschutz</a>
              </p>
              <p>
                <a href="/Impressum">Impressum</a>
              </p>
            </div>

            {/* Kontakt */}
            <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase text-secondary fw-bold mb-4">
                Kontakt
              </h6>
              <p>
                <i className="fas fa-home me-3 text-secondary"></i>Amselweg 13
                <br />
                <i className="fas fa-home me-3 text-primary"></i>89182 Bernstadt
                <br />
                <i className="fas fa-home me-3 text-primary"></i>Deutschland
              </p>
              {/* E-Mail with JS encrypted later */}
              <p>
                <i className="fas fa-envelope me-3 text-secondary"></i>
                <a href="&#109;&#097;&#105;&#108;&#116;&#111;&#058;&#110;&#105;&#099;&#111;&#046;&#098;&#108;&#101;&#115;&#115;&#105;&#110;&#103;&#064;&#115;&#116;&#119;&#046;&#100;&#101;">
                  &#110;&#105;&#099;&#111;&#046;&#098;&#108;&#101;&#115;&#115;&#105;&#110;&#103;&#064;&#115;&#116;&#119;&#046;&#100;&#101;
                </a>
              </p>
              <p>
                <i className="fas fa-phone me-3 text-secondary"></i>+49 171
                5200970
              </p>
              <p className="mb-0">
                <i className="fas fa-print me-3 text-secondary"></i>+49 17113
                5200970{' '}
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Copyright */}
      <section
        className="text-center p-4 border-top text-secondary"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
        id="footerCopyright">
        © 2021 Copyright:
        <a className="text-reset fw-bold" href="https://www.steinbeis.de/">
          Steinbeis.de
        </a>
      </section>
    </footer>
  );
};

export default Footer;
