import React from 'react';
// import "./Datenschutz.css";

const Datenschutz = () => {
  return (
    <div id="Datenschutz">
      <div className="d-flex justify-content-center">
        <div className="container m-5 p-5 shadow">
          <div>
            <p>
              <strong>Datenschutzerkl&auml;rung</strong>
            </p>
            <p>
              <strong>Allgemeiner Hinweis und Pflichtinformationen</strong>
            </p>
            <p>
              <strong>Benennung der verantwortlichen Stelle</strong>
            </p>
            <p>
              Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf
              dieser Website ist:
            </p>
            <p>
              Steinbeis Transferzentrum für Zerstörungsfreie Prüfung und
              Messtechnik
              <br />
              Nico Blessing
              <br />
              Amselweg 13
              <br />
              89182 Bernstadt
            </p>
            <p>
              <br />
            </p>
            <p>
              Die verantwortliche Stelle entscheidet allein oder gemeinsam mit
              anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von
              personenbezogenen Daten (z.B. Namen, Kontaktdaten o. &Auml;.).
            </p>
            <p>
              <strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong>
            </p>
            <p>
              Nur mit Ihrer ausdr&uuml;cklichen Einwilligung sind einige
              Vorg&auml;nge der Datenverarbeitung m&ouml;glich. Ein Widerruf
              Ihrer bereits erteilten Einwilligung ist jederzeit m&ouml;glich.
              F&uuml;r den Widerruf gen&uuml;gt eine formlose Mitteilung per
              E-Mail. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf
              erfolgten Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.
            </p>
            <p>
              <strong>
                Recht auf Beschwerde bei der zust&auml;ndigen
                Aufsichtsbeh&ouml;rde
              </strong>
            </p>
            <p>
              Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
              Versto&szlig;es ein Beschwerderecht bei der zust&auml;ndigen
              Aufsichtsbeh&ouml;rde zu. Zust&auml;ndige Aufsichtsbeh&ouml;rde
              bez&uuml;glich datenschutzrechtlicher Fragen ist der
              Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der
              Sitz unseres Unternehmens befindet. Der folgende Link stellt eine
              Liste der Datenschutzbeauftragten sowie deren Kontaktdaten
              bereit:&nbsp;
              <a
                href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                target="_blank"
                rel="noreferrer">
                https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
              </a>
              .
            </p>
            <p>
              <strong>Recht auf Daten&uuml;bertragbarkeit</strong>
            </p>
            <p>
              Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer
              Einwilligung oder in Erf&uuml;llung eines Vertrags automatisiert
              verarbeiten, an sich oder an Dritte aush&auml;ndigen zu lassen.
              Die Bereitstellung erfolgt in einem maschinenlesbaren Format.
              Sofern Sie die direkte &Uuml;bertragung der Daten an einen anderen
              Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
              machbar ist.
            </p>
            <p>
              <strong>
                Recht auf Auskunft, Berichtigung, Sperrung, L&ouml;schung
              </strong>
            </p>
            <p>
              Sie haben jederzeit im Rahmen der geltenden gesetzlichen
              Bestimmungen das Recht auf unentgeltliche Auskunft &uuml;ber Ihre
              gespeicherten personenbezogenen Daten, Herkunft der Daten, deren
              Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein
              Recht auf Berichtigung, Sperrung oder L&ouml;schung dieser Daten.
              Diesbez&uuml;glich und auch zu weiteren Fragen zum Thema
              personenbezogene Daten k&ouml;nnen Sie sich jederzeit &uuml;ber
              die im Impressum aufgef&uuml;hrten Kontaktm&ouml;glichkeiten an
              uns wenden.
            </p>
            <p>
              <strong>SSL- bzw. TLS-Verschl&uuml;sselung</strong>
            </p>
            <p>
              Aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung
              vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden,
              nutzt unsere Website eine SSL-bzw. TLS-Verschl&uuml;sselung. Damit
              sind Daten, die Sie &uuml;ber diese Website &uuml;bermitteln,
              f&uuml;r Dritte nicht mitlesbar. Sie erkennen eine
              verschl&uuml;sselte Verbindung an der &bdquo;https://&ldquo;
              Adresszeile Ihres Browsers und am Schloss-Symbol in der
              Browserzeile.
            </p>
          </div>
          <div>
            <p>
              <strong>Server-Log-Dateien</strong>
            </p>
            <p>
              In Server-Log-Dateien erhebt und speichert der Provider der
              Website automatisch Informationen, die Ihr Browser automatisch an
              uns &uuml;bermittelt. Dies sind:
            </p>
            <ul>
              <li>Besuchte Seite auf unserer Domain</li>
              <li>Datum und Uhrzeit der Serveranfrage</li>
              <li>Browsertyp und Browserversion</li>
              <li>Verwendetes Betriebssystem</li>
              <li>Referrer URL</li>
              <li>Hostname des zugreifenden Rechners</li>
              <li>IP-Adresse</li>
            </ul>
            <p>
              Es findet keine Zusammenf&uuml;hrung dieser Daten mit anderen
              Datenquellen statt. Grundlage der Datenverarbeitung bildet Art. 6
              Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur
              Erf&uuml;llung eines Vertrags oder vorvertraglicher
              Ma&szlig;nahmen gestattet.
            </p>
          </div>
          <div>
            <p>
              <strong>Kontaktformular</strong>
            </p>
            <p>
              Per Kontaktformular &uuml;bermittelte Daten werden
              einschlie&szlig;lich Ihrer Kontaktdaten gespeichert, um Ihre
              Anfrage bearbeiten zu k&ouml;nnen oder um f&uuml;r Anschlussfragen
              bereitzustehen. Eine Weitergabe dieser Daten findet ohne Ihre
              Einwilligung nicht statt.
            </p>
            <p>
              Die Verarbeitung der in das Kontaktformular eingegebenen Daten
              erfolgt ausschlie&szlig;lich auf Grundlage Ihrer Einwilligung
              (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten
              Einwilligung ist jederzeit m&ouml;glich. F&uuml;r den Widerruf
              gen&uuml;gt eine formlose Mitteilung per E-Mail. Die
              Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
              Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf
              unber&uuml;hrt.
            </p>
            <p>
              &Uuml;ber das Kontaktformular &uuml;bermittelte Daten verbleiben
              bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre
              Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit
              der Datenspeicherung mehr besteht. Zwingende gesetzliche
              Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben
              unber&uuml;hrt.
            </p>
          </div>
          <div>
            <p>
              <small>
                Quelle: Datenschutz-Konfigurator von&nbsp;
                <a
                  href="http://www.mein-datenschutzbeauftragter.de/"
                  target="_blank"
                  rel="noreferrer">
                  mein-datenschutzbeauftragter.de
                </a>
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Datenschutz;
