import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUsersGear,
  faMicroscope,
  faGraduationCap,
  faScrewdriverWrench,
} from '@fortawesome/free-solid-svg-icons';
import TextBox from '../../../components/TextBox';
import Button from '../../../components/Button';

import '../Labs.css';

const Optischemesstechnik = () => {
  library.add(faUsersGear, faMicroscope, faGraduationCap, faScrewdriverWrench);
  const textBoxList = [
    /*
            Anleitung zum Hinzufügen neuer Inhalte:
                1. geschweifte Klammern mit Werten kopieren und
                   unter das Array anfügen (Komma vorher nicht vergessen)

                   {
                       title: " Titel",
                       bulletPoints: [
                           "Punkt 1",
                           "Punkt 2",
                           "Punkt 2",
                           "Punkt 2"
                       ],
                       fontAwesome: "fa-solid fa-symbol"
                   },

                2. Titel und Text der bulletPoints abändern
                3. Passendes Symbol auf https://fontawesome.com/icons aussuchen (Bsp: <i class="fa-solid fa-database"></i>)
                    1. Dieses Symbol (fa-database) aus dem Datenpaket '@fortawesome/free-solid-svg-icons' oben bei den Imports hinzufügen
                    dabei ist darauf zu achten dass die Bindestriche durch Großbuchstaben ersetzt werden (Bsp. fa-database -> faDatabase)
                    2. zur Library vor dem Datenarray hinzufügen
                    vorher: library.add(faGlasses, faScrewdriverWrench,...);
                    nachher: library.add(faGlasses, faScrewdriverWrench,..., faDatabase);
                    3. im Array aufrufen (fontAwesome: "fa-solid fa-database)
                4. ggf. einfügen
                <div>
                <Download
                    key= "0"
                    className= "my-3"
                    title="Dateiname.dateiendung"
                    link="/downloads/Dateiname.dateiendung"
                    fontAwesome="fa-solid fa-file-zipper"
                />
                </div>
        */
    {
      title: 'Kompetenzbereiche',
      bulletPoints: [
        '3D-Oberflächenanalyse',
        '2D-Analyse von Querschnitten oder Punkten',
        'Vergleich von CAD-Konstruktionsdaten und Bauteil (Soll-Ist-Vergleich)',
        'Erzeugung des kompletten CAD-Models des Bauteils (Reverse Engineering)',
        'Qualitätsanalyse',
      ],
      fontAwesome: 'fa-solid fa-users-gear',
    },
    {
      title: 'Forschungs- und Kooperationsgebiete',
      bulletPoints: [
        'Unterstützung bei der Qualitätssicherung, wenn firmeneigene Mess- und Prüfverfahren ausgelastet oder nicht verfügbar sind.',
        'Beratung und Dienstleistung in den oben genannten Kompetenzbereichen',
        'Gemeinsame Beantragung und Durchführung von nationalen und internationalen industrie- und anwendungsnahen Forschungsprojekten mit Dualen Partnern und Firmenpartnern sowie assoziierten Instituten und anderen Forschungseinrichtungen',
      ],
      fontAwesome: 'fa-solid fa-microscope',
    },
    {
      title: 'Lehrgebiete',
      bulletPoints: [
        'Durchführung von Laborveranstaltungen im Rahmen verschiedener Vorlesungen.',
        'Vergabe von Studien- und Projektarbeiten.',
      ],
      fontAwesome: 'fa-solid fa-graduation-cap',
    },
    {
      title: 'Ausstattung',
      bulletPoints: [
        'ATOS III-Scanner, GOM mbH',
        '3D-Digitalisierungssoftware und Inspektionssoftware, GOM mbH',
        'Verschiedene Messfelder zur Digitalisierung kleiner und großer Objekte',
      ],
      fontAwesome: 'fa-solid fa-screwdriver-wrench',
    },
  ];

  return (
    <section id="laborContent">
      <div class="container-lg mt-5 justify-content-center align-items-center">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-5">
            {/* Bild  */}
            <img
              class="img-fluid rounded"
              src={require('../../../assets/img/GOMScanCobot.png')}
              alt="GOMScanCobot"
            />
            <p>GOM ScanCobot</p>
          </div>
          <div class="col-md-5">
            <h2>Labor für optische Messtechnik</h2>
            <div class="row mt-4 mb-4">
              <div class="col">
                <h4>
                  Mit Hilfe von optischen Scannern ist es heute möglich, die
                  Oberfläche von Objekten von beinahe beliebiger Größe zu
                  vermessen und zu prüfen. Im Labor für optische Messtechnik der
                  DHBW Heidenheim werden mit dem Streifenprojektionsscanner ATOS
                  III der Firma GOM mbH Bauteile digitalisiert, d.h. Oberflächen
                  dreidimensional erfasst, welche anschließend mittels
                  geeigneter Software-Werkzeuge analysiert und ausgewertet
                  werden, um Fehler zu diagnostizieren und die Qualität der
                  Objekte zu beurteilen.
                </h4>
                {/* Button auf die virtuelle Begehung */}
                {
                  <Button
                    title="360° Blick ins Labor"
                    url="https://my.matterport.com/show/?m=SCEKg6tnmtc&sr=-3.04,-1.12&ss=40"
                  />
                }
              </div>
            </div>
          </div>
        </div>

        {
          // map => durchläuft alle TextBoxen in der textBoxList
          textBoxList.map(
            (
              { className, classNameCol, title, bulletPoints, fontAwesome },
              index
            ) => {
              return (
                <TextBox
                  // key={index} ist für react zwingend erforderlich!
                  key={index}
                  className="my-4"
                  title={title}
                  bulletPoints={bulletPoints}
                  fontAwesome={fontAwesome}
                />
              );
            }
          )
        }
      </div>
    </section>
  );
};

export default Optischemesstechnik;
