import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Button.css';

/*
    Beispiel:
        <Button
            title="Zum Labor"       // erforderlich: Button Title
            url="/Labore/Optischemesstechnik"
                                    // erforderlich: setzt den Button Link
                                    //  bei externen Links z.B.: "https://www.google.de"
                                    //  bei internen Links z.B.: "/Labore/Optischemesstechnik" (React Routing muss gesetzt sein! siehe App.js)
            className="my-3"        // optional: Klassen, die dem Component mitgegeben werden können
            isDownload=true         // true:   Download
                                    // false:  Link
        />
 */

const Button = (button) => {
  const classes = 'btn-steinbeis ' + (button.className ? button.className : '');
  const navigate = useNavigate();
  const clickHandler = () => {
    // Ist das erste Zeichen der URL ein '/'
    button.url[0] === '/'
      ? // dann Navigiere intern (component),
        navigate(button.url)
      : // sonst Navigiere extern (link)
        window.location.replace(button.url);
  };

  return (
    <React.Fragment>
      {
        // handelt es sich um einen Download?
        button.isDownload ? (
          // wenn ja:
          <a href={button.url} target="_blank" rel="noreferrer" download>
            <button type="button" className={classes}>
              {button.title}
            </button>
          </a>
        ) : (
          // wenn nein: (Link)
          <button type="button" className={classes} onClick={clickHandler}>
            {button.title}
          </button>
        )
      }
    </React.Fragment>
  );
};

export default Button;
