import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMicroscope, faUsersGear } from '@fortawesome/free-solid-svg-icons';
import './About.css';
import TextBox from '../../components/TextBox';
import ImgTextBox from '../../components/ImgTextBox';

const About = () => {
  library.add(faMicroscope, faUsersGear);
  return (
    <section id="about">
      <div className="container my-5 mx-auto">
        <ImgTextBox
          // key={index} ist für react zwingend erforderlich!
          key="0"
          className="my-5"
          imgUrl={require('../../assets/banner/laborausschnit_300_576.webp')}
          imgAlt="laborausschnitt_300_576"
          title="Komplexe Aufgabenstellungen "
          content="im Bereich der Mess- und Prüftechnik können heute mit Hilfe moderner Methoden wie z.B der industriellen Röntgen-Computertomographie (iCT) gelöst werden. Das Steinbeis Transferzentrum für Zerstörungsfreie Prüfung und Messtechnik (ZPM) bietet hierfür individuelle Ansätze und Dienstleistungen an."
        />

        <TextBox
          // key={index} ist für react zwingend erforderlich!
          key="2"
          className="my-5"
          shadowRow="true"
          title="Unsere Schwerpunktthemen"
          bulletPoints={[
            '3D-Mess- und -Prüftechnik',
            'Erfassung von komplexen Innen- und Außengeometrien mit Hilfe der Industriellen 3D-Röntgencomputertomographie (iCT)',
            'Werkstoff- und Oberflächenuntersuchungen mit Hilfe der Rasterelektronenmikroskopie',
            'Rapid Product Development (Rapid Prototyping, Rapid tooling, Rapid Manufacturing)',
            'Risikoanalysen in der Produkt- und Prozessentwicklung',
            'Produktsicherheit, CE-Konformität',
            'Kooperative, anwendungs- und marktorientierte Forschung in den o.g. Bereichen',
          ]}
          fontAwesome="fa-solid fa-users-gear"
        />

        <TextBox
          // key={index} ist für react zwingend erforderlich!
          key="1"
          className="my-5"
          shadowRow="true" // nicht beide benutzen!
          title="Konkret unterstützt das Steinbeis ZPM Ihr Unternehmen"
          bulletPoints={[
            'bei der Analyse und Durchführung komplexer mess- und prüftechnischer Aufgabenstellungen. Hierbei kommen zum Beispiel die Industrielle 3D-Röntgencomputertomographie, Streifenlichtprojektion und Photogrammetrie sowie Digitalmikroskopie und Rasterelektronenmikroskopie zum Einsatz.',
            'bei der Herstellung von Prototypen (Rapid Prototyping), Prototyp-Werkzeugen (Rapid Tooling) und Kleinserien (Rapid Manufacturing). Verfügbare Technologien und Anlagen: SLS (EOS P395 Laser Sinter System), FDM (STRATASYS Eden) und PolyJet (STRATASYSF120).',
            'bei der Durchführung von Risikoanalysen im Bereich Produkt- und Prozessentwicklung bzw. - optimierung (Konzept-, Design- und Prozess-FMEA nach VDA / AIAG) sowie Produktsicherheit (CE-Konformität nach den einschlägigen EU-Richtlinien (z.B. Richtlinie 2006/42/EG (Maschinenrichtlinie).',
          ]}
          fontAwesome="fa-solid fa-microscope"
        />
      </div>
    </section>
  );
};

export default About;
