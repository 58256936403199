import './TextBox.css';

/*
    Beispiel:
        <TextBox
            key=0                       // erforderlich: für react, muss eindeutig sein!
            className="my-3"            // optional: Klassen, die der TextBox mitgegeben werden können
            classNameCol="col-md-8"     // optional: Klassen, die dem Feld (col)
                                        //  innerhalb der TextBox mitgegeben werden können
            title="Titel"               // optional aber sinnvoll: Titel der ImgTextBox
            bulletPoints=[
                "Punkt 1",
                "Punkt 2",
                "Punkt 3"
            ]                           // optional aber sinnvoll: Auszählungspunkte der TextBox
            fontAwesome="fa-solid fa-microscope"
                                        // optional: FontAwesome Icon
        />
 */

const TextBox = (props) => {
  const classesRow = 'row shadow ' + (props.className ? props.className : '');
  const classesCol =
    'col p-3 m-3 ' + (props.classNameCol ? props.classNameCol : 'col-md-8');
  return (
    <div className={classesRow}>
      {props.fontAwesome ? (
        <div className="col-md-2 mx-4 text-center fontAwesomeLogo align-self-center">
          <i className={props.fontAwesome}></i>
        </div>
      ) : null}

      <div className={classesCol}>
        <h4>{props.title}</h4>
        <ul>
          {props.bulletPoints.map((bulletPoint, index) => {
            // key={index} ist "bad practice" bei dynamischen Listen
            // hier akzeptabel, da es sich um eine statische Liste handelt
            return <li key={index}>{bulletPoint}</li>;
          })}
        </ul>
      </div>
    </div>
  );
};

export default TextBox;
