import { useState } from 'react';
import './PartnerCard.css';

/*
    Beispiel:
        <PartnerCard
            className="my-3"            // optional: Klassen, die der PartnerCard mitgegeben werden können
            title="Titel"               // optional aber sinnvoll: Titel der ImgTextBox
            text="text"                 // optional: Text der PartnerCard
            img=require("../../assets/img/partner/fraunhofer-gesellschaft_50.webp")
                                        // optional: Bild der PartnerCard
            url="https://www.fraunhofer.de/"
                                        // optonal: Link der PartnerCard
        />
 */

const PartnerCard = (card) => {
  const classes = 'card ' + (card.className ? card.className : '');
  const mouseEnter = () => {
    setCardClasses('card hovered ' + (card.className ? card.className : ''));
  };
  const mouseLeave = () => {
    setCardClasses('card ' + (card.className ? card.className : ''));
  };
  const [cardClasses, setCardClasses] = useState(classes);
  return (
    <div className="col">
      <button onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
        <a
          href={card.url}
          className="a-partner"
          target="_blank"
          rel="noreferrer">
          <div className={cardClasses || ''}>
            {card.img ? (
              <img className="card-img-top" src={card.img} alt={card.title} />
            ) : (
              <img
                className="card-img-top"
                src={require('../assets/img/partner/Partner.webp')}
                alt={card.title}
              />
            )}
            <div className="card-body">
              {/* <h5 className="card-title">{card.title}</h5> */}
              <p className="card-text text-primary">{card.text}</p>
              {/* <a href={card.url} className="btn btn-primary">besuchen</a> */}
            </div>
          </div>
        </a>
      </button>
    </div>
  );
};

export default PartnerCard;
