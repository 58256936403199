import React from 'react';
import { Link } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrash, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import './CustomerDownloadCard.css';

const CustomerDownloadCard = (props) => {
  const customerDownload = props.customerDownload;

  library.add(faTrash, faPenToSquare);

  return (
    <div className="row border">
      {/* uuid */}
      <div className="col-3 uuid">{customerDownload.uuid}</div>
      {/* customer */}
      <div className="col">{customerDownload.customer}</div>
      {/* customerEmail */}
      <div className="col">{customerDownload.customerEmail}</div>
      {/* password */}
      <div className="col">{customerDownload.password}</div>
      {/* fileName */}
      <div className="col">{customerDownload.fileName}</div>
      {/* buttons */}
      <div className="col-1 buttons">
        <div className="row justify-content-between">
          <div className="col-6">
            <Link
              to={'/Download/löschen/' + customerDownload.uuid}
              className="">
              <i className="fa-solid fa-trash ms-2"></i>
            </Link>
          </div>
          <div className="col-6">
            <Link
              to={'/Download/bearbeiten/' + customerDownload.uuid}
              className="">
              <i className="fa-solid fa-pen-to-square"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDownloadCard;
