import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useHttp from '../../hooks/use-http';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import { apiURL } from '../../utils/Constants';
import AddOrder from './AddOrder';
// import './EditOrder.css';

const EditOrder = () => {
  const params = useParams();
  const orderID = params.id; // id: from src/App.jsx -> Routes
  const { isLoading, errors, /* setErrors,*/ sendRequest } = useHttp();
  const [order, setOrder] = useState([]);
  const [success, setSuccess] = useState(false); // only for development (React.strict)
  const token = useSelector((state) => state.auth.token);

  // get Order
  useEffect(() => {
    const json = (json) => {
      setOrder(json.data);
      setSuccess(true);
    };

    sendRequest(
      {
        url: apiURL + '/order/get/' + orderID,
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      },
      json
    );
  }, [sendRequest, orderID]);

  const errorContent = (
    <React.Fragment>
      <center className="py-5">
        <h3 className="error-text">Der Anfrage konnte nicht geladen werden!</h3>
        <br />
        {errors && errors.hasOwnProperty('data') ? (
          <p className="error-text pb-5">{JSON.stringify(errors.data)}</p>
        ) : null}
        <Link to={'/Order/Übersicht'}>
          <button autoFocus={true} className="btn bg-primary border-primary">
            Zurück zur Anfragen Übersicht
          </button>
        </Link>
      </center>
    </React.Fragment>
  );

  const successContent = <AddOrder orderToEdit={order}></AddOrder>;

  const loadingSpinner = (
    <React.Fragment>
      <center className="py-5">
        <b>Lade Anfrage ...</b>
        <br />
        <LoadingSpinner />
      </center>
    </React.Fragment>
  );

  const somethingWentWrongContent = (
    <React.Fragment>
      <center className="py-5">
        <h3 className="error-text">Etwas ist schief gelaufen :(</h3>
        <br />
        <Link to={'/Order/Übersicht'}>
          <button autoFocus={true} className="btn bg-primary border-primary">
            Zurück zur Anfragen Übersicht
          </button>
        </Link>
      </center>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {!isLoading && !success && errors == null && somethingWentWrongContent}
      {!isLoading && !success && errors != null && errorContent}
      {!isLoading && success && successContent}
      {isLoading && loadingSpinner}
    </React.Fragment>
  );
};

export default EditOrder;
