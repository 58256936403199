import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useHttp from '../../hooks/use-http';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import { apiURL } from '../../utils/Constants';
//import Invoice from '../../../utils/invoice';
// import './OrderInvoice.css';

// downloadZip
const downloadZip = ({ fileName, file }) => {
  const link = document.createElement('a');
  link.href = String(file);
  link.download = String(fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const OrderInvoice = () => {
  const params = useParams();
  const orderID = params.id; // id: from src/App.jsx -> Routes
  const { isLoading, errors, /* setErrors,*/ sendRequest } = useHttp();
  // eslint-disable-next-line
  const [order, setOrder] = useState([]);
  const [success, setSuccess] = useState(false); // only for development (React.strict)
  const token = useSelector((state) => state.auth.token);

  // get Order
  useEffect(() => {
    const json = (json) => {
      setOrder(json.data);
      setSuccess(true);
      downloadZip({ fileName: json.data.fileName, file: json.data.file });
    };

    sendRequest(
      {
        url: apiURL + '/order/getInvoice/' + orderID,
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      },
      json
    );
  }, [sendRequest, orderID]);

  const errorContent = (
    <React.Fragment>
      <center className="py-5">
        <h3 className="error-text">Rechnung konnte nicht erstellt werden!</h3>
        <br />
        {errors && errors.hasOwnProperty('data') ? (
          <p className="error-text pb-5">{JSON.stringify(errors.data)}</p>
        ) : null}
        <Link to={'/Order/bearbeiten/' + orderID}>
          <button
            autoFocus={true}
            className="btn bg-primary border-primary mb-5">
            Zurück zur Anfrage
          </button>
        </Link>
      </center>
    </React.Fragment>
  );

  const successContent = (
    <React.Fragment>
      <center className="py-5">
        <h3 className="success-text">Rechnung erfolgreich erstellt!</h3>

        <br />
        <Link to="/Order/Übersicht">
          <button className="btn bg-primary border-primary" autoFocus={true}>
            OK
          </button>
        </Link>
      </center>
    </React.Fragment>
  );

  const loadingSpinner = (
    <React.Fragment>
      <center className="py-5">
        <b>erstelle Rechnung ...</b>
        <br />
        <LoadingSpinner />
      </center>
    </React.Fragment>
  );

  const somethingWentWrongContent = (
    <React.Fragment>
      <center className="py-5">
        <h3 className="error-text">Etwas ist schief gelaufen :(</h3>
        <br />
        <Link to={'/Order/Übersicht'}>
          <button autoFocus={true} className="btn bg-primary border-primary">
            Zurück zur Anfrage
          </button>
        </Link>
      </center>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {!isLoading && !success && errors == null && somethingWentWrongContent}
      {!isLoading && !success && errors != null && errorContent}
      {!isLoading && success && successContent}
      {isLoading && loadingSpinner}
    </React.Fragment>
  );
};

export default OrderInvoice;
