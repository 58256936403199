import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import validator from 'validator';
import useHttp from '../../hooks/use-http';
import useInput from '../../hooks/use-input';
import LoadingSpinnger from '../../components/UI/LoadingSpinner';
import { apiURL } from '../../utils/Constants';
import GoogleCalendar from '../../components/GoogleCalendar';
import DateTimePicker from '../../components/DateTimePicker';
import { convertStateToName } from '../../utils/attributeConverter';
import './AddOrder.css';

// eslint-disable-next-line
Date.prototype.yyyymmdd = function () {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [
    this.getFullYear(),
    '-',
    (mm > 9 ? '' : '0') + mm,
    '-',
    (dd > 9 ? '' : '0') + dd,
  ].join('');
};

const AddOrder = ({ orderToEdit }) => {
  const edit = orderToEdit != null ? true : false;

  const { isLoading, errors, setErrors, sendRequest } = useHttp();
  const [formIsValid, setFormIsValid] = useState(false);
  // eslint-disable-next-line
  const [response, setResponse] = useState(null);
  const [services, setServices] = useState(null);
  const [success, setSuccess] = useState(false); // only for development (React.strict)
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);

  const {
    value: firstname,
    isValid: firstnameIsValid,
    hasError: firstnameHasError,
    valueChangeHandler: firstnameChangeHandler,
    inputBlurHandler: firstnameBlurHandler,
  } = useInput({
    defaultValue: edit ? orderToEdit.firstname : '',
    validate: (value) =>
      validator.isAlphanumeric(String(value).trim(), 'de-DE', {
        ignore: '- ',
      }),
  });

  //Lastname
  const {
    value: lastname,
    isValid: lastnameIsValid,
    hasError: lastnameHasError,
    valueChangeHandler: lastnameChangeHandler,
    inputBlurHandler: lastnameBlurHandler,
  } = useInput({
    defaultValue: edit ? orderToEdit.lastname : '',
    validate: (value) =>
      validator.isAlphanumeric(String(value).trim(), 'de-DE', {
        ignore: '- ',
      }),
  });

  //Company
  const {
    value: company,
    isValid: companyIsValid,
    hasError: companyHasError,
    valueChangeHandler: companyChangeHandler,
    inputBlurHandler: companyBlurHandler,
  } = useInput({
    defaultValue: edit ? orderToEdit.company : '',
    validate: (value) =>
      validator.isAlphanumeric(String(value).trim(), 'de-DE', {
        ignore: '- ',
      }),
  });

  //E-Mail
  const {
    value: email,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput({
    defaultValue: edit ? orderToEdit.email : '',
    validate: (value) => validator.isEmail(String(value).trim()),
  });

  //Phone
  const {
    value: phone,
    isValid: phoneIsValid,
    hasError: phoneHasError,
    valueChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
  } = useInput({
    defaultValue: edit ? orderToEdit.phone : '',
    validate: (value) =>
      String(value)
        .trim()
        .match(/^\+?[-0-9() /]+$/g) !== null,
  });

  //Postal
  const {
    value: postal,
    isValid: postalIsValid,
    hasError: postalHasError,
    valueChangeHandler: postalChangeHandler,
    inputBlurHandler: postalBlurHandler,
  } = useInput({
    defaultValue: edit ? orderToEdit.postal : '',
    validate: (value) => validator.isPostalCode(String(value).trim(), 'DE'),
  });

  //City
  const {
    value: city,
    isValid: cityIsValid,
    hasError: cityHasError,
    valueChangeHandler: cityChangeHandler,
    inputBlurHandler: cityBlurHandler,
  } = useInput({
    defaultValue: edit ? orderToEdit.city : '',
    validate: (value) =>
      validator.isAlphanumeric(String(value).trim(), 'de-DE', {
        ignore: '-.()/ ',
      }),
  });

  //Street
  const {
    value: street,
    isValid: streetIsValid,
    hasError: streetHasError,
    valueChangeHandler: streetChangeHandler,
    inputBlurHandler: streetBlurHandler,
  } = useInput({
    defaultValue: edit ? orderToEdit.street : '',
    validate: (value) =>
      validator.isAlphanumeric(String(value).trim(), 'de-DE', {
        ignore: '.- ',
      }),
  });

  //StreetNumber
  const {
    value: streetNumber,
    isValid: streetNumberIsValid,
    hasError: streetNumberHasError,
    valueChangeHandler: streetNumberChangeHandler,
    inputBlurHandler: streetNumberBlurHandler,
  } = useInput({
    defaultValue: edit ? orderToEdit.streetNumber : '',
    validate: (value) =>
      validator.isAlphanumeric(String(value).trim(), 'de-DE', {
        ignore: '-./ ',
      }),
  });

  //Country
  const {
    value: country,
    isValid: countryIsValid,
    hasError: countryHasError,
    valueChangeHandler: countryChangeHandler,
    inputBlurHandler: countryBlurHandler,
  } = useInput({
    defaultValue: edit ? orderToEdit.country : '',
    validate: (value) =>
      validator.isAlphanumeric(String(value).trim(), 'de-DE', {
        ignore: '!?-.:,; ',
      }),
  });

  const defaultDate = new Date();
  const defaultStartDate = new Date(defaultDate.setHours(8, 0, 0, 0));

  const {
    value: startDateTime,
    setValue: startDateTimeChangeHandler,
    isValid: startDateTimeIsValid,
    hasError: startDateTimeHasError,
    inputBlurHandler: startDateTimeBlurHandler,
    /* reset: startDateTimeReset, */
  } = useInput({
    defaultValue: edit ? new Date(orderToEdit.startDateTime) : defaultStartDate, // 8 o'clock
    validate: (value) => (value ? typeof value.getMonth === 'function' : false),
  });

  // endDateTime
  // startDateTime
  const defaultEndDate = new Date(defaultDate.setHours(9, 0, 0, 0));

  const {
    value: endDateTime,
    setValue: endDateTimeChangeHandler,
    isValid: endDateTimeIsValid,
    hasError: endDateTimeHasError,
    inputBlurHandler: endDateTimeBlurHandler,
    /* reset: endDateTimeReset, */
  } = useInput({
    defaultValue: edit ? new Date(orderToEdit.endDateTime) : defaultEndDate, // 9 o'clock,
    validate: (value) => (value ? typeof value.getMonth === 'function' : false),
  });

  //Price
  const {
    value: price,
    isValid: priceIsValid,
    hasError: priceHasError,
    valueChangeHandler: priceChangeHandler,
    inputBlurHandler: priceBlurHandler,
  } = useInput({
    defaultValue: edit ? orderToEdit.price : 0.0,
    validate: (value) => validator.isDecimal(String(value).trim()),
  });

  //state
  const {
    value: state,
    isValid: stateIsValid,
    //hasError: stateHasError,
    valueChangeHandler: stateChangeHandler,
    inputBlurHandler: stateBlurHandler,
  } = useInput({
    defaultValue: edit ? orderToEdit.state : 1,
    validate: (value) => validator.isDecimal(String(value).trim()),
  });

  //content
  const {
    value: content,
    isValid: contentIsValid,
    hasError: contentHasError,
    valueChangeHandler: contentChangeHandler,
    inputBlurHandler: contentBlurHandler,
  } = useInput({
    defaultValue: edit ? orderToEdit.content : '',
    validate: (value) =>
      validator.isAlphanumeric(String(value).trim(), 'de-DE', {
        ignore: '-./:, ',
      }),
  });

  //comment
  const {
    value: comment,
    isValid: commentIsValid,
    hasError: commentHasError,
    valueChangeHandler: commentChangeHandler,
    inputBlurHandler: commentBlurHandler,
  } = useInput({
    defaultValue: edit ? orderToEdit.comment : '',
    validate: (value) => (value ? true : false),
  });

  //serviceID
  const {
    value: serviceID,
    isValid: serviceIDIsValid,
    /* hasError: serviceIDHasError, */
    valueChangeHandler: serviceIDChangeHandler,
    inputBlurHandler: serviceIDBlurHandler,
  } = useInput({
    defaultValue: edit ? orderToEdit.serviceID : '',
    validate: (value) => validator.isInt(String(value).trim()),
  });

  const billDate = '';
  const date = new Date();
  const orderDate = date.yyyymmdd();

  const json = (json) => {
    // transform data here (extract data from JSend specification)
    setResponse(json.data);
    setSuccess(true);
  };
  const sendForm = async (event) => {
    await sendRequest(
      {
        url: edit
          ? apiURL + '/order/edit/' + orderToEdit.orderID
          : apiURL + '/order/new',
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: {
          customer: {
            firstname,
            lastname,
            company,
            email,
            phone,
            postal,
            city,
            street,
            streetNumber,
            country,
          },
          offer: {
            startDateTime,
            endDateTime,
            content,
            price,
            state,
            serviceID,
          },
          order: {
            comment,
            billDate,
            orderDate,
          },
        },
      },
      json
    );
  };
  // if form was submitted
  const formSubmitHandler = async (event) => {
    event.preventDefault();

    // set all fields as touched
    firstnameBlurHandler();
    lastnameBlurHandler();
    companyBlurHandler();
    emailBlurHandler();
    phoneBlurHandler();
    postalBlurHandler();
    cityBlurHandler();
    streetBlurHandler();
    streetNumberBlurHandler();
    countryBlurHandler();
    contentBlurHandler();
    startDateTimeBlurHandler();
    endDateTimeBlurHandler();
    priceBlurHandler();
    stateBlurHandler();
    commentBlurHandler();
    serviceIDBlurHandler();

    // set form to valid if all fields are valid
    if (
      firstnameIsValid &&
      lastnameIsValid &&
      companyIsValid &&
      emailIsValid &&
      phoneIsValid &&
      postalIsValid &&
      cityIsValid &&
      streetIsValid &&
      streetNumberIsValid &&
      countryIsValid &&
      contentIsValid &&
      startDateTimeIsValid &&
      endDateTimeIsValid &&
      priceIsValid &&
      stateIsValid &&
      commentIsValid &&
      serviceIDIsValid
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  };

  const [dropdownElements, setDropdownElements] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(
    edit ? orderToEdit.title : null
  );
  const [selectedState, setSelectedState] = useState(
    edit ? convertStateToName(orderToEdit.state) : null
  );
  const setState = (event) => {
    stateChangeHandler(event);
    setSelectedState(event.target.innerText);
  };
  const setService = (event) => {
    serviceIDChangeHandler(event);
    setSelectedTitle(event.target.innerText);
  };

  useEffect(() => {
    const serviceJson = (json) => {
      // transform data here (extract data from JSend specification)
      setServices(json.data);

      setDropdownElements(
        <React.Fragment>
          {Object.keys(json.data).map((service) => {
            return (
              <li
                className="dropdown-item"
                key={'service_dropdown_' + json.data[service].id}
                onClick={setService}
                value={json.data[service].id}>
                {json.data[service].title}
              </li>
            );
          })}
        </React.Fragment>
      );
    };

    sendRequest(
      {
        url: apiURL + '/service/fetchAll',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      },
      serviceJson
    );
    // eslint-disable-next-line
  }, [sendRequest]);

  const retryHandler = () => {
    setErrors(null);
    setFormIsValid(false);
  };

  const resetHandler = () => {
    const orderID = edit ? orderToEdit.id : response ? response[1].id : null;

    navigate(orderID ? '/offer/' + orderID : '/');

    /*    
    setFormIsValid(false);
    setResponse(null);
    setErrors(null);
    setSuccess(false);

    firstnameReset &&
    lastnameReset &&
    companyReset &&
    emailReset &&
    phoneReset &&
    postalReset &&
    cityReset &&
    streetReset &&
    streetNumberReset &&
    countryReset &&
    titleReset &&
    contentReset&&
    startDateReset &&
    endDateReset &&
    priceReset&&
    serviceTypeIDReset
    */
  };

  // if form is valid -> send form
  if (formIsValid) {
    setFormIsValid(false);
    sendForm();
  }

  const errorContent = (
    <React.Fragment>
      <center className="py-5">
        <b className="error-text">
          {errors && errors.hasOwnProperty('data')
            ? JSON.stringify(errors.data)
            : null}
        </b>
        <br />
        <br />
        <button
          onClick={retryHandler}
          className="btn btn-primary bg-primary border-primary">
          Nochmal versuchen
        </button>
      </center>
    </React.Fragment>
  );

  const successContent = (
    <React.Fragment>
      <center className="py-5">
        <b className="success-text">
          {edit
            ? 'Anfrage erfolgreich bearbeitet'
            : 'Anfrage erfolgreich angelegt'}
        </b>
        <br />
        <br />
        <button
          autoFocus={true}
          onClick={resetHandler}
          className="btn btn-primary bg-primary border-primary">
          OK
        </button>
      </center>
    </React.Fragment>
  );

  const loadingSpinner = (
    <React.Fragment>
      <center className="py-5">
        <b>Anfrage senden ...</b>
        <br />
        <LoadingSpinnger />
      </center>
    </React.Fragment>
  );

  const formContent = (
    <React.Fragment>
      <section id="order">
        <div className="container py-5">
          <h2 className="me-5 md-3">
            {edit
              ? 'Anfrage ' + orderToEdit.orderID + ' bearbeiten '
              : 'Neue Anfrage'}
          </h2>
          {/*form*/}
          {/* FirstName */}
          <form onSubmit={formSubmitHandler}>
            <div className="form-group row pt-3">
              <label
                htmlFor="firstname"
                className="col-form-label col-sm-3 col-lg-2">
                Vorname
              </label>
              <div className="col-md-9 col-lg-10">
                <input
                  type="text"
                  className={
                    firstnameHasError ? 'form-control invalid' : 'form-control'
                  }
                  id="firstname"
                  placeholder="z.B. Max"
                  onChange={firstnameChangeHandler}
                  onBlur={firstnameBlurHandler}
                  value={firstname}
                />
                {firstnameHasError && (
                  <p className="error-text">
                    {'Vorname ' +
                      (firstname.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )}
              </div>
            </div>
            {/* lastname */}
            <div className="form-group row">
              <label
                htmlFor="lastname"
                className="col-form-label col-sm-3 col-lg-2">
                Nachname
              </label>
              <div className="col-md-9 col-lg-10">
                <input
                  type="text"
                  className={
                    lastnameHasError ? 'form-control invalid' : 'form-control'
                  }
                  id="lastname"
                  placeholder="z.B. Mustermann"
                  onChange={lastnameChangeHandler}
                  onBlur={lastnameBlurHandler}
                  value={lastname}
                />
                {lastnameHasError && (
                  <p className="error-text">
                    {'Nachname ' +
                      (lastname.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )}
              </div>
            </div>
            {/* company */}
            <div className="form-group row">
              <label
                htmlFor="company"
                className="col-form-label col-sm-3 col-lg-2">
                Firmenname
              </label>
              <div className="col-md-9 col-lg-10">
                <input
                  type="text"
                  className={
                    companyHasError ? 'form-control invalid' : 'form-control'
                  }
                  id="company"
                  placeholder="z.B. Musterfirma"
                  onChange={companyChangeHandler}
                  onBlur={companyBlurHandler}
                  value={company}
                />
                {companyHasError && (
                  <p className="error-text">
                    {'Firmenname ' +
                      (company.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )}
              </div>
            </div>
            {/* email */}
            <div className="form-group row">
              <label
                htmlFor="email"
                className="col-form-label col-sm-3 col-lg-2">
                Emailadresse
              </label>
              <div className="col-md-9 col-lg-10">
                <input
                  type="text"
                  className={
                    emailHasError ? 'form-control invalid' : 'form-control'
                  }
                  id="email"
                  placeholder="z.B. max.mustermann@musterfirma.de"
                  onChange={emailChangeHandler}
                  onBlur={emailBlurHandler}
                  value={email}
                />
                {emailHasError && (
                  <p className="error-text">
                    {'Emailadresse ' +
                      (email.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )}
              </div>
            </div>
            {/* phone */}
            <div className="form-group row">
              <label
                htmlFor="phone"
                className="col-form-label col-sm-3 col-lg-2">
                Telefonnummer
              </label>
              <div className="col-md-9 col-lg-10">
                <input
                  type="text"
                  className={
                    phoneHasError ? 'form-control invalid' : 'form-control'
                  }
                  id="phone"
                  placeholder="z.B. +49 123456789"
                  onChange={phoneChangeHandler}
                  onBlur={phoneBlurHandler}
                  value={phone}
                />
                {phoneHasError && (
                  <p className="error-text">
                    {'Telefonnummer ' +
                      (phone.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )}
              </div>
            </div>
            {/* postal */}
            <div className="form-group row">
              <label
                htmlFor="postal"
                className="col-form-label col-sm-3 col-lg-2">
                Postleitzahl
              </label>
              <div className="col-md-9 col-lg-10">
                <input
                  type="text"
                  className={
                    postalHasError ? 'form-control invalid' : 'form-control'
                  }
                  id="postal"
                  placeholder="z.B. 89518"
                  onChange={postalChangeHandler}
                  onBlur={postalBlurHandler}
                  value={postal}
                />
                {postalHasError && (
                  <p className="error-text">
                    {'Postleitzahl ' +
                      (postal.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )}
              </div>
            </div>
            {/* city */}
            <div className="form-group row">
              <label
                htmlFor="city"
                className="col-form-label col-sm-3 col-lg-2">
                Stadt
              </label>
              <div className="col-md-9 col-lg-10">
                <input
                  type="text"
                  className={
                    cityHasError ? 'form-control invalid' : 'form-control'
                  }
                  id="city"
                  placeholder="z.B. Heidenheim"
                  onChange={cityChangeHandler}
                  onBlur={cityBlurHandler}
                  value={city}
                />
                {cityHasError && (
                  <p className="error-text">
                    {'Stadt ' +
                      (city.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )}
              </div>
            </div>
            {/* street */}
            <div className="form-group row">
              <label
                htmlFor="street"
                className="col-form-label col-sm-3 col-lg-2">
                Straße
              </label>
              <div className="col-md-9 col-lg-10">
                <input
                  type="text"
                  className={
                    streetHasError ? 'form-control invalid' : 'form-control'
                  }
                  id="street"
                  placeholder="z.B. Musterstraße"
                  onChange={streetChangeHandler}
                  onBlur={streetBlurHandler}
                  value={street}
                />
                {streetHasError && (
                  <p className="error-text">
                    {'Straße ' +
                      (street.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )}
              </div>
            </div>
            {/* streetNumber */}
            <div className="form-group row">
              <label
                htmlFor="streetNumber"
                className="col-form-label col-sm-3 col-lg-2">
                Hausnummer
              </label>
              <div className="col-md-9 col-lg-10">
                <input
                  type="text"
                  className={
                    streetNumberHasError
                      ? 'form-control invalid'
                      : 'form-control'
                  }
                  id="streetNumber"
                  placeholder="z.B. 15"
                  onChange={streetNumberChangeHandler}
                  onBlur={streetNumberBlurHandler}
                  value={streetNumber}
                />
                {streetNumberHasError && (
                  <p className="error-text">
                    {'Hausnummer ' +
                      (streetNumber.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )}
              </div>
            </div>
            {/* country */}
            <div className="form-group row">
              <label
                htmlFor="country"
                className="col-form-label col-sm-3 col-lg-2">
                Land
              </label>
              <div className="col-md-9 col-lg-10">
                <input
                  type="text"
                  className={
                    countryHasError ? 'form-control invalid' : 'form-control'
                  }
                  id="country"
                  placeholder="z.B. Musterland"
                  onChange={countryChangeHandler}
                  onBlur={countryBlurHandler}
                  value={country}
                />
                {countryHasError && (
                  <p className="error-text">
                    {'Land ' +
                      (country.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )}
              </div>
            </div>
            <div className="pt-5 pb-5"></div>
            {/* startDate */}
            <div className="form-group row">
              <label
                htmlFor="startDateTime"
                className="col-form-label col-sm-3 col-lg-2">
                Beginn
              </label>
              <div className="col-md-9 col-lg-10">
                <DateTimePicker
                  onChange={startDateTimeChangeHandler}
                  value={startDateTime}
                />
                {startDateTimeHasError && (
                  <p className="error-text mb-2">
                    {'Startzeit ' +
                      (startDateTime.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'ist ungültig')}
                  </p>
                )}
              </div>
            </div>
            {/* endDate */}
            <div className="form-group row">
              <label
                htmlFor="endDateTime"
                className="col-form-label col-sm-3 col-lg-2">
                Ende
              </label>
              <div className="col-md-9 col-lg-10">
                <DateTimePicker
                  onChange={endDateTimeChangeHandler}
                  value={endDateTime}
                />
                {endDateTimeHasError && (
                  <p className="error-text mb-2">
                    {'Endzeit ' +
                      (endDateTime.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'ist ungültig')}
                  </p>
                )}
              </div>
            </div>
            {edit ? (
              <div className="form-group row">
                <label
                  htmlFor="price"
                  className="col-form-label col-sm-3 col-lg-2">
                  Preis
                </label>
                <div className="col-md-9 col-lg-10">
                  <input
                    type="text"
                    className={
                      priceHasError ? 'form-control invalid' : 'form-control'
                    }
                    id="endDate"
                    placeholder="z.B. 2023-01-01"
                    onChange={priceChangeHandler}
                    onBlur={priceBlurHandler}
                    value={price}
                  />
                  {priceHasError && (
                    <p className="error-text">
                      {'Ende ' +
                        (price.trim() === ''
                          ? 'darf nicht leer sein'
                          : 'enthält ungültige Zeichen')}
                    </p>
                  )}
                </div>
              </div>
            ) : (
              ''
            )}

            <div className="pt-5 pb-5">
              {/* Service */}
              <div className="form-group row">
                <label
                  htmlFor="service"
                  className="col-form-label col-sm-3 col-lg-2">
                  Art der Anfrage
                </label>
                <div className="col-md-9 col-lg-10">
                  <script
                    type="text/javascript"
                    src="Scripts/bootstrap.min.js"></script>
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="true">
                      {selectedTitle
                        ? selectedTitle
                        : 'Auswahl der Dienstleistung'}
                    </button>

                    <ul className="dropdown-menu">
                      {services ? dropdownElements : null}
                    </ul>
                  </div>
                </div>
              </div>
              {/* State */}
              {edit ? (
                <div className="form-group row">
                  <label
                    htmlFor="service"
                    className="col-form-label col-sm-3 col-lg-2">
                    Status der Anfrage
                  </label>
                  <div className="col-md-9 col-lg-10">
                    <script
                      type="text/javascript"
                      src="Scripts/bootstrap.min.js"></script>
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton2"
                        data-bs-toggle="dropdown"
                        aria-expanded="true">
                        {selectedState ? selectedState : 'Status '}
                      </button>

                      <ul className="dropdown-menu">
                        <li
                          className="dropdown-item"
                          key={'service_dropdown_1'}
                          onClick={setState}
                          value="1">
                          Angefragt
                        </li>
                        <li
                          className="dropdown-item"
                          key={'service_dropdown_2'}
                          onClick={setState}
                          value="2">
                          Angenommen
                        </li>
                        <li
                          className="dropdown-item"
                          key={'service_dropdown_3'}
                          onClick={setState}
                          value="3">
                          Erledigt
                        </li>
                        <li
                          className="dropdown-item"
                          key={'service_dropdown_4'}
                          onClick={setState}
                          value="9">
                          Abgesagt
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              {/* content */}
              <div className="form-group row">
                <label
                  htmlFor="content"
                  className="col-form-label col-sm-3 col-lg-2">
                  Titel
                </label>
                <div className="col-md-9 col-lg-10">
                  <input
                    type="text"
                    className={
                      contentHasError ? 'form-control invalid' : 'form-control'
                    }
                    id="content"
                    placeholder="z.B. Anfrage zur Messung von... "
                    onChange={contentChangeHandler}
                    onBlur={contentBlurHandler}
                    value={content}
                  />
                  {contentHasError && (
                    <p className="error-text">
                      {'Nachricht ' +
                        (content.trim() === ''
                          ? 'darf nicht leer sein'
                          : 'enthält ungültige Zeichen')}
                    </p>
                  )}
                </div>
              </div>

              {/* comment */}
              <div className="form-group row">
                <label
                  htmlFor="comment"
                  className="col-form-label col-sm-3 col-lg-2">
                  Nachricht
                </label>
                <div className="col-10">
                  <textarea
                    type="text"
                    className={
                      commentHasError ? 'form-control invalid' : 'form-control'
                    }
                    id="comment"
                    placeholder="z.B. Anfrage zur Messung von... "
                    rows="10"
                    onChange={commentChangeHandler}
                    onBlur={commentBlurHandler}
                    value={comment}
                  />
                  {commentHasError && (
                    <p className="error-text">
                      {'Nachricht ' +
                        (comment.trim() === ''
                          ? 'darf nicht leer sein'
                          : 'enthält ungültige Zeichen')}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {/* {!isLoading && errors != null && (
              <b className="error-text">{JSON.stringify(errors)}</b>
            )} */}
            {/* submit */}
            <div className="form-group row">
              <div className="col-md-9 col-lg-10">
                <button
                  /* autoFocus={true} */
                  type="submit"
                  className="btn btn-primary bg-primary border-primary">
                  {edit ? 'Aktualisieren' : 'Absenden'}
                </button>
                {/* Rechnung */}
                {edit ? (
                  <Link to={'/Order/Rechnung/' + orderToEdit.orderID}>
                    <button
                      className="btn btn-primary bg-primary border-primary"
                      autoFocus={false}>
                      Rechnung erstellen
                    </button>
                  </Link>
                ) : (
                  ''
                )}
              </div>
            </div>
          </form>
        </div>
      </section>
    </React.Fragment>
  );
  return (
    <section id="about">
      <div className="container-fluid">
        <center>
          <GoogleCalendar />
        </center>
      </div>
      <div>
        <React.Fragment>
          {!isLoading && !success && errors == null && formContent}
          {!isLoading && !success && errors != null && errorContent}
          {!isLoading && success && successContent}
          {isLoading && loadingSpinner}
        </React.Fragment>
      </div>
    </section>
  );
};

export default AddOrder;
