import './Services.css';
import ImgTextBox from '../../components/ImgTextBox';

const Services = () => {
  const imgTextBoxList = [
    /*
            Anleitung zum Hinzufügen neuer Dienstleistungen:
                1. geschweifte Klammern mit Werten kopieren und
                   unter das Array anfügen (Komma vorher nicht vergessen)

                    {
                        imgUrl: require("../../assets/img/dienstleistungen/dienstleistungen.png"),
                        imgAlt: "Dienstleistung",
                        title: "Dienstleistung Titel",
                        content: "Partner Text bzw. Untertitel",
                        buttonTitle: "Button Titel (optional)",
                        buttonUrl: "/Pfad/des/Buttons"
                    },

                2. imgUrl, imgAlt, Titel und Content der Dienstleistung abändern
                3. Bild hochladen und img im vorgegebenen Format anpassen
                    ( require("../../assets/img/dienstleistungen/...") )
                4. url des Buttons hinzufügen (optional)
                    bei externen Links z.B.: "https://www.google.de"
                    bei internen Links z.B.: "/Labore/Optischemesstechnik" (React Routing muss gesetzt sein! siehe App.js)
        */
    {
      imgUrl: require('../../assets/img/dienstleistungen/koordinatenmesstechnik_02.png'),
      imgAlt: 'LaborBeispiel',
      title: 'Prüfung und Messtechnik',
      content:
        'Durchführung von mess- und prüftechnischen Aufgaben (insb. industrielle Computertomographie)',
      buttonTitle: 'Zu den Laboren',
      buttonUrl: '/Labore/Fertigungsverfahren',
    },
    {
      imgUrl: require('../../assets/img/dienstleistungen/9.2seminar.png'),
      imgAlt: 'Schulung',
      title: 'Schulungen und Fortbildung',
      content:
        'Einführung und Schulung zu Methoden und Werkzeugen rund um das Rapid Product Development',
      buttonTitle: 'Aktuelles',
      buttonUrl: '/Aktuelles',
    },
    {
      imgUrl: require('../../assets/img/dienstleistungen/blessingDL.png'),
      imgAlt: 'Herr Blessing',
      title: 'Beratung & Unterstüzung',
      content:
        'Kooperative Forschung bei und für Industrieunternehmen. Begleitung von Unternehmen bei der Einführung von Mess- und Prüftechnik-Systemen. Umsetzung von Forschungsergebnissen für die industrielle Anwendung. Unterstützung bei der Erstellung von Risikobeurteilungen (CE-Konformität, Produktsicherheit)',
      buttonTitle: 'Hier buchen',
      buttonUrl: 'mailto:nico.blessing@stw.de',
    },
    {
      imgUrl: require('../../assets/img/dienstleistungen/feuerloescher.png'),
      imgAlt: 'Feuerloescher',
      title: 'Referenzbeispiele',
      content:
        'Falls wir Ihr Interesse geweckt haben, können Sie sich gerne anhand von Beispielen unter Aktuelles & Archiv ein Bild von unserer Arbeit machen',
      buttonTitle: 'Aktuelles & Archiv',
      buttonUrl: '/Aktuelles',
    },
    {
      imgUrl: require('../../assets/img/dienstleistungen/KetteQuellePixabay.jpg'),
      imgAlt: 'Beispiel1',
      title: 'Leichte Muster für Ketten',
      content: 'Hier sehen sie ein Referenzbeispiel',
    },
    {
      imgUrl: require('../../assets/img/dienstleistungen/VisierQuellePixabay.jpg'),
      imgAlt: 'Beispiel2',
      title: 'Schutzschilder zu Corona-Zeiten',
      content: 'Hier sehen sie ein Referenzbeispiel',
    },
  ];
  return (
    <section id="services">
      <div className="container text-center my-5 mx-auto">
        <h2 className="mb-3">Dienstleistungen</h2>
        Das Steinbeis Transferzentrum Zerstörungsfreie Prüfung und Messtechnik
        (ZPM) bietet für Ihre Aufgabenstellungen <br /> individuelle Ansätze und
        Dienstleistungen an.
      </div>
      <div className="container-lg justify-content-center align-items-center">
        {imgTextBoxList.map(
          (
            { imgUrl, imgAlt, title, content, buttonUrl, buttonTitle },
            index
          ) => {
            return (
              <ImgTextBox
                // key={index} ist für react zwingend erforderlich!
                key={index}
                className="my-5"
                imgUrl={imgUrl}
                imgAlt={imgAlt}
                title={title}
                content={content}
                buttonUrl={buttonUrl}
                buttonTitle={buttonTitle}
              />
            );
          }
        )}
      </div>
    </section>
  );
};

export default Services;
