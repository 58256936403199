import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useHttp from '../../hooks/use-http';
import { apiURL } from '../../utils/Constants';
import Orders from './components/Orders';
// import './Orders.css';

const DownloadsOverview = () => {
  const { isLoading, errors, sendRequest } = useHttp();
  const [orders, setOrders] = useState([]);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const json = (json) => {
      setOrders(json.data);
    };

    sendRequest(
      {
        url: apiURL + '/orders/fetchAll',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      },
      json
    );
  }, [sendRequest]);

  return (
    <React.Fragment>
      <section id="ordersOverview">
        <Orders isLoading={isLoading} errors={errors} data={orders} />
      </section>
    </React.Fragment>
  );
};

export default DownloadsOverview;
