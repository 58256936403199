import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faGraduationCap,
  faPeopleGroup,
  faUsersGear,
} from '@fortawesome/free-solid-svg-icons';
// import "./LebenslaufUndProjekte.css";
import TextBox from '../TextBox';
import ImgTextBox from '../ImgTextBox';

const LebenslaufUndProjekte = () => {
  library.add(faGraduationCap, faPeopleGroup, faUsersGear);
  const lebenslaufList = [
    {
      title: 'Qualifikation',
      bulletPoints: [
        'Studium der Physik(Dipl.-Phys.) an der Universität Konstanz',
        'Promotion zum Dr.-Ing. an der Universität Stuttgart und am Fraunhofer Institut für Produktionstechnik und Automatisierung',
      ],
      fontAwesome: 'fa-solid fa-graduation-cap',
    },
    {
      title: 'Führungserfahrung',
      bulletPoints: [
        'Projektleiter seit 2008',
        'Projekt-und Teilprojektleitung in Veränderungsprojekten',
        'Führen von Projektteams',
        'Mitarbeitercoaching in der Prozessumsetzung',
      ],
      fontAwesome: 'fa-solid fa-people-group',
    },
    {
      title: 'Prozessumsetzung Themenschwerpunkte',
      bulletPoints: [
        'Produktentstehungsprozess',
        'Qualitätsmethoden, wie z.B. FMEA, DRBFM, QFD, TRIZ',
        'Risikobeurteilungen, CE-Konformität',
        'Problemlösungs-und Qualitätsverbesserungsprojekte',
        'Beantragung, Durchführung und Begleitung von Projekten in F&E',
      ],
      fontAwesome: 'fa-solid fa-users-gear',
    },
  ];

  const projekte = [
    {
      imgUrl: require('../../assets/img/partner/DHBW-Logo.png'),
      imgAlt: 'DHBW',
      title: 'Studiengangsleiter',
      content:
        'Seit 2011 Studiengangsleiteran der Dualen Hochschule Baden-Württemberg Heidenheim im Studiengang Maschinenbau(Mathematik, Physik, Elektrotechnik, BWL, CE, Methoden des Produktentstehungsprozesses)',
    },
    {
      imgUrl: require('../../assets/logo/primary.png'),
      imgAlt: 'Steinbeis',
      title: 'Leiter Steinbeis-Transferzentrum',
      content:
        'Seit 2012 Leiter Steinbeis-Transferzentrum für Zerstörungsfreie Prüfung und Messtechnik(Forschung, Industrieprojekte, Dienstleistungen im Bereich Messtechnik, zerstörungsfreie Prüfung, industrielle Computertomographie)',
    },
    {
      imgUrl: require('../../assets/img/partner/TQU-Logo.png'),
      imgAlt: 'TQU International GmbH',
      title: 'Partner der TQU International GmbH',
      content:
        'Seit 2011 Partner der TQU International GmbH (Beratung und Implementierung in allen Bereichen des Produktentstehungsprozesses; Risikobeurteilungen & CE)',
    },
    {
      imgUrl: require('../../assets/img/partner/Zwick-Roell-Logo.jpeg'),
      imgAlt: 'Zwick/Roell GmbH & Co.KG',
      title: 'Entwicklungsleiter',
      content:
        '2004 –2010 Industrie: 6 Jahre Entwicklungsleiterbei der Zwick/Roell GmbH & Co.KG (Prüfmaschinenhersteller)',
    },
    {
      imgUrl: require('../../assets/img/partner/Fraunhofer-Logo.jpeg'),
      imgAlt: 'Fraunhofer Institut',
      title: 'Wissenschaftlicher Mitarbeiter',
      content:
        '1998 –2004 Wissenschaftlicher Mitarbeiter an der Universität Stuttgart und am Fraunhofer Institut für Produktionstechnik und Automatisierung, Stuttgart',
    },
  ];

  return (
    <section id="lebenslaufUndProjekteContent">
      <div className="container-lg align-items-center justify-content-center mt-5">
        <h1>Lebenslauf</h1>
        <div className="row">
          {
            // map => durchläuft alle TextBoxen in der textBoxList
            lebenslaufList.map(
              ({ title, bulletPoints, fontAwesome }, index) => {
                return (
                  <TextBox
                    // key={index} ist für react zwingend erforderlich!
                    key={index}
                    className="my-3"
                    shadowRow="true"
                    title={title}
                    bulletPoints={bulletPoints}
                    fontAwesome={fontAwesome}
                  />
                );
              }
            )
          }
        </div>

        <h1 className="mt-5">Projekte und Berufserfahrung</h1>
        <div className="row mb-5">
          {projekte.map(
            (
              { imgUrl, imgAlt, title, content, buttonUrl, buttonTitle },
              index
            ) => {
              return (
                <ImgTextBox
                  // key={index} ist für react zwingend erforderlich!
                  key={index}
                  className="my-3"
                  imgUrl={imgUrl}
                  imgAlt={imgAlt}
                  title={title}
                  content={content}
                  buttonUrl={buttonUrl}
                  buttonTitle={buttonTitle}
                />
              );
            }
          )}
        </div>
      </div>
    </section>
  );
};

export default LebenslaufUndProjekte;
