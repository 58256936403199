import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

const useHttp = () => {
  const token = useSelector((state) => state.auth.token);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const sendRequest = useCallback(
    async (reqConfig, resData) => {
      let subscribed = true;
      setIsLoading(true);
      setErrors(null);

      try {
        const response = await fetch(reqConfig.url, {
          method: reqConfig.method ? reqConfig.method : 'GET',
          headers: reqConfig.headers
            ? reqConfig.headers
            : token
            ? {
                Authorization: 'Bearer ' + token,
              }
            : {},
          body: reqConfig.body ? JSON.stringify(reqConfig.body) : null,
        });

        const json = await response.json();

        if (subscribed) {
          // looking for JSend specification (property: "status") in the json response
          if (json.hasOwnProperty('status')) {
            json.status === 'success'
              ? // if success
                resData(json)
              : // if error
                setErrors(json);
          } else {
            // if error: setErrors based on JSend specification
            throw new Error({
              status: 'error',
              code: response.status ? response.status : 500,
              message: response.status
                ? response.statusText
                : 'Internal Server Error',
            });
          }
        }
      } catch (err) {
        // if error: setErrors based on JSend specification
        setErrors(
          err || {
            status: 'error',
            code: 500,
            message: 'Internal Server Error',
          }
        );
      }
      setIsLoading(false);

      // cleanup function
      return () => {
        subscribed = false;
      };
    },
    [token]
  );

  return { isLoading, errors, setErrors, sendRequest };
};

export default useHttp;
