import React from 'react';
import './UeberMich.css';
import Button from '../Button';

const UeberMich = () => {
  const nameTitle = 'Prof. Dr.-Ing.';
  const name = 'Nico Blessing';
  const title = 'Hallo';
  const subtitle = 'Wer bin ich und was mache ich?';
  // const text = '';
  const img = require('../../assets/img/blessing_neu.jpg');
  const street = 'Amselweg 13';
  const location = 'D-89182 Bernstadt';
  const tel = '+49 171 5200970';
  const fax = '+49 17113 5200970';
  const email = 'nico.blessing@stw.de';
  const researchGate = 'https://www.researchgate.net/profile/Nico-Blessing';

  return (
    <section id="aboutme">
      {/* Hintergrund */}

      {/* Desktop Ansicht */}
      <div className="container-fluid profile-container d-none d-md-block">
        <div className="row box-bg-color">
          <div className="col-4 bg-secondary"></div>
        </div>
      </div>

      {/* Mobile Ansicht */}
      <div className="container-fluid profile-container-mobile d-block d-md-none">
        <div className="row box-bg-color-mobile">
          <div className="col bg-secondary"></div>
        </div>
        <div className="row box-bg-color-mobile">
          <div className="col"></div>
        </div>
      </div>

      {/* Vordergrund */}

      {/* Desktop Ansicht */}
      <div className="row box d-none d-md-block">
        <div className="col">
          <div className="row justify-content-center">
            {/* Profil Bild Box */}
            <div className="col-4 bg-primary shadow-lg p-4 text-center profile-img-box">
              <img
                className="img-fluid profile-img mb-3"
                src={img}
                alt="blessing.jpeg"
              />
              <h4 className="profile-img-text mt-3">
                {nameTitle}
                <br />
                {name}
              </h4>
            </div>

            {/* Profil Text Box */}
            <div className="col-4 align-self-center">
              <h1>{title}</h1>
              <h4 className="subtitle-text">{subtitle}</h4>
              <Button
                className="rounded-pill"
                url="/LebenslaufUndProjekte"
                title="Lebenslauf und Projekte"
              />
              <p className="profil-text mt-3">
                <b>Kontakt:</b>
                <br />
                {street}
                <br />
                {location}
                <br />
                Telefon: {tel}
                <br />
                Fax: {fax}
                <br />
                E-Mail: <a href={'mailto:' + email}>{email}</a>
                <br />
                ResearchGate: <a href={researchGate}>{name}</a>
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Ansicht */}
      <div className="row box d-block d-md-none">
        <div className="col">
          {/* Profil Bild Box */}
          <div className="row justify-content-center">
            <div className="col-8 bg-primary shadow-lg p-4 text-center">
              <img
                className="img-fluid profile-img mb-3"
                src={img}
                alt="blessing.jpeg"
              />
              <h4 className="profile-img-text mt-3">
                {nameTitle}
                <br />
                {name}
              </h4>
            </div>
          </div>

          {/* Profil Text Box */}
          <div className="row justify-content-center">
            <div className="col-8 p-3 text-center">
              <h1>{title}</h1>
              <h4 className="subtitle-text">{subtitle}</h4>
              <Button
                className="rounded-pill"
                url="/LebenslaufUndProjekte"
                title="Lebenslauf und Projekte"
              />
              <p className="profil-text mt-3">
                <b>Kontakt:</b>
                <br />
                {street}
                <br />
                {location}
                <br />
                Telefon: {tel}
                <br />
                Fax: {fax}
                <br />
                E-Mail: <a href={'mailto:' + email}>{email}</a>
                <br />
                ResearchGate: <a href={researchGate}>{name}</a>
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UeberMich;
