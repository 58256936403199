import React from 'react';
import { Link } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrash, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import './SeminarCard.css';
import {
  convertStateToName,
  convertToDateTime,
} from '../../../utils/attributeConverter';

const SeminarCard = (props) => {
  const seminar = props.seminar;
  // make first letter of type uppercase
  const type = seminar.type.charAt(0).toUpperCase() + seminar.type.slice(1);
  // convert state to name
  const status = convertStateToName(seminar.state);
  // convert startDateTime
  const startDateTime = convertToDateTime(seminar.startDateTime);
  const endDateTime = convertToDateTime(seminar.endDateTime);
  library.add(faTrash, faPenToSquare);

  return (
    <div className="row border">
      {/* title */}
      <div className="col-3 uuid">{seminar.title}</div>
      {/* startDateTime */}
      <div className="col">{startDateTime}</div>
      {/* endDateTime */}
      <div className="col">{endDateTime}</div>
      {/* type */}
      <div className="col">{type}</div>
      {/* status */}
      <div className="col">{status}</div>
      {/* buttons */}
      <div className="col-1 buttons">
        <div className="row justify-content-between">
          <div className="col-6">
            <Link to={'/Seminar/löschen/' + seminar.uuid} className="">
              <i className="fa-solid fa-trash ms-2"></i>
            </Link>
          </div>
          <div className="col-6">
            <Link to={'/Seminar/bearbeiten/' + seminar.uuid} className="">
              <i className="fa-solid fa-pen-to-square"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeminarCard;
