import HeaderNav from './HeaderNav';
import MainNav from './MainNav';

const Navigation = () => {
  return (
    <div id="Navigation">
      <HeaderNav />
      <MainNav />
    </div>
  );
};

export default Navigation;
