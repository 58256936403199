import Button from './Button';

/*
    Beispiel:
        <ImgTextBox
            key=0                   // erforderlich: für react, muss eindeutig sein!
            className="my-3"        // optional: Klassen, die der ImgTextBox mitgegeben werden können
            imgUrl={require("../../assets/img/image.jpg")}
                                    // optional aber sinnvoll: Bild der ImgTextBox
            imgAlt="image"          // optional
            title="Titel"           // optional aber sinnvoll: Titel der ImgTextBox
            content="Text ..."      // optional aber sinnvoll: Text der ImgTextBox
            buttonTitle="Zum Labor" // erforderlich: Button Title
            buttonUrl="/Labore/Optischemesstechnik"
                                    // erforderlich: setzt den Button Link
                                    //  bei externen Links z.B.: "https://www.google.de"
                                    //  bei internen Links z.B.: "/Labore/Optischemesstechnik" (React Routing muss gesetzt sein! siehe App.js)
            buttonClassName="my-3"  // optional: Klassen, die dem Button mitgegeben werden können
        />
 */

const ImgTextBox = (props) => {
  const classes =
    'row shadow align-items-center justify-content-center p-3 rounded ' +
    (props.className ? props.className : '');
  return (
    <div className={classes}>
      <div className="col-md-5">
        <img
          className="img-fluid rounded"
          src={props.imgUrl}
          alt={props.imgAlt}
        />
      </div>
      <div className="col">
        <h4 className="mt-3">{props.title}</h4>
        <div className="row my-4">
          <div className="col">
            {props.content}
            <div className="col">
              {props.buttonTitle ? (
                <Button
                  title={props.buttonTitle}
                  url={props.buttonUrl}
                  className={props.buttonClassName}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImgTextBox;
