import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faDatabase,
  faRulerCombined,
  faScaleUnbalancedFlip,
  faRuler,
} from '@fortawesome/free-solid-svg-icons';
import TextBox from '../../../components/TextBox';
import Button from '../../../components/Button';
import '../Labs.css';

const Koordinatenmesstechnik = () => {
  library.add(faDatabase, faRulerCombined, faScaleUnbalancedFlip, faRuler);
  const textBoxList = [
    /*
            Anleitung zum Hinzufügen neuer Inhalte:
                1. geschweifte Klammern mit Werten kopieren und
                   unter das Array anfügen (Komma vorher nicht vergessen)

                   {
                       title: " Titel",
                       bulletPoints: [
                           "Punkt 1",
                           "Punkt 2",
                           "Punkt 2",
                           "Punkt 2"
                       ],
                       fontAwesome: "fa-solid fa-symbol"
                   },

                2. Titel und Text der bulletPoints abändern
                3. Passendes Symbol auf https://fontawesome.com/icons aussuchen (Bsp: <i class="fa-solid fa-database"></i>)
                    1. Dieses Symbol (fa-database) aus dem Datenpaket '@fortawesome/free-solid-svg-icons' oben bei den Imports hinzufügen
                    dabei ist darauf zu achten dass die Bindestriche durch Großbuchstaben ersetzt werden (Bsp. fa-database -> faDatabase)
                    2. zur Library vor dem Datenarray hinzufügen
                    vorher: library.add(faGlasses, faScrewdriverWrench,...);
                    nachher: library.add(faGlasses, faScrewdriverWrench,..., faDatabase);
                    3. im Array aufrufen (fontAwesome: "fa-solid fa-database)
                4. ggf. einfügen
                <div>
                <Download
                    key= "0"
                    className= "my-3"
                    title="Dateiname.dateiendung"
                    link="/downloads/Dateiname.dateiendung"
                    fontAwesome="fa-solid fa-file-zipper"
                />
                </div>
        */
    {
      title: 'Daten',
      bulletPoints: [
        'Antastrichtung: 6 Achsen, x-,y-,z-, Richtung',
        'Antastkraft; Senkrecht bis Waagerecht bis zu 1,4N',
        'Wiederholgenauigkeit der Messung: 0,5 µm',
        'Umgebungstemperatur: +5°C bis +40°C',
        'Zulässiger Schalldruck: 75dBA',
        'Materialien VAST Gold, HHS',
      ],
      fontAwesome: 'fa-solid fa-database',
    },
    {
      title: 'Abmessung und Gewicht',
      bulletPoints: [
        'Planscheibe, Durchmesser: 300mm',
        'Zentrierbohrung, Durchmesser: 50mm',
        'Gewicht: 75kg',
      ],
      fontAwesome: 'fa-solid fa-ruler-combined',
    },
    {
      title: 'Belastbarkeit/Grenzwerte',
      bulletPoints: [
        'Kippmoment, max: 50Nm',
        'Werkstückgewicht, max: 100kg',
        'Messträgheitsmoment, max: mit Kollisionsschutz: 1,123kgm², ohne Kollisionschutz: 3kgm²',
      ],
      fontAwesome: 'fa-solid fa-scale-unbalanced-flip',
    },
    {
      title: 'Gebrauch',
      bulletPoints: [
        'Mit dem Drehtisch können Werkstücke in beliebige Winkelstellungen positioniert werden. Dadurch werden hochgenaue Winkel- und Radialmessungen möglich. Eine besondere Anwendung von Drehtischen ist das Vermessen von Rotationskörpern. Drehtische erleichtern ebenso das Vermessen von prismatischen Teilen.',
      ],
      fontAwesome: 'fa-solid fa-ruler',
    },
  ];

  return (
    <section id="laborContent">
      <div className="container-lg mt-5 justify-content-center align-items-center">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-5">
            {/* Bild  */}
            <img
              className="img-fluid rounded"
              src={require('../../../assets/img/UMC550.png')}
              alt="UMC550"
            />
            <p>Zeiss UMC 550 (Messmaschine)</p>
          </div>
          <div className="col-md-5">
            <h2>Labor für Koordinatenmesstechnik</h2>
            <div className="row mt-4 mb-4">
              <div className="col">
                <h4>
                  Die Messmaschine tastet mit Hilfe eines Tastkopfes die
                  eingespannten Werkstücke ab und stellt sie anschließend
                  dreidimensional am Computer da.
                </h4>
                {/* Button auf die virtuelle Begehung */}
                {
                  <Button
                    title="360° Blick ins Labor"
                    url="https://my.matterport.com/show/?m=SCEKg6tnmtc&sr=-.96,-1.09&ss=42"
                  />
                }
              </div>
            </div>
          </div>
        </div>
        {
          // map => durchläuft alle TextBoxen in der textBoxList
          textBoxList.map(
            (
              { className, classNameCol, title, bulletPoints, fontAwesome },
              index
            ) => {
              return (
                <TextBox
                  // key={index} ist für react zwingend erforderlich!
                  key={index}
                  className="my-4"
                  title={title}
                  bulletPoints={bulletPoints}
                  fontAwesome={fontAwesome}
                />
              );
            }
          )
        }
      </div>
    </section>
  );
};

export default Koordinatenmesstechnik;
