import React from 'react';
import { default as DTP } from 'react-datetime-picker';
import './DateTimePicker.css';

const DateTimePicker = (props) => {
  //const calendarClassName = className;
  let date = new Date();
  date.setHours(props.setHours ? props.setHours : 8);
  date.setMinutes(props.setMinutes ? props.setMinutes : 0);
  date.setSeconds(props.setSeconds ? props.setSeconds : 0);

  return (
    <DTP
      onChange={props.onChange}
      value={props.value ? props.value : date}
      name={props.name ? props.name : 'DateTimePicker'}
      className={'datetimepicker'}
      calendarClassName={'datetimepicker'}
      autoFocus={false}
      format="yyyy-MM-dd hh:mm:ss"
      showLeadingZeros={true}
      disableClock={true}
      hourPlaceholder="00"
      minutePlaceholder="00"
      secondPlaceholder="00"
      locale="de-DE"
    />
  );
};

export default DateTimePicker;
