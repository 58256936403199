import { useState } from 'react';

const useInput = ({ defaultValue = '', validate }) => {
  const [value, setValue] = useState(defaultValue);
  const [isTouched, setIsTouched] = useState(false);

  const isValid = validate(value);
  const hasError = !isValid && isTouched;

  const valueChangeHandler = (event) => {
    setValue(event.target.value);
  };

  const inputBlurHandler = (event) => {
    setIsTouched(true);
  };

  const reset = (resetValue) => {
    setValue(resetValue ? resetValue : defaultValue);
    setIsTouched(false);
  };

  return {
    value,
    setValue, // use to direct set value
    isValid,
    hasError,
    valueChangeHandler, // use inside input fields
    inputBlurHandler,
    reset,
  };
};

export default useInput;
