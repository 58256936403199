import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { authActions } from '../../store/auth';
import { Link } from 'react-router-dom';
import validator from 'validator';
import useHttp from '../../hooks/use-http';
import useInput from '../../hooks/use-input';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import { apiURL } from '../../utils/Constants';
import './Auth.css';

const Login = () => {
  const dispatch = useDispatch();
  const { isLoading, errors, setErrors, sendRequest } = useHttp();
  const [formIsValid, setFormIsValid] = useState(false);
  // eslint-disable-next-line
  const [response, setResponse] = useState(null);
  const [success, setSuccess] = useState(false); // only for development (React.strict)

  // email
  const {
    value: email,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: emailReset,
  } = useInput({
    validate: (value) => validator.isEmail(value.trim()),
  });

  // password
  const {
    value: password,
    // isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: passwordReset,
  } = useInput({
    validate: (value) => true, // do not validate user entered password
  });

  // if response is a json with JSend specification { status: "success" ... }
  const json = (json) => {
    dispatch(
      authActions.login({
        id: json.data.id,
        uuid: json.data.uuid,
        token: json.data.token,
        firstname: json.data.firstname,
        lastname: json.data.lastname,
        JWT_EXPIRES_AT: json.data.JWT_EXPIRES_AT,
      })
    );
    setResponse(json.data);
    setSuccess(true);
  };

  const sendForm = async (event) => {
    await sendRequest(
      {
        url: apiURL + '/auth/login',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: {
          email: email,
          password: password,
        },
      },
      json
    );
  };

  // if form was submitted
  const formSubmitHandler = (event) => {
    event.preventDefault();

    // set all fields as touched
    emailBlurHandler();
    passwordBlurHandler();

    // set form to valid if all fields are valid
    emailIsValid ? setFormIsValid(true) : setFormIsValid(false);
  };

  const retryHandler = () => {
    setErrors(null);
    setFormIsValid(false);
  };

  const resetHandler = () => {
    setFormIsValid(false);
    setResponse(null);
    setErrors(null);
    setSuccess(false);

    emailReset();
    passwordReset();
  };

  // if form is valid -> send form
  if (formIsValid) {
    setFormIsValid(false);
    sendForm();
  }

  const errorContent = (
    <React.Fragment>
      <center className="py-5">
        <h3 className="error-text">Anmeldung fehlgeschlagen!</h3>
        <br />
        {errors && errors.hasOwnProperty('data') ? (
          <p className="error-text pb-5">{JSON.stringify(errors.data)}</p>
        ) : null}
        <button
          autoFocus={true}
          onClick={retryHandler}
          className="btn bg-primary border-primary">
          Nochmal versuchen
        </button>
      </center>
    </React.Fragment>
  );

  const successContent = (
    <React.Fragment>
      <center className="py-5">
        <h3 className="success-text">Anmeldung erfolgreich!</h3>
        <br />
        <Link to="/">
          <button
            onClick={resetHandler}
            className="btn bg-primary border-primary"
            autoFocus={true}>
            OK
          </button>
        </Link>
      </center>
    </React.Fragment>
  );

  const loadingSpinner = (
    <React.Fragment>
      <center className="py-5">
        <b>Login ...</b>
        <br />
        <LoadingSpinner />
      </center>
    </React.Fragment>
  );

  const formContent = (
    <React.Fragment>
      <section id="login">
        <div className="container">
          <div className="login-page">
            {/* Form */}
            <form className="form" onSubmit={formSubmitHandler}>
              <h1 className="text-secondary pt-0">Anmelden</h1>

              {/* email */}
              <div className="form-group row">
                <label htmlFor="email" className="col-form-label" hidden>
                  Email Address
                </label>
                <input
                  className={
                    emailHasError ? 'form-control invalid' : 'form-control'
                  }
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  onChange={emailChangeHandler}
                  onBlur={emailBlurHandler}
                  value={email}
                />
                {emailHasError && (
                  <p className="error-text">
                    {'Email ' +
                      (email.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )}
              </div>

              {/* password */}
              <div className="form-group row">
                <label htmlFor="password" className="col-form-label" hidden>
                  Password
                </label>
                <input
                  className={
                    passwordHasError ? 'form-control invalid' : 'form-control'
                  }
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Passwort"
                  onChange={passwordChangeHandler}
                  onBlur={passwordBlurHandler}
                  autoFocus={true}
                />
              </div>

              {/* submit */}
              <div className="form-group row">
                <input
                  type="submit"
                  name="submit"
                  className="input-submit"
                  value="Anmelden"
                />
              </div>

              {/* no account, register? */}
              <p className="text text-white">
                Noch keinen Account?{' '}
                <Link to="/register" className="text-links">
                  Registrieren
                </Link>
              </p>
            </form>
          </div>
        </div>
      </section>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {!isLoading && !success && errors == null && formContent}
      {!isLoading && !success && errors != null && errorContent}
      {!isLoading && success && successContent}
      {isLoading && loadingSpinner}
    </React.Fragment>
  );
};

export default Login;
