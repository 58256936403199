import React from 'react';
import Iframe from 'react-iframe'; // https://www.npmjs.com/package/react-iframe
import useWindowDimensions from '../hooks/use-windowDimensions';
import './GoogleCalendar.css';

const GoogleCalendar = (calendar) => {
  // id and class
  const id = calendar.id ? calendar.id : 'GoogleCalendar';
  const className = calendar.className ? calendar.className : '';

  // dimensions
  const { height: deviceHeight, width: deviceWidth } = useWindowDimensions();
  let width = calendar.width ? calendar.width : '800';
  width = width > deviceWidth - 50 ? deviceWidth - 50 : width;
  let height = calendar.height ? calendar.height : '600';
  height = height > deviceHeight ? deviceHeight : height;

  // colors
  const bgcolor = calendar.bgcolor ? calendar.bgcolor : 'ffffff'; // primary: 00283f, hex-color without #
  const colorBeratung = '039BE5';
  const colorMessung = 'D50000';
  const colorSeminar = '33B679';
  const colorFeiertag = '0B8043';

  // style and customizing
  const style = calendar.style ? calendar.style : 'border-width:0'; // "border:solid 1px #00283f"
  const mode = calendar.mode ? calendar.mode : 'WEEK'; // mode = WEEK, MONTH, AGENDA (Woche, Monat, Terminübersicht)
  const showCalendars = calendar.showCalendars ? calendar.showCalendars : 1;
  const showNav = calendar.showNav ? calendar.showNav : 1;
  const showPrint = calendar.showPrint ? calendar.showPrint : 0;
  const showTabs = calendar.showTabs ? calendar.showTabs : 1;
  const showTitle = calendar.showTitle ? calendar.showTitle : 0;

  return (
    <section id="googlecalendar">
      <center>
        <Iframe
          id={id}
          className={'googlecalendar py-3 ' + className}
          src={
            'https://calendar.google.com/calendar/embed?height=' +
            height +
            '&wkst=2&bgcolor=%23' +
            bgcolor +
            '&ctz=Europe%2FBerlin&mode=' +
            mode +
            '&showTitle=' +
            showTitle +
            '&showPrint=' +
            showPrint +
            '&showCalendars=' +
            showCalendars +
            '&showTabs=' +
            showTabs +
            '&showNav=' +
            showNav +
            '&src=NGVlYWM1MGU2NDY3YTZkNzNlNGQ4ZmFjMTFmYWE0MzM1NmU5ZTViMDE1YjhiY2UxZGZjY2EwOGY1MWRiOGViNEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t' +
            '&src=NmQwN2YwOTVlNjllYTFkOWFkNWExYjBkOTcyZTJmMzEyM2U0OTQ2OGMyMTU2NGQ4YTM5MDA5M2YyMDc4ZmNlMEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t' +
            '&src=ZWU1MDk5NjFjN2Y5N2ViNzc1YjZkYzU1YWNkNWI4ODY2MjZmOWVmY2NkNjYxZDQ3Y2M0Y2E0MDBmNWQ5MzVjMUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t' +
            '&src=ZGUuZ2VybWFuI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t' +
            '&color=%23' +
            colorBeratung +
            '&color=%23' +
            colorMessung +
            '&color=%23' +
            colorSeminar +
            '&color=%23' +
            colorFeiertag
          }
          style={style}
          width={width}
          height={height}
          frameborder="0"
          scrolling="yes"></Iframe>
      </center>
    </section>
  );
};

export default GoogleCalendar;
