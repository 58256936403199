import React from 'react';
import { useState } from 'react';

import './Accordion.css';
import Button from './Button';
import Carousel from './Carousel';
import Download from './Download';
import PartnerCard from './PartnerCard';

/*
    Beispiel:
        <Accordion
            key=0                           // erforderlich: für react, muss eindeutig sein!
            eventID="One"                   // erforderlich: muss eindeutig sein!
            className="my-3"                // optional: Klassen, die dem Component mitgegeben werden können
            collapsed={false}               // optional: true: Accordion ist zugeklappt; false: Accordion ist ausgeklappt
            title="Title 0"                 // optional aber sinnvoll: Titel des Accordions
            subtitle="Untertitel 0"         // optional aber sinnvoll: Untertitel des Accordions
            content={content}               // optional aber sinnvoll: Content der im ausgeklappten Zustand zu sehen ist
            buttonTitle="Zum Labor"         // optional: wenn gesetzt erscheint ein Button mit der gesetzten Url
            buttonUrl="/Labore/Optischemesstechnik"
                                            // erforderlich, wenn buttonTitle gesetzt: setzt den Button Link
                                            //  bei externen Links z.B.: "https://www.google.de"
                                            //  bei internen Links z.B.: "/Labore/Optischemesstechnik" (React Routing muss gesetzt sein! siehe App)
            images=[
                {
                    imgUrl: require("../assets/img/image1.jpg"),
                    imgAlt: "image1"
                },
                {
                    imgUrl: require("../assets/img/image2.jpg"),
                    imgAlt: "image2"
                }
            ]                               // optional: Array mit Bildern
        />
 */

const Accordion = (props) => {
  const event = props.event;
  const classes = 'container-lg justify-content-center align-items-center';
  const heading = 'heading' + event.eventID;
  const collapse = 'collapse' + event.eventID;
  const hashcollapse = '#collapse' + event.eventID;
  var collapsed = event.collapsed === undefined ? true : event.collapsed;

  const clickHandler = () => {
    // Ist das Accordion zusammengeklappt ? ...
    if (collapsed) {
      // ... dann klappe aus (expand)
      setAccordionItemContent(expandedAccordionItemContent);
      currentAccordionItemContent = expandedAccordionItemContent;
      collapsed = false;
    } else {
      // ... sonst klappe ein (collapse)
      setAccordionItemContent(collapsedAccordionItemContent);
      currentAccordionItemContent = collapsedAccordionItemContent;
      collapsed = true;
    }
  };

  // definiere ausgeklappten Zustand (expanded)
  const expandedAccordionItemContent = (
    <React.Fragment>
      <h2 className="accordion-header" id={heading}>
        <div className={classes}>
          <div className="row align-items-center justify-content-center p-3 rounded">
            <div className="col-md-8">
              {/* <img className="img-fluid rounded" src={event.imgUrl} alt={event.imgAlt} /> */}
              <Carousel
                key={'carouselID' + event.eventID}
                carouselID={event.eventID}
                images={event.images}
              />
            </div>
            <div className="col-md-4">
              <button
                className="accordion-button shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={hashcollapse}
                aria-expanded="true"
                aria-controls={collapse}
                onClick={clickHandler}>
                <div className="col">
                  <div className="row">
                    <div className="col">
                      <h2 className="mt-3">{event.title}</h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">{event.subtitle}</div>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </h2>
      <div
        id={collapse}
        className="accordion-collapse collapse show"
        aria-labelledby={heading}
        data-bs-parent={'#accordion' + event.eventID}>
        <div className="accordion-body">
          <div className="row">
            <div className="col text-start">{event.content}</div>
          </div>
          {event.partner ? (
            <React.Fragment>
              <h4 className="mt-5 mb-3">Beteiligte Partner</h4>
              <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-5 justify-content-center">
                {event.partner.map(({ id, title, text, img, url }, index) => {
                  return (
                    <PartnerCard
                      key={index}
                      title={title}
                      text={text}
                      img={img}
                      url={url}
                    />
                  );
                })}
              </div>
            </React.Fragment>
          ) : null}
          <br />
          {event.buttonTitle ? (
            <Button title={event.buttonTitle} url={event.buttonUrl} />
          ) : null}
          {event.downloads
            ? event.downloads.map(
                ({ className, title, link, image, fontAwesome }, index) => {
                  return (
                    <Download
                      key={index} // key={index} ist für react zwingend erforderlich!
                      className={className}
                      title={title}
                      link={link}
                      image={image}
                      fontAwesome={fontAwesome}
                    />
                  );
                }
              )
            : null}
        </div>
      </div>
    </React.Fragment>
  );

  // definiere zusammengeklappten Zustand (collapsed)
  const collapsedAccordionItemContent = (
    <React.Fragment>
      <h2 className="accordion-header" id={heading}>
        <button
          className="accordion-button collapsed shadow-none"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={hashcollapse}
          aria-expanded="false"
          aria-controls={collapse}
          onClick={clickHandler}>
          <div className={classes}>
            <div className="row align-items-center justify-content-center p-3 rounded">
              <div className="col-md-5">
                {/* In der zugeklappten Ansicht nimm immer das erste Foto im images Array */}
                <img
                  className="img-fluid rounded"
                  src={event.images[0].imgUrl}
                  alt={event.images[0].imgAlt}
                />
                {/*
                                <Carousel
                                    key={"carouselID" + event.eventID}
                                    carouselID={event.eventID}
                                    images={event.images}
                                />
                                */}
              </div>
              <div className="col-md-7">
                <h2 className="mt-3">{event.title}</h2>
                <div className="row my-4">
                  <div className="col">{event.subtitle}</div>
                </div>
              </div>
            </div>
          </div>
        </button>
      </h2>
      <div
        id={collapse}
        className="accordion-collapse collapse"
        aria-labelledby={heading}
        data-bs-parent={'#accordion' + event.eventID}>
        <div className="accordion-body">
          {event.content}
          <div className="col">
            {event.buttonTitle ? (
              <Button title={event.buttonTitle} url={event.buttonUrl} />
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  // definiere aktuellen Zustand des Accordions
  var currentAccordionItemContent = collapsed
    ? collapsedAccordionItemContent
    : expandedAccordionItemContent;
  // speichere den Zustand in accordionItemContent und überwache ihn mit useState()
  // setze einen neuen Zustand mit setAccordionItemContent
  const [accordionItemContent, setAccordionItemContent] = useState(
    currentAccordionItemContent
  );

  return (
    <div
      className={'accordion accordion-flush shadow ' + event.className}
      id={'accordion' + event.eventID}>
      <div className="accordion-item">{accordionItemContent}</div>
    </div>
  );
};

export default Accordion;
