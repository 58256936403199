import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faGraduationCap,
  faSearch,
  faDownload,
  faEnvelopeOpenText,
  faInbox,
  faUser,
  faRightToBracket,
} from '@fortawesome/free-solid-svg-icons';
import './HeaderNav.css';

const HeaderNav = () => {
  library.add(
    faGraduationCap,
    faSearch,
    faDownload,
    faEnvelopeOpenText,
    faInbox,
    faUser,
    faRightToBracket
  );
  const isAuth = useSelector((state) => state.auth.isAuthenticated);

  return (
    <nav className="navbar navbar-expand bg-white p-1" id="headerNav">
      <div className="container-lg">
        {/* Logo */}
        <a className="navbar-brand ms-1" href="/">
          <img
            className="navbar-img"
            src={require('../../assets/logo/primary.webp')}
            alt="primary.webp"
          />
        </a>
        <ul className="navbar-nav ms-auto">
          {/*
            <form className="d-flex">
                <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                <button className="btn btn-outline-success" type="submit">Search</button>
            </form>
          */}

          {/*  Search 
          <li className="nav-item">
            <NavLink to="/Suche" className="nav-link" key="header-nav-logout">
              <i className="fa-solid fa-search"></i>
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink to="/" className="nav-link" key="header-nav-logout">
              DE
            </NavLink>
          </li> */}

          {/* Seminar */}
          {isAuth ? (
            <li className="nav-item">
              <NavLink
                to="/Seminar/Übersicht"
                className="nav-link"
                key="header-nav-logout"
              >
                <i className="fa-solid fa-graduation-cap"></i>
              </NavLink>
            </li>
          ) : null}

          {/* Newsletter */}
          {isAuth ? (
            <li className="nav-item">
              <NavLink
                to="/Newsletter"
                className="nav-link"
                key="header-nav-logout"
              >
                <i className="fa-solid fa-envelope-open-text"></i>
              </NavLink>
            </li>
          ) : null}

          {/* Services */}
          {isAuth ? (
            <li className="nav-item">
              <NavLink
                to="/Order/Übersicht"
                className="nav-link"
                key="header-nav-login"
              >
                <i className="fa-solid fa-inbox"></i>
              </NavLink>
            </li>
          ) : null}

          {/* Downloads */}
          {isAuth ? (
            <li className="nav-item">
              <NavLink
                to="/Download/Übersicht"
                className="nav-link"
                key="header-nav-login"
              >
                <i className="fa-solid fa-download"></i>
              </NavLink>
            </li>
          ) : null}

          <li className="nav-item">
            {/* Login / Logout */}
            {isAuth ? (
              <NavLink
                to="/Logout"
                className="nav-link"
                key="header-nav-logout"
              >
                <i className="fa-solid fa-right-to-bracket"></i>
              </NavLink>
            ) : (
              <NavLink to="/Login" className="nav-link" key="header-nav-login">
                <i className="fa-solid fa-user"></i>
              </NavLink>
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default HeaderNav;
