import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import validator from 'validator';
import useHttp from '../../hooks/use-http';
import useInput from '../../hooks/use-input';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import { apiURL, frontendURL } from '../../utils/Constants';
import { v1 as UUIDv1 } from 'uuid';
// import './AddDownload.css';

const uuid = UUIDv1();

const generatePassword = () => {
  const lowercase = 'abcdefghijklmnopqrstuvwxyz';
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';
  const special = '!@#$%^&*()_+-=[]{}|;\':",.<>/?~';
  let password = '';

  for (let i = 0; i < 3; i++) {
    password += lowercase[Math.floor(Math.random() * lowercase.length)];
    password += uppercase[Math.floor(Math.random() * uppercase.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];
    password += special[Math.floor(Math.random() * special.length)];
  }

  return password.slice(0, 12);
};

const passwordGenerated = generatePassword();

const AddCustomerDownload = ({ customerDownloadToEdit }) => {
  const edit = customerDownloadToEdit != null ? true : false;
  const token = useSelector((state) => state.auth.token);

  const { isLoading, errors, setErrors, sendRequest } = useHttp();
  const [formIsValid, setFormIsValid] = useState(false);
  // eslint-disable-next-line
  const [response, setResponse] = useState(null);
  const [success, setSuccess] = useState(false); // only for development (React.strict)
  const navigate = useNavigate();

  const downloadLink =
    frontendURL +
    '/Kunden-Download/' +
    (edit ? customerDownloadToEdit.uuid : uuid);

  // customer
  const {
    value: customer,
    isValid: customerIsValid,
    hasError: customerHasError,
    valueChangeHandler: customerChangeHandler,
    inputBlurHandler: customerBlurHandler,
    /* reset: customerReset, */
  } = useInput({
    defaultValue: edit ? customerDownloadToEdit.customer : '',
    validate: (value) =>
      validator.isAlphanumeric(String(value).trim(), 'de-DE', {
        ignore: '!- ',
      }),
  });

  // customerEmail
  const {
    value: customerEmail,
    isValid: customerEmailIsValid,
    hasError: customerEmailHasError,
    valueChangeHandler: customerEmailChangeHandler,
    inputBlurHandler: customerEmailBlurHandler,
    /* reset: customerEmailReset, */
  } = useInput({
    defaultValue: edit ? customerDownloadToEdit.customerEmail : '',
    validate: (value) => validator.isEmail(value.trim()),
  });

  // password
  const {
    value: password,
    /* isValid: passwordIsValid, */
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    /* reset: passwordReset, */
  } = useInput({
    defaultValue: edit ? customerDownloadToEdit.password : passwordGenerated,
    validate: (value) =>
      edit
        ? true
        : validator.isStrongPassword(value.trim(), {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
            returnScore: false,
            /* // if returnScore: true:
      pointsPerUnique: 1,
      pointsPerRepeat: 0.5,
      pointsForContainingLower: 10,
      pointsForContainingUpper: 10,
      pointsForContainingNumber: 10,
      pointsForContainingSymbol: 10, */
          }), // do not validate user entered password
  });

  // passwordRepeat
  const {
    value: passwordRepeat,
    /* isValid: passwordRepeatIsValid, */
    hasError: passwordRepeatHasError,
    valueChangeHandler: passwordRepeatChangeHandler,
    inputBlurHandler: passwordRepeatBlurHandler,
    /* reset: passwordRepeatReset, */
  } = useInput({
    defaultValue: edit ? customerDownloadToEdit.password : passwordGenerated,
    validate: (value) => (value === password ? true : false),
  });

  // file
  const [file, setFile] = useState({});
  const {
    value: fileName,
    /* isValid: fileNameIsValid, */
    hasError: fileNameHasError,
    valueChangeHandler: fileNameChangeHandler,
    inputBlurHandler: fileNameBlurHandler,
    /* reset: fileNameReset, */
  } = useInput({
    defaultValue: null,
    validate: (value) =>
      validator.isAlphanumeric(
        String(value).trim().toLocaleLowerCase().slice(12),
        'de-DE',
        {
          ignore: '-_. ',
        }
      ) &&
      String(value)
        .toLocaleLowerCase()
        .slice(12)
        .match(/.*.(zip|x-zip-compressed)$/g),
  });
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const convertFileToBase64 = async (e) => {
    fileNameChangeHandler(e);
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setFile(base64);
  };

  const json = (json) => {
    // transform data here (extract data from JSend specification)
    setResponse(json.data);
    setSuccess(true);
  };

  const sendForm = async (event) => {
    const fileNameTrimmed = String(fileName)
      .trim()
      .toLowerCase()
      .replace(
        'c:' + String.fromCharCode(92) + 'fakepath' + String.fromCharCode(92), // remove c:\fakepath\ from file
        ''
      )
      .replace(' ', '_');

    await sendRequest(
      {
        url:
          apiURL +
          (edit
            ? '/customer_downloads/update/' + customerDownloadToEdit.uuid
            : '/customer_downloads/add'),
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: {
          uuid: edit ? customerDownloadToEdit.uuid : uuid,
          customer,
          customerEmail,
          password,
          fileName: fileNameTrimmed,
          file,
        },
      },
      json
    );
  };

  // if form was submitted
  const formSubmitHandler = async (event) => {
    event.preventDefault();

    // set all fields as touched
    customerBlurHandler();
    customerEmailBlurHandler();
    passwordBlurHandler();
    passwordRepeatBlurHandler();
    fileNameBlurHandler();

    // set form to valid if all fields are valid
    if (customerIsValid && customerEmailIsValid) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  };

  const retryHandler = () => {
    setErrors(null);
    setFormIsValid(false);
  };

  const resetHandler = () => {
    navigate('/Downloads/Übersicht');
  };

  // if form is valid -> send form
  if (formIsValid) {
    setFormIsValid(false);
    sendForm();
  }

  const errorContent = (
    <React.Fragment>
      <center className="py-5">
        <b className="error-text mb-2">
          {errors && errors.hasOwnProperty('data')
            ? JSON.stringify(errors.data)
            : null}
        </b>
        <br />
        <br />
        <button
          onClick={retryHandler}
          className="btn btn-primary bg-primary border-primary">
          Nochmal versuchen
        </button>
      </center>
    </React.Fragment>
  );

  const successContent = (
    <React.Fragment>
      <center className="py-5">
        <b className="success-text">
          {'Download erfolgreich ' + (edit ? 'bearbeitet' : 'hinzugefügt')}
        </b>
        <br />
        <br />
        <button
          autoFocus={true}
          onClick={resetHandler}
          className="btn btn-primary bg-primary border-primary">
          OK
        </button>
      </center>
    </React.Fragment>
  );

  const loadingSpinner = (
    <React.Fragment>
      <center className="py-5">
        <b>
          {'Download wird ' + (edit ? 'bearbeitet' : 'hochgeladen') + ' ...'}
        </b>
        <br />
        <LoadingSpinner />
      </center>
    </React.Fragment>
  );

  const formContent = (
    <React.Fragment>
      <section id="offer">
        <div className="container pb-5">
          <h2 className="mt-5 mb-3">
            {edit ? 'Download bearbeiten' : 'Neuer Download'}
          </h2>

          {/* Form */}
          <form onSubmit={formSubmitHandler}>
            {/* download link */}
            <div className="form-group row my-1 align-items-center">
              <label
                htmlFor="DownloadLink"
                className="col-form-label col-sm-3 col-lg-2 ">
                Download Link
              </label>
              <div className="col-md-9 col-lg-10">
                <b>{downloadLink}</b>
              </div>
            </div>

            {/* username */}
            <div className="form-group row my-1 align-items-center">
              <label
                htmlFor="Username"
                className="col-form-label col-sm-3 col-lg-2 ">
                Benutzer
              </label>
              <div className="col-md-9 col-lg-10">
                <b>user</b>
              </div>
            </div>

            {/* download password */}
            <div className="form-group row mt-1 mb-5 align-items-center">
              <label
                htmlFor="DownloadPassword"
                className="col-form-label col-sm-3 col-lg-2 ">
                Passwort
              </label>
              <div className="col-md-9 col-lg-10">
                <b>{password}</b>
              </div>
            </div>

            {/* customer */}
            <div className="form-group row my-1">
              <label
                htmlFor="customer"
                className="col-form-label col-sm-3 col-lg-2">
                Kunde
              </label>
              <div className="col-md-9 col-lg-10">
                <input
                  type="text"
                  className={
                    customerHasError ? 'form-control invalid' : 'form-control'
                  }
                  id="customer"
                  placeholder="Firma Mustermann GmbH"
                  onChange={customerChangeHandler}
                  onBlur={customerBlurHandler}
                  value={customer}
                />
                {customerHasError && (
                  <p className="error-text mb-2">
                    {'Kunde ' +
                      (customer.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )}
              </div>
            </div>

            {/* customerEmail */}
            <div className="form-group row my-1">
              <label
                htmlFor="customerEmail"
                className="col-form-label col-sm-3 col-lg-2">
                Kunden E-Mail
              </label>
              <div className="col-md-9 col-lg-10">
                <input
                  type="text"
                  className={
                    customerEmailHasError
                      ? 'form-control invalid'
                      : 'form-control'
                  }
                  id="customerEmail"
                  placeholder="info@mustermann.de"
                  onChange={customerEmailChangeHandler}
                  onBlur={customerEmailBlurHandler}
                  value={customerEmail}
                />
                {customerEmailHasError && (
                  <p className="error-text mb-2">
                    {'Kunden E-Mail ' +
                      (customerEmail.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'enthält ungültige Zeichen')}
                  </p>
                )}
              </div>
            </div>

            {/* password */}
            <div className="form-group row my-1">
              <label
                htmlFor="password"
                className="col-form-label col-sm-3 col-lg-2">
                Password
              </label>
              <div className="col-md-9 col-lg-10">
                <input
                  className={
                    passwordHasError ? 'form-control invalid' : 'form-control'
                  }
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Passwort"
                  onChange={(event) => {
                    passwordChangeHandler(event);
                    passwordRepeatBlurHandler(event);
                  }}
                  onBlur={passwordBlurHandler}
                  value={password}
                />
                {passwordHasError && (
                  <p className="error-text mb-2">
                    {'Passwort ' +
                      (password.trim() === ''
                        ? 'darf nicht leer sein'
                        : 'ist nicht stark genug (mindestens 8 Zeichen, 1 Kleinbuchstabe, 1 Großbuchstabe, ' +
                          '1 Zahl, 1 Symbol)')}
                  </p>
                )}
              </div>
            </div>

            {/* passwordRepeat */}
            <div className="form-group row my-1">
              <label
                htmlFor="passwordRepeat"
                className="col-form-label col-sm-3 col-lg-2">
                Password wiederholen
              </label>
              <div className="col-md-9 col-lg-10">
                <input
                  className={
                    passwordRepeatHasError
                      ? 'form-control invalid'
                      : 'form-control'
                  }
                  type="password"
                  name="passwordRepeat"
                  id="passwordRepeat"
                  placeholder="Passwort wiederholen"
                  onChange={passwordRepeatChangeHandler}
                  onBlur={passwordRepeatBlurHandler}
                  value={passwordRepeat}
                />
                {passwordRepeatHasError && (
                  <p className="error-text mb-2">
                    {'Passwörter stimmen nicht überein'}
                  </p>
                )}
              </div>
            </div>

            {/* file */}
            {edit ? null : (
              <div className="form-group row my-1">
                <label
                  htmlFor="file"
                  className="col-form-label col-sm-3 col-lg-2">
                  Datei
                </label>
                <div className="col-md-9 col-lg-10">
                  <input
                    type="file"
                    className={
                      fileNameHasError
                        ? 'form-control-file invalid'
                        : 'form-control-file'
                    }
                    id="file"
                    name="file"
                    onChange={convertFileToBase64}
                    onBlur={fileNameBlurHandler}
                  />
                  {fileNameHasError && (
                    <p className="error-text mb-2">
                      Das Dateiformat ist ungültig (zip)
                    </p>
                  )}
                </div>
              </div>
            )}

            {/* submit */}
            <div className="form-group row my-3">
              <div className="col-md-9 col-lg-10">
                <button
                  /* autoFocus={true} */
                  type="submit"
                  className="btn btn-primary bg-primary border-primary">
                  {edit ? 'Speichern' : 'Datei hochladen'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {!isLoading && !success && errors == null && formContent}
      {!isLoading && !success && errors != null && errorContent}
      {!isLoading && success && successContent}
      {isLoading && loadingSpinner}
    </React.Fragment>
  );
};

export default AddCustomerDownload;
