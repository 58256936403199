import React from 'react';
import PartnerCard from '../../components/PartnerCard';
import './Partner.css';

const Partner = () => {
  const partnerList = [
    /*
            Anleitung zum Hinzufügen neuer Partner:
                1. geschweifte Klammern mit Werten kopieren und
                   unter das Array anfügen (Komma vorher nicht vergessen)

                   {
                       title: "Partner Titel",
                       text: "Partner Text bzw. Untertitel",
                       img: require("../../assets/img/partner/partner_logo.webp"),
                       url: "https://www.partnerlink.de/"
                   },

                2. Titel und Text des Partners abändern
                3. Bild hochladen und img im vorgegebenen Format anpassen
                   ( require("../../assets/img/partner/...") )
                   kann auch weggelassen werden (Platzhalter-Bild)
                4. url des Partners hinzufügen
        */
    {
      title: 'Fraunhofer-Gesellschaft',
      text: 'Fraunhofer-Gesellschaft zur Förderung der angewandten Forschung e.V.',
      img: require('../../assets/img/partner/fraunhofer-gesellschaft_50.webp'),
      url: 'https://www.fraunhofer.de/',
    },
    {
      title: 'EMPA',
      text: 'Eidgenössische Materialpüfungs- und Forschungsanstalt',
      img: require('../../assets/img/partner/empa_50.webp'),
      url: 'https://www.empa.ch/',
    },
    {
      title: 'RayScan Technologies GmbH',
      text: 'RayScan Technologies GmbH',
      img: require('../../assets/img/partner/rayscan_50.webp'),
      url: 'https://www.rayscan.eu/',
    },
    {
      title: 'Sonotec',
      text: 'Sonotec Ultraschallsensorik Halle GmbH',
      img: require('../../assets/img/partner/sonotec_50.webp'),
      url: 'https://www.sonotec.de/',
    },
    {
      title: 'Winterthur Instruments GmbH',
      text: 'Winterthur Instruments GmbH',
      img: require('../../assets/img/partner/winterthurinstruments_50.webp'),
      url: 'https://www.chemie.de/firmen/1034356/winterthur-instruments-ag.html',
    },
    {
      title: 'Ing.-Buero Dipl.-Inf. Andre Jansen',
      text: 'Ing.-Buero Dipl.-Inf. Andre Jansen',
      img: require('../../assets/img/partner/ingeneurbuero_jansen_50.webp'),
      url: 'https://www.ibjansen.de/',
    },
    {
      title: 'Fraunhofer-Institut',
      text: 'Fraunhofer-Institut fuer Produktionstechnik und Automatisierung IPA',
      img: require('../../assets/img/partner/fraunhofer-institut_50.webp'),
      url: 'https://www.ipa.fraunhofer.de/',
    },
    {
      title: 'FBS Finish GmbH',
      text: 'FBS Finish GmbH',
      img: require('../../assets/img/partner/fbs_50.webp'),
      url: 'https://www.fbs-finish.de/',
    },
    {
      title: 'Frank und Waldenberger GmbH',
      text: 'Frank und Waldenberger GmbH',
      img: require('../../assets/img/partner/frank_und_waldenberger_50.webp'),
      url: 'http://www.frankundwaldenberger.de/',
    },
    {
      title: 'Fischer Gase GmbH',
      text: 'Fischer Gase GmbH',
      img: require('../../assets/img/partner/fischer_gase_50.webp'),
      url: 'https://www.fischergase.de/',
    },
    {
      title: 'Tomo Adour S.A.',
      text: 'Tomo Adour S.A.',
      img: require('../../assets/img/partner/tomo_adour_s_a_50.webp'),
      url: 'https://www.airframer.com/direct_detail.html?company=118700',
    },
    {
      title: 'Ing.-Buero C-C-Urban.',
      text: 'Ing.-Buero C-C-Urban',
      img: require('../../assets/img/partner/c_c_urban_50.webp'),
      url: '#',
    },
  ];

  return (
    <section id="partner">
      <div className="container my-5">
        <h2 className="mt-5 mb-3">Unsere Partner</h2>

        {/* Cards */}
        <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-5">
          {
            // map => durchläuft alle Partner in der partnerList
            partnerList.map(({ id, title, text, img, url }, index) => {
              return (
                <PartnerCard
                  // key={index} ist für react zwingend erforderlich!
                  key={index}
                  title={title}
                  text={text}
                  img={img}
                  url={url}
                />
              );
            })
          }
        </div>
      </div>
    </section>
  );
};

export default Partner;
