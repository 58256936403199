import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useHttp from '../../hooks/use-http';
import useInput from '../../hooks/use-input';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import { apiURL } from '../../utils/Constants';
import './Newsletter.css';

const Newsletter = () => {
  const token = useSelector((state) => state.auth.token);
  const { isLoading, errors, setErrors, sendRequest } = useHttp();
  const [formIsValid, setFormIsValid] = useState(false);
  // eslint-disable-next-line
  const [response, setResponse] = useState(null);
  const [success, setSuccess] = useState(false); // only for development (React.strict)

  // subject
  const {
    value: subject,
    isValid: subjectIsValid,
    hasError: subjectHasError,
    valueChangeHandler: subjectChangeHandler,
    inputBlurHandler: subjectBlurHandler,
    reset: subjectReset,
  } = useInput({
    defaultValue: '',
    validate: (value) => (value ? true : false),
  });

  // body
  const {
    value: body,
    isValid: bodyIsValid,
    hasError: bodyHasError,
    valueChangeHandler: bodyChangeHandler,
    inputBlurHandler: bodyBlurHandler,
    reset: bodyReset,
  } = useInput({
    defaultValue: '',
    validate: (value) => (value ? true : false),
  });

  // if response is a json with JSend specification { status: "success" ... }
  const json = (json) => {
    setResponse(json.data);
    setSuccess(true);
  };

  const sendForm = async (event) => {
    await sendRequest(
      {
        url: apiURL + '/newsletter',
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: {
          subject,
          body,
        },
      },
      json
    );
  };

  // if form was submitted
  const formSubmitHandler = (event) => {
    event.preventDefault();

    // set all fields as touched
    subjectBlurHandler();
    bodyBlurHandler();

    // set form to valid if all fields are valid
    subjectIsValid && bodyIsValid
      ? setFormIsValid(true)
      : setFormIsValid(false);
  };

  const retryHandler = () => {
    setErrors(null);
    setFormIsValid(false);
  };

  const resetHandler = () => {
    setFormIsValid(false);
    setResponse(null);
    setErrors(null);
    setSuccess(false);

    subjectReset();
    bodyReset();
  };

  // if form is valid -> send form
  if (formIsValid) {
    setFormIsValid(false);
    sendForm();
  }

  const errorContent = (
    <React.Fragment>
      <center className="py-5">
        <h3 className="error-text">Newsletter senden fehlgeschlagen</h3>
        <br />
        {errors && errors.hasOwnProperty('data') ? (
          <p className="error-text pb-5">{JSON.stringify(errors.data)}</p>
        ) : null}
        <button
          autoFocus={true}
          onClick={retryHandler}
          className="btn bg-primary border-primary">
          Nochmal versuchen
        </button>
      </center>
    </React.Fragment>
  );

  const successContent = (
    <React.Fragment>
      <center className="py-5">
        <h3 className="success-text">
          Newsletter erfolgreich versendet
          <br />
          Betreff: {subject}
          <br />
          Body: {body}
          <br />
        </h3>
        <br />
        <Link to="/Newsletter">
          <button
            onClick={resetHandler}
            className="btn bg-primary border-primary"
            autoFocus={true}>
            OK
          </button>
        </Link>
      </center>
    </React.Fragment>
  );

  const loadingSpinner = (
    <React.Fragment>
      <center className="py-5">
        <b>sende Newsletter ...</b>
        <br />
        <LoadingSpinner />
      </center>
    </React.Fragment>
  );

  const formContent = (
    <React.Fragment>
      <section id="newsletter">
        <div className="container">
          {/* Form */}
          <form className="form my-5" onSubmit={formSubmitHandler}>
            <h1 className="text-secondary py-0">Newsletter senden</h1>

            {/* subject */}
            <div className="form-group row">
              <label htmlFor="subject" className="col-form-label text-white">
                <b>Betreff:</b>
              </label>
              <input
                className={
                  subjectHasError ? 'form-control invalid' : 'form-control'
                }
                type="text"
                name="subject"
                id="subject"
                placeholder="Betreff"
                onChange={subjectChangeHandler}
                onBlur={subjectBlurHandler}
                value={subject}
              />
              {subjectHasError && (
                <p className="error-text">
                  {'Betreff ' +
                    (subject.trim() === ''
                      ? 'darf nicht leer sein'
                      : 'enthält ungültige Zeichen')}
                </p>
              )}
            </div>

            {/* body */}
            <div className="form-group row">
              <label htmlFor="body" className="col-form-label text-white">
                <b>Text:</b>
              </label>
              <textarea
                className={
                  bodyHasError ? 'form-control invalid' : 'form-control'
                }
                name="body"
                id="body"
                rows="10"
                placeholder="Text"
                onChange={bodyChangeHandler}
                onBlur={bodyBlurHandler}
                value={body}
              />
              {bodyHasError && (
                <p className="error-text">
                  {'Text ' +
                    (body.trim() === '' ? 'darf nicht leer sein' : null)}
                </p>
              )}
            </div>

            {/* submit */}
            <div className="form-group row mt-4">
              <input
                type="submit"
                subject="submit"
                className="input-submit"
                value="Absenden"
              />
            </div>
          </form>
        </div>
      </section>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {!isLoading && !success && errors == null && formContent}
      {!isLoading && !success && errors != null && errorContent}
      {!isLoading && success && successContent}
      {isLoading && loadingSpinner}
    </React.Fragment>
  );
};

export default Newsletter;
